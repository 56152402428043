import Base from "../services/baseClientService";
import store from "../redux/store";

class ProgramService extends Base {
  static async getPrograms(reload = false) {
    // fetch current language
    const language = store.getState().auth.user?.interface_language || "en";
    console.debug(
      "User language:",
      store.getState().auth.user?.interface_language
    );

    const cacheIndex = `all-programs-${language}`;

    // If reload is true, then we will force a reload of the cache
    if (reload) {
      this.clearCache(cacheIndex);
    }

    return await this.getCachedOr(cacheIndex, async () => {
      try {
        const programs = await this.GET(`/organization/programs`);
        this.cache(cacheIndex, programs);
        return programs;
      } catch (e) {
        console.error("Error fetching organization programs: ", e.message);
        return this.Error(e.message);
      }
    });
  }

  static async getProgram(program_id) {
    program_id = parseInt(program_id);
    const programs = await this.getPrograms();
    if (!programs) return false;
    const program = programs.find(
      (program) => program.program_id === program_id
    );
    if (!program) return this.Error("Program not found!");
    return program;
  }

  static async unassignProgramFromUser(user_id, program_id) {
    try {
      const result = await this.POST(
        `/organization/users/${user_id}/remove-program/${program_id}`
      );
      return result;
    } catch (e) {
      console.error("Error unassigning program from user: ", e.message);
      return this.Error(e.message);
    }
  }

  static async assignProgramToUser(user_id, program_id) {
    try {
      const result = await this.POST(
        `/organization/users/${user_id}/assign-program/${program_id}`
      );
      return result;
    } catch (e) {
      console.error(
        "Error assigning program to user within organization: ",
        e.message
      );
      return this.Error(e.message);
    }
  }

  static async assignGroupsToUsers(user_ids, group_ids) {
    console.debug("assignGroupsToUsers called with", user_ids, group_ids);
    try {
      return await this.POST(
        `/organization/users/assign-groups`,
        {user_ids, group_ids}
      );
    } catch (e) {
      console.error(
        "Error assigning groups to users within organization: ",
        e.message
      );
      throw new Error(e.message);
    }
  }

  static async assignProgramsToUsers(user_ids, program_ids) {
    try {
      return await this.POST(
        `/organization/users/assign-programs`,
        {
          user_ids, program_ids
        }
      );
    } catch (e) {
      console.error(
        "Error assigning programs to users within organization: ",
        e.message
      );
      throw new Error(e.message);
    }
  }

  static async getAllCourses() {
    const cacheIndex = "all-courses";
    return await this.getCachedOr(cacheIndex, async () => {
      try {
        let courses = await this.GET(`/organization/courses`);
        courses = courses ? Object.values(courses) : false;

        if (!Array.isArray(courses)) throw new Error("Invalid courses data");

        this.cache(cacheIndex, courses);
        return courses;
      } catch (e) {
        console.error("Error fetching organization courses: ", e.message);
        return this.Error(
          e.message || "Unknown error while getting all courses"
        );
      }
    });
  }

  static async saveProgram(program) {
    if (typeof program !== "object") return this.Error("Invalid program data");

    const { program_id, ...other } = program;

    try {
      const result = program_id
        ? // program_id is defined, so we are updating an existing program
          await this.POST(`/organization/programs/update/${program_id}`, other)
        : // program_id is not defined, so we are creating a new program
          await this.POST(`/organization/programs/add`, other);

      return result;
    } catch (e) {
      console.error("Error saving program: ", e.message);
      return this.Error(e.message);
    }
  }

  // static async updateCourses(program_id, course_ids) {
  //   if (!Array.isArray(course_ids)) return this.Error("Invalid courses data");

  //   try {
  //     return await this.POST(`/organization/programs/courses/${program_id}`, {
  //       course_ids,
  //     });
  //   } catch (e) {
  //     console.error("Error updating courses: ", e.message);
  //     return this.Error(e.message);
  //   }
  // }

  static async deleteProgram(program_id) {
    try {
      const result = await this.POST(
        `/organization/programs/delete/${program_id}`
      );
      return result;
    } catch (e) {
      console.error("Error saving program: ", e.message);
      return this.Error(e.message);
    }
  }
  static async deleteImage(program_id) {
    try {
      const result = await this.POST(
        `/organization/programs/${program_id}/image/delete`
      );
      return result;
    } catch (e) {
      console.error("Error saving program: ", e.message);
      return this.Error(e.message);
    }
  }
  static async updateImage(program_id, image) {
    try {
      const result = await this.POST(
        `/organization/programs/image/${program_id}`,
        image
      );
      return result;
    } catch (e) {
      console.error("Error saving program: ", e.message);
      return this.Error(e.message);
    }
  }

  static async getProgramCourse(program_id, course_id) {
    try {
      const result = await this.GET(
        `/organization/programs/${program_id}/course/${course_id}`
      );
      return result;
    } catch (e) {
      console.error("Error feaching course: ", e.message);
      this.Error(e.message);
      return false;
    }
  }
}

export default ProgramService;
