import React from 'react';
// import ColumnedList from '@bit/ses-education.ses-courses-shared-components.template.custom-card-list/columned-list';

// import "./custom-card-list.scss"

const ColumnedList = ({ items }) => (
  <ul className='columned-list'>
    {Array.isArray(items) &&
      items.map((e, ind) => e ? <li key={`cl-item-${ind}`}>{e.title}</li>: null )  }
  </ul>
);

export default ColumnedList;