import React from 'react';
import {
  Container,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { Email, Lock } from '@material-ui/icons';
import store from '../../redux/store';
import A from '../../redux/actionTypes';
import Service from '../../services/AuthService';

import { ReactComponent as STLabsTitle } from './images/self-training-lab.svg';

import './login.scss';
import { Link } from 'react-router-dom';
import WithTranslations from '../WithTranslations';
import Spinner from '../template/Spinner';

class Login extends React.Component {
  state = {
    email: '',
    password: '',
    connecting: false,
  };

  componentDidMount() {
    this.props.fetchTranslations([
      'Login',
      'organizationLogin',      
      'forgotPassword?',
      "Email",
      "Password",
      "Logging in"
    ]);
  }

  validateForm = (ev) => {
    return ev.currentTarget.reportValidity();
  };

  login = async (ev) => {
    // catch submit
    ev.preventDefault();

    if (this.validateForm(ev)) {
      const { email, password } = this.state;

      if (email && password) {
        console.debug('Logging in');
        this.setState({ connecting: true }, () => {
          this.setState({ connecting: false });
          this.doLogin(email, password);
        });
      }
    }
  };

  doLogin = async (email, password) => {
    const result = await Service.login(email, password);
    console.debug("login result", result);
    if (result) {
      const { token, user } = result;
      store.dispatch({ type: A.LOGIN, payload: { user, token } });
    } else {
      store.dispatch({
        type: A.SHOW_MESSAGE,
        payload: { message: Service.error || 'Unknown error', type: 'error' },
      });
    }
  };

  updateValue = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  render() {
    const { email, password, connecting } = this.state;
    const { translationError, translationsLoaded, _t } = this.props;

    if (!translationError && !translationsLoaded) return <Spinner />;

    return connecting ? (
      <div id='login'>
        <Container>
          <CircularProgress /> {_t("Logging in")}...
        </Container>
      </div>
    ) : (
      <div id='login'>
        <Container>
          <div className='st-labs-title'>
            <STLabsTitle />
          </div>
          <form noValidate className='login-form' onSubmit={this.login}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant='h6' color='primary'>
                  {_t("organizationLogin")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <InputLabel htmlFor='input-with-icon-adornment'>
                    {_t("Email")}
                  </InputLabel>
                  <Input
                    id='input-with-icon-adornment'
                    autoComplete='username'
                    type='email'
                    name='email'
                    onChange={this.updateValue}
                    value={email}
                    startAdornment={
                      <InputAdornment position='start'>
                        <Email color='primary' />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <InputLabel htmlFor='input-with-icon-adornment2'>
                  {_t("Password")}
                  </InputLabel>
                  <Input
                    id='input-with-icon-adornment2'
                    type='password'
                    name='password'
                    autoComplete='current-password'
                    onChange={this.updateValue}
                    value={password}
                    startAdornment={
                      <InputAdornment position='start'>
                        <Lock color='primary' />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  style={{ width: '50%', marginBottom: '10px' }}
                >
                  {_t("Login")}
                </Button>
                <Typography
                  className='reset-password-link'
                  component={Link}
                  to={'/reset-password'}
                >
                  {_t("forgotPassword?")}
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Container>
      </div>
    );
  }
}

export default WithTranslations( Login );
