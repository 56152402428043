import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import ProgramService from "../../../services/ProgramService";
import Error from "../../template/Error";
import Spinner from "../../template/Spinner";
import "./style.scss";
import "./course.scss";
import {Parser} from "@ses-education/content-parser";
import { Link, NavLink, Redirect } from "react-router-dom";
import AutoBreadcrumbs from "../../template/CustomBreadcrumbs";
import WithTranslations from "../../WithTranslations";
import { Home } from "@material-ui/icons";
import experiment_pages from "../../../data/experiment_pages.json";

const subDiv = [
  {
    id: "objectives",
    title: "Objectives",
  },
  {
    id: "discussion",
    title: "Discussion",
  },
  {
    id: "prep",
    title: "Preparation Questions",
  },
  {
    id: "experiment",
    title: "Experiment",
  },
  {
    id: "summary",
    title: "Summary Questions",
  },
];
class ProgramCourse extends React.Component {
  state = {
    course: null,
    program: null,
    // experiment: null,
    error: null,
    html: null,
    redirect: null,
    questions: null,
    breadcrumbs: [],
    backLink: "/",
  };
  _accordionElement = null;

  // listeners to mount and unmount
  listeners = [
    { event: "scroll", listener: this.updateAccordionHeight },
    // resize listener
    { event: "resize", listener: this.updateAccordionHeight },
  ];
  componentDidMount = async () => {
    this.props.fetchTranslations([
      "Objectives",
      "Discussion",
      "Preparation Questions",
      "Experiment",
      "Summary Questions",
      "Programs",
      "Back",
      "View course content",
    ]);

    const { program_id, course_id } = this.props.match.params;
    await this.fetchData(program_id, course_id);
    // add event listeners
    this.listeners.forEach((listener) => {
      window.addEventListener(
        listener.event,
        this.updateAccordionHeight.bind(this)
      );
    });

    this.updateAccordionHeight();
  };
  componentWillUnmount() {
    // remove event listeners on unmount
    this.listeners.forEach((listener) => {
      window.removeEventListener(listener.event, listener.listener);
    });
  }
  componentDidUpdate = async (prevProps, prevState) => {
    const { program_id, course_id, experiment_id, chapter } =
      this.props.match.params;
    const {
      course_id: prev_course_id,
      experiment_id: prev_experiment_id,
      chapter: prev_chapter,
    } = prevProps.match.params;
    if (prevState.redirect) {
      this.setState({ redirect: null });
    }
    if (
      course_id != prev_course_id ||
      prevProps._language != this.props._language
    ) {
      await this.fetchData(program_id, course_id);
      return;
    }
    if (experiment_id != prev_experiment_id || chapter != prev_chapter) {
      this.populateHtml();
      // scroll content element to top when changing page
      const content = document.getElementById("experiment-content-scroll");
      // check if element exists and valid
      if (content && content.scrollTop) content.scrollTop = 0;
      return;
    }
  };
  /**
   * Fetch program and course data
   * @param {*} program_id
   * @param {*} course_id
   * @returns
   */
  fetchData = async (program_id, course_id) => {
    console.log("fetch course", program_id, course_id);
    const course = await ProgramService.getProgramCourse(program_id, course_id);
    const program = await ProgramService.getProgram(program_id);

    if (!course || !program) {
      this.setState({ error: ProgramService.error });
      return;
    }
    this.setState({ course, program, html: null }, this.populateHtml);
  };

  /**
   * Prepare html and other values for render
   * @returns
   */
  populateHtml = () => {
    const { _t } = this.props;
    const { program_id, course_id, experiment_id, chapter } =
      this.props.match.params;
    const { program, course } = this.state;

    let backLink = `/programs/${program_id}`;

    let breadcrumbs = [
      {
        icon: <Home />,
        to: "/",
      },
      {
        text: _t("Programs"),
        to: "/programs",
      },
      {
        text: program.title,
        to: `/programs/${program_id}`,
      },
      {
        text: course.title,
        to: `${backLink}/${course_id}`,
      },
    ];

    if (!experiment_id) {
      // if no experiment_id, then show the course overview
      this.setState({ html: course.html, breadcrumbs, backLink });
      return;
    }

    // experiment is selected, find it
    const experiment =
      course.experiments.find(
        (exp) => exp.course_experiment_id == experiment_id
      ) || {};
    console.debug("experiment:", experiment);

    // update back link
    backLink = `${backLink}/${course_id}`;

    // add it to breadcrumbs
    breadcrumbs = [
      ...breadcrumbs,
      {
        text: experiment.title || "Unknown experiment",
        to: `${backLink}/${experiment_id}`,
      },
    ];

    console.debug("experiment breadcrumbs:", breadcrumbs);

    // if no chapter is selected within experiment, go to its first chapter
    if (!chapter) {
      this.setState({
        redirect: `${backLink}/${experiment_id}/objectives`,
      });
      return;
    }

    // TODO: chapter selected, find it

    // update back link
    // backLink = `${backLink}/${experiment_id}/objectives`;
    const page_item = experiment_pages[chapter];
    if (!page_item) {
      this.setState({
        redirect: `${backLink}/${experiment_id}/objectives`,
      });
      return;
    }

    this.setState({
      // find htmls for chapter using index array from chapter item, then join all htmls into one
      html: page_item.index.map((ind) => experiment[ind]).join("\n"), // experiment[`html_${chapter}`] || "",
      questions: (experiment || {}).questions,
      breadcrumbs,
      backLink,
      //   experiment,
    });
  };
  updateAccordionHeight = () => {
    console.debug("updateAccordionHeight called");
    const course = document.getElementById("course-content-block");

    const accordion = document.getElementById("accordion");
    if (!this._accordionElement) {
      // if (!accordion) {
      console.debug("updateAccordionHeight: no element defined: ", accordion);
      return;
    }
    if (!course) {
      console.debug("updateAccordionHeight: no course defined: ", course);
      return;
    }

    try {
      // Grid item padding (2 * 12)
      const padding = 24;

      const height = course.getBoundingClientRect().height - padding;

      console.debug(
        "updateAccordionHeight: height: ",
        height,
        "element: ",
        this._accordionElement
      );

      // element target height is viewport top position plus viewport height minus element top position
      // const height = containerY > 0 ?  vh - containerY - threshold - padding : vh - threshold - padding;

      // switched from this._accordionElement to get element by id,
      // because looks like this._accordionElement gets asyncronously redefined when component is updated
      // and this function is called before it finished
      this._accordionElement.style.height = height + "px";
      // accordion.style.height = height + "px";
    } catch (e) {
      console.debug("updateAccordionHeight error:", e);
    }
  };
  render() {
    const { _t } = this.props;
    const { course, error, html, redirect, questions, breadcrumbs, backLink } =
      this.state;
    const { experiments } = course || {};
    const { experiment_id, program_id, course_id } = this.props.match.params;
    if (redirect) {
      return <Redirect to={redirect} />;
    }

    // find first experiment id for the link
    let first_experiment_id;
    if (!experiment_id && course) {
      first_experiment_id = course.experiments[0].course_experiment_id;
    }
    return (
      <Container>
        <AutoBreadcrumbs items={breadcrumbs} {...{ backLink }} backText={_t("Back")} />
        {error && <Error {...{ error }} />}
        {!error && !course && <Spinner />}
        {!error && course && (
          <div className="course-main-container" id="course-main">
            <Typography variant="h1" color="primary">
              {course.title}
            </Typography>
            <Paper className="paper grow-1" id="course-content-block">
              <Grid container spacing={3} className="grid-container">
                <Grid item xs={12} md={3} className="course-accordion-block">
                  {Array.isArray(experiments) && experiment_id && (
                    <List
                      className="exp-list-container"
                      // set accordion element to update accordion height
                      ref={(el) => {
                        this._accordionElement = el;
                        this.updateAccordionHeight();
                      }}
                      id="accordion"
                    >
                      {experiments.map((exp) => {
                        console.log(
                          "experiment_link:",
                          `/programs/${program_id}/${course_id}/${exp.course_experiment_id}`
                        );
                        const experiment_link = `/programs/${program_id}/${course_id}/${exp.course_experiment_id}`;
                        return (
                          <ListItem
                            button={true}
                            divider={true}
                            selected={
                              exp.course_experiment_id === experiment_id
                            }
                            key={exp.course_experiment_id}
                          >
                            <Accordion
                              expanded={
                                experiment_id == exp.course_experiment_id
                              }
                              className="accordion-list"
                              // onChange={() => this.updateAccordionHeight()}
                              on
                            >
                              <Link
                                to={experiment_link}
                                className="accordion-experiment-title"
                              >
                                <AccordionSummary>{exp.title}</AccordionSummary>
                              </Link>
                              <AccordionDetails>
                                <List component="div" className="chapters">
                                  {/* {subDiv.map((item) => { */}
                                  {
                                    // use values from experiment pages object
                                    Object.values(experiment_pages).map(
                                      (item) => {
                                        // const chapterLink = `/program/${prog_id}/course/${course_id}/experiment/${exp.course_experiment_id}/${item.id}`;
                                        const chapterLink = `/programs/${program_id}/${course_id}/${exp.course_experiment_id}/${item.id}`;
                                        return (
                                          <ListItem
                                            component={NavLink}
                                            to={chapterLink}
                                            key={`exp-${experiment_id}-${item.id}-menu-item`}
                                            button={true}
                                            divider={true}
                                          >
                                            {_t(item.title)}
                                          </ListItem>
                                        );
                                      }
                                    )
                                  }
                                </List>
                              </AccordionDetails>
                            </Accordion>
                          </ListItem>
                        );
                      })}
                    </List>
                  )}

                  {!experiment_id && (
                    <div className="flex grow-1 align-items-center column">
                      <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={`${backLink}/${course.course_id}/${first_experiment_id}`}
                        className="align-self-center"
                      >
                        {_t("View course content")}
                      </Button>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} md={9}>
                  <div
                    className="exp-content-container"
                    id="experiment-content-scroll"
                  >
                    {html && (
                      <Parser
                        html={html}
                        allQuestions={questions}
                        onErrors={(error) => {
                          this.setState({ error });
                        }}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </div>
        )}
      </Container>
    );
  }
}

export default WithTranslations(ProgramCourse);
