import React from "react";
import UserService from "../../../services/UserService";
import ProgramService from "../../../services/ProgramService";
import {
  LinearProgress,
  // Grid,
  ButtonGroup,
  Button,
  Typography,
  // Dialog,
  // DialogActions,
  // DialogTitle,
  Tooltip,
  Zoom,
  Container,
  MenuItem,
  Select,
} from "@material-ui/core";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import PersonIcon from "@material-ui/icons/Person";
import DeleteIcon from "@material-ui/icons/Delete";
import { GridOverlay } from "@material-ui/data-grid";
import { connect } from "react-redux";
import { showMessage } from "../../../redux/notificationActions";
import ModalAddStudents from "../../template/ModalAddStudents";
import { statusDescription } from "../../../data/statusDescription";
import { Link } from "react-router-dom";
import Error from "../../template/Error";
import AutoBreadcrumbs from "../../template/CustomBreadcrumbs";
import "./users.scss";
import { Home, ArrowDownward } from "@material-ui/icons";
import WithTranslations from "../../WithTranslations";
import { strFormat } from "../../../utils/functions";
import ProcessSpinner from "../../template/ProcessSpinner";
import OrganizationService from "../../../services/OrganizationService";
import LocalizedAdminTable from "../../template/localized-admin-table";
import ConfirmDialog from "../../template/ConfirmDialog";
import SelectGroups from "./select-groups";
import SelectPrograms from "./select-programs";
import FilterSelect from "../../template/filter-select";

const filterFunctions = {
  status_id: (value) => (row) => {
    return row.status_id === value;
  },
};
class Users extends React.Component {
  state = {
    id: null,
    users: [],
    programs: null,
    rows: [],
    filter_rows: [],
    loading: false,
    confirmDialog: false,
    error: null,
    groups: null,
    addModalOpen: false,
    renderAddModal: false, // triggers the rendering of ModalAddStudents
    user: null,
    filters: {
      status_id: -1,
      selectedOnly: {
        items: [],
      },
    },
    sortModel: [
      { field: "status", sort: "asc" },
      { field: "user_full_name", sort: "asc" },
    ],
    processTitle: null,
    statusList: null,
    showAssignProgramsDialog: null,
    showAssignGroupsDialog: null
  };

  async componentDidMount() {
    this.translatePage();
    this.setState({ loading: true });
    const usersLoaded = await this.fetchUsers();
    const programsLoaded = await this.fetchPrograms();
    const StatusLoaded = await this.fetchStatusList();
    const groupsLoaded = await this.fetchGroups();
    if (usersLoaded && programsLoaded && StatusLoaded && groupsLoaded) {
      this.setState({ error: null, loading: false });
    }
    this.onSelect({ target: { value: -1 } }, "status_id");
  }

  translatePage() {
    this.props.fetchTranslations([
      "Are you sure you want to delete the %number% selected student(s)?",
      "Assign these %count% program(s) to selected users?",
      "Assign",
      "Delete",
      "Add students",
      "Actions",
      "ID",
      "Status",
      "Full Name",
      "Email",
      "Progress",
      "Select groups to assign",
      "View",
      "Search",
      "Students",
      "Cancel",
      "All",
      "Deleting users",
      "Deleting users...",
      "Programs successfully assigned",
      
    ]);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps._language != this.props._language) {
      this.setState({ loading: true });
      if ((await this.fetchPrograms()) || (await this.fetchStatusList())) {
        this.setState({ error: null, loading: false });
      }

      await this.fetchGroups();

      this.translatePage();
    }
  }
  async fetchUsers() {
    try {
      console.log("fetching users");
      let users = await UserService.getUsers();

      if (users) {
        // only populate state with STUDENT users
        const studentUsers = users.filter(
          (user) => user.credentials && user.credentials.includes("student")
        );
        this.setState({ users: studentUsers });
        this.populateRows(studentUsers);
        return true;
      } else {
        this.setState({ error: "Failed loading users" });
        return false;
      }
    } catch (err) {
      this.props.onShowMessage(`Fetching users from server failed.`, "error");
      return false;
    }
  }

  async fetchPrograms() {
    try {
      const programs = await ProgramService.getPrograms();
      if (programs) {
        this.setState({
          programs,
        });
        return true;
      } else {
        this.setState({ error: "Failed loading programs" });
        return false;
      }
    } catch (err) {
      this.props.onShowMessage(
        `Fetching organization programs from server failed.`,
        "error"
      );
      return false;
    }
  }

  fetchGroups = async () => {
    try{
      const groups = await OrganizationService.getStudentGroups();
      this.setState({groups})
      return true;
    } catch(e){
      console.debug(e);

      const {_t, onShowMessage} = this.props;

      onShowMessage(e.message || _t("Unknown error"));
      return false;
    }
  }

  async fetchStatusList() {
    const statusList = await OrganizationService.getStatusList();
    if (Array.isArray(statusList)) {
      this.setState({ statusList }, () =>
        this.props.fetchTranslations(statusList.map((i) => i.status_title))
      );
      return true;
    }
    return false;
  }

  /**
   * populates users and stores "rows" array to state
   * @param {*} users
   */
  populateRows(users) {
    let rows = [];
    users.forEach((u) => {
      const item = {
        id: u.id, // must exist
        citizen_id: u.citizen_id ? u.citizen_id : "N/A",
        status_id: u.status_id,
        status: u.status.status_title,
        user_full_name: `${u.name} ${u.last_name}`,
        user_email: u.email,
        progress: [
          u.courses_total ? (
            <Tooltip
              TransitionComponent={Zoom}
              title={this.populateProgressDetails(
                u.assigned_courses || [],
                u.course_progress || []
              )}
              arrow
            >
              <Typography style={{ cursor: "default" }}>
                {(u.courses_done || 0) + "/" + u.courses_total}
              </Typography>
            </Tooltip>
          ) : (
            <Typography style={{ cursor: "default" }}>{"N/A"}</Typography>
          ),
        ],
      };
      rows.push(item);
    });
    this.setState({ rows, filter_rows: rows });
  }

  loadingOverlay() {
    return (
      <GridOverlay>
        <div style={{ position: "absolute", top: 0, width: "100%" }}>
          <LinearProgress />
        </div>
      </GridOverlay>
    );
  }

  populateProgressDetails(assigned_courses, course_progress) {
    return (
      <Typography>
        {assigned_courses.map((item) => {
          const course =
            course_progress.find((c) => c.course_id === item.course_id) || {};
          return (
            <ul className="tooltip-list">
              <li>
                <span>{item.course_code}</span>
                <span>&nbsp;</span>
                <span>{(course.progress || "0") + "%"}</span>
              </li>
            </ul>
          );
        })}
      </Typography>
    );
  }

  assignPrograms = async (items) => {
    const {_t, onShowMessage} = this.props;
    const {filters} = this.state;

    // items = null;
    // const setState = this.setState;

    // validate selected users
    if( !Array.isArray( filters?.selectedOnly?.items) || filters.selectedOnly.length === 0 ){
      return onShowMessage(_t("No users selected"), "error");
    }
    if( !Array.isArray( items) || items.length === 0){
      return onShowMessage(_t("No programs selected"), "error");
    }

    console.debug("assigning programs", items);

    const confirmDialog = {
      header: _t("Assign programs"),
      prompt: strFormat( _t("Assign these %count% program(s) to selected users?"), {"%count%": items.length} ),
      onConfirm: async () => {
        try{
          this.setState({processTitle: _t("Assigning programs...")})
          await ProgramService.assignProgramsToUsers( 
            filters.selectedOnly.items,
            items.map( p => p.program_id)
          )

          this.setState({processTitle: null, confirmDialog: null, showAssignProgramsDialog: null});
          onShowMessage(_t("Programs successfully assigned", "success"));

          return true;
        }catch(e){
          console.debug("caught in assignPrograms");
          this.setState({processTitle: null});
          onShowMessage(e.message || _t("Unknown error"), "error")
          return false;
        }
      },
      onClose: () => this.setState({confirmDialog: null}),
      formClassName: "confirm-dialog shrink"
    }

    this.setState({confirmDialog})
  }


  assignGroups = (items) => {
    console.debug("assigning groups", items);

    const {_t, onShowMessage} = this.props;
    const {filters} = this.state;

    // items = null;
    // const setState = this.setState;

    // validate selected users
    if( !Array.isArray( filters?.selectedOnly?.items) || filters.selectedOnly.length === 0 ){
      return onShowMessage(_t("No users selected"), "error");
    }
    if( !Array.isArray( items) || items.length === 0){
      return onShowMessage(_t("No groups selected"), "error");
    }

    console.debug("assigning groups", items);

    const confirmDialog = {
      header: _t("Assign groups"),
      prompt: strFormat( _t("Assign these %count% group(s) to selected users?"), {"%count%": items.length} ),
      onConfirm: async () => {
        try{
          this.setState({processTitle: _t("Assigning groups...")})
          await ProgramService.assignGroupsToUsers( 
            filters.selectedOnly.items,
            items.map( p => p.student_group_id)
          )

          this.setState({processTitle: null, confirmDialog: null, showAssignGroupsDialog: null});
          onShowMessage(_t("Groups successfully assigned", "success"));

          return true;
        }catch(e){
          console.debug("caught in assignGroups");
          this.setState({processTitle: null});
          onShowMessage(e.message || _t("Unknown error"), "error")
          return false;
        }
      },
      onClose: () => this.setState({confirmDialog: null}),
      formClassName: "confirm-dialog shrink"
    }

    this.setState({confirmDialog})
  }

  /**
   * Showinf process spinner
   * @param {*} processTitle
   */
  setProcess = (processTitle) => {
    this.setState({ processTitle });
  };

  closeDialogHandler() {
    this.setState({ confirmDialog: false, delID: null });
  }

  deleteUserHandler = async () => {
    const { filters } = this.state;
    const {_t} = this.props;

    // show progress
    this.setProcess(_t("Deleting users..."));

    const result = await UserService.deleteStudents(filters.selectedOnly.items);
    if (result) {
      this.props.onShowMessage(
        `Users with ids ${filters.selectedOnly.items} was deleted.`,
        "info"
      );
    } else {
      this.props.onShowMessage(
        UserService.error ||
          `Error deleting users, please remove assigned programs before deletion.`,
        "error"
      );
    }
    this.fetchUsers();
    this.setProcess(null);
    this.closeDialogHandler();
  }

  closeAddModal() {
    this.setState({
      addModalOpen: false,
      renderAddModal: false,
    });
  }
  onSelect2 = (model) => {
    let { filters } = this.state;
    filters.selectedOnly.items = model;
    this.setState({ filters });
  };
  onSelect = (event, type) => {
    let { filters } = this.state;
    filters = { ...filters, [type]: event.target.value };
    console.log("onSelect", filters);
    this.setState({ filters }, this.filterEverything);
  };
  filterEverything = () => {
    console.log("filterEverything", this.state);
    const { filters } = this.state;
    let filter_rows = [...this.state.rows];
    Object.entries(filters).map((entry) => {
      const [key, value] = entry;
      //console.log("filterEverything",filterFunctions[key]);
      if (filterFunctions[key] && value !== -1) {
        filter_rows = filter_rows.filter(filterFunctions[key](value));
      }
    });
    this.setState({ filter_rows });
  };
  render() {
    const {
      loading,
      filter_rows,
      error,
      confirmDialog,
      addModalOpen,
      users,
      groups,
      programs,
      renderAddModal,
      filters,
      sortModel,
      processTitle,
      statusList,
      showAssignGroupsDialog,
      showAssignProgramsDialog
    } = this.state;

    console.debug("state:", this.state);
    const { _t, _language } = this.props;
    // console.debug("filters:", filters);

    const preparedStatusList = [ 
      // default value
      {value: -1, title: _t("All")},
      ...(Array.isArray(statusList) ?
      statusList.map(
         i => ({value: i.status_id, title: _t(i.status_title)})
      ) : [])
    ];

    const columns = [
      { field: "citizen_id", headerName: _t("ID"), flex: 1 },
      {
        field: "status",
        headerName: _t("Status"),
        flex: 1,
        renderCell: (params) => (
          <div>
            {[
              <Tooltip
                TransitionComponent={Zoom}
                title={statusDescription(params.row.status_id)}
              >
                <Typography style={{ cursor: "default" }}>
                  {_t(params.value)}
                </Typography>
              </Tooltip>,
            ]}
          </div>
        ),
      },
      { field: "user_full_name", headerName: _t("Full Name"), flex: 1.5 },
      { field: "user_email", headerName: _t("Email"), flex: 1.5 },
      {
        field: "progress",
        headerName: _t("Progress"),
        flex: 1.5,
        renderCell: (params) => <div>{params.value}</div>,
      },
      {
        field: "actions",
        headerName: _t("Actions"),
        flex: 2,
        renderCell: (params) => (
          <div>
            <ButtonGroup>
              <Button
                component={Link}
                to={`/students/${params.id}`}
                variant="contained"
                color="primary"
                size="small"
                startIcon={<PersonIcon />}
              >
                {_t("View")}
              </Button>
              {/* <Button
                onClick={() =>
                  this.setState({ confirmDialog: true, delID: params.id })
                }
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button> */}
            </ButtonGroup>
          </div>
        ),
      },
    ];
    const buttons = (
      <div className="buttons">
        <Button
          onClick={() =>
            this.setState({
              addModalOpen: true,
              renderAddModal: true,
            })
          }
          variant="contained"
          startIcon={<GroupAddIcon />}
          className="add-button"
          size="small"
        >
          {_t("Add students")}
        </Button>
        
        <Button
          className="flex align-self-stretch"
          onClick={() => this.setState({ confirmDialog: {
            header: _t("Deleting selected students"),
            prompt: strFormat( 
              _t("Are you sure you mant to delete the %number% selected user(s)?"), 
              {
                "%number%" : filters?.selectedOnly?.items?.length
              } 
            ),
            onClose: () => this.setState({confirmDialog: null}),
            onConfirm: this.deleteUserHandler
          } })}
          variant="contained"
          color="secondary"
          size="small"
          disabled={filters.selectedOnly.items.length === 0}
          startIcon={<DeleteIcon />}
        >
          {_t("Delete")}
        </Button>
        <Button
          onClick={() => this.setState({ showAssignGroupsDialog: true })}
          variant="contained"
          size="small"
          disabled={filters.selectedOnly.items.length === 0}
          startIcon={<GroupAddIcon />}
          className="bg-orange color-white"
        >
          {_t("Assign groups")}
        </Button>
        <Button
          onClick={() => this.setState({ showAssignProgramsDialog: true })}
          variant="contained"
          size="small"
          disabled={filters.selectedOnly.items.length === 0}
          startIcon={<GroupAddIcon />}
          className="bg-orange color-white"
        >
          {_t("Assign programs")}
        </Button>
        <FilterSelect 
          value={filters["status_id"]}
          onChange={(event) => this.onSelect(event, "status_id")}
          items={ preparedStatusList }
          InputLabel = {_t("Show")}
          variant="outlined"
          
        />
        {/* <div>
          <Select
            // native
            value={filters["status_id"]}
            onChange={(event) => this.onSelect(event, "status_id")}
            label={() => <span>Show</span>}
          >
            <MenuItem value={-1}>{_t("All")}</MenuItem>
            {Array.isArray(statusList) &&
              statusList.map((i) => (
                <MenuItem value={i.status_id}>{_t(i.status_title)}</MenuItem>
              ))}
          </Select>
        </div> */}
      </div>
    );


    return error ? (
      <Error {...{ error }} />
    ) : (
      // <Typography>{error}</Typography>
      <Container className="user-list">
        <AutoBreadcrumbs
          items={[{ icon: <Home />, to: "/" }, { text: _t("Students") }]}
        />
        <Typography variant="h1" color="primary">
          {_t("Students")}
        </Typography>
        <div className="bubble">
          <LocalizedAdminTable
            language_id={_language}
            components={{
              LoadingOverlay: this.loadingOverlay,
            }}
            searchPlaceholder={_t("Search")}
            {...{ columns, buttons, loading }}
            pageSize={10}
            rows={filter_rows}
            rowHeight={70}
            onSortModelChange={(model) => {
              if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
                this.setState({ sortModel: model });
              }
            }}
            onSelectionModelChange={(model) => {
              return this.onSelect2(model);
            }}
            sortModel={sortModel}
            autoHeight
            searchFields={["user_full_name", "user_email"]}
            disableSelectionOnClick
            checkboxSelection
          />
          <ConfirmDialog 
            open={Boolean(confirmDialog)}
            {...confirmDialog}
          />

          {renderAddModal ? (
            <ModalAddStudents
              existingUsers={users}
              onModalOpen={addModalOpen}
              onModalClose={() => this.closeAddModal()}
              onChanges={() => {
                this.fetchUsers();
                this.fetchPrograms();
              }}
            />
          ) : null}

          { showAssignGroupsDialog && (
            <SelectGroups 
            formClassName="shrink"
            header={_t("Select groups to assign")}
            open={showAssignGroupsDialog} 
            items={groups}
            onClose={() => this.setState({showAssignGroupsDialog: null})}
            onAssign={this.assignGroups}
            {...{_t}}
            />
          ) }
          { showAssignProgramsDialog && (
            <SelectPrograms 
            formClassName="shrink"
            header={_t("Select programs to assign")}
            open={showAssignProgramsDialog} 
            items={programs}
            onClose={() => this.setState({showAssignProgramsDialog: null})}
            onAssign={this.assignPrograms}
            {...{_t}}
            />
          ) }
        
          <ProcessSpinner open={processTitle} header={processTitle} />
        </div>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};

export default WithTranslations(connect(null, mapDispatchToProps)(Users));
