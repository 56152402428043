import React from 'react';
import { connect } from 'react-redux';
// import { hideMessage, clearAllMessages } from '../../../redux/notificationActions';

import {Notifications, hideMessage, clearAllMessages} from '@ses-education/courses-components';

class Notification extends React.Component {

  render() {
    const { messages, onCloseMessage, onCloseAll } = this.props;
    return (<div>
      <Notifications 
        {...{
            messages, onCloseMessage, onCloseAll
          }
        }
        config={{
          topToBottom: true                    
        }}
      />
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    messages: state.reducers.messages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseMessage: (ind) => dispatch(hideMessage(ind)),
    onCloseAll: () => dispatch( clearAllMessages() )  
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
