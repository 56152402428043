import React from "react";
// import UserService from "../../../services/UserService";
// import ProgramService from "../../../services/ProgramService";
// import AuthService from "../../../services/AuthService";
import {
  // Accordion,
  Grid,
  // AccordionDetails,
  // AccordionSummary,
  Typography,
  Container,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogActions,
  // LinearProgress,
  // TextField,
  // Modal,
} from "@material-ui/core";
import { connect } from "react-redux";
import { showMessage } from "../../../redux/notificationActions";
import ModalEditStudent from "../../template/ModalEditStudent";
import Spinner from "../../template/Spinner";
// import { Link } from "react-router-dom";
import "./users.scss";
import {PasswordChangeDialog, ConfirmDialog} from "@ses-education/courses-components";
import AutoBreadcrumbs from "../../template/CustomBreadcrumbs";
import Error from "../../template/Error";
import UserProgress from "./user-progress";
import GroupStudentCourse from "../StudentGroups/group-student-course";
import { strFormat } from "../../../utils/functions";
import OrganizationProfile from "./organization-profile";
import DeleteIcon from "@material-ui/icons/Delete";
import UserService from "../../../services/UserService";
import { Redirect } from "react-router-dom";
import WithTranslations from "../../WithTranslations";

const DetailsRow = (props) => (
  <div className="details-row">
    <span>{props.title}</span>
    <span>{props.value}</span>
  </div>
);

// const { PUBLIC_URL = "https://images.ses-education.com" } = process.env
const PUBLIC_URL = "https://images.ses-education.com/courses";

class UserProfile extends React.Component {
  state = {
    currentUser: null,
    programs: null,
    users: null,
    error: null,
    editModalOpen: false,
    // renderEditModal: false,
    editPasswordModalOpen: false,
    breadcrumbs: [],
    backLink: "/",
    course: null,
    confirmDialog: false,
    delID: null,
    redirect: null,
  };

  componentDidMount() {
    this.props.fetchTranslations([
      "User profile",
      "ID",
      "First Name",
      "Last Name",
      "Email",
      "Status",
      "Credentials",
      "Edit user",
      "Change Password",
      "Delete",
      "Password change",
      "Please choose new password",
      "Cancel",
      "Save",
      "Passwords don't match",
      "Password",
      "Confirm password",
      "Back",
      "Deleting a student",
      "Are you sure you want to delete the student?",
    ]);
    this.populateCourse();
  }
  async componentDidUpdate(prevProps, prevState) {
    console.log(prevState, this.state);
    if (
      this.props.match.params.course_id !== prevProps.match.params.course_id ||
      prevProps.currentUser !== this.props.currentUser
    ) {
      this.populateCourse();
    }
  }
  async populateCourse() {
    const { programs } = await this.props;
    const { course_id } = this.props.match.params;
    let course = null;
    let program = null;
    if (programs && course_id) {
      program = programs.find((p) =>
        p.course_ids.includes(parseInt(course_id))
      );
      if (program) {
        course = program.courses.find(
          (c) => c.course_id === parseInt(course_id)
        );
      }
    }
    this.setState({ course }, this.populateBreadCrums);
  }
  populateBreadCrums = () => {
    const { course } = this.state;
    let { breadcrumbs, backLink = "/" } = this.props;
    breadcrumbs = [...breadcrumbs];
    const { group_id, user_id, course_id } = this.props.match.params;
    const courseLink =
      group_id && user_id
        ? `/groups/${group_id}/${user_id}/%course_id%`
        : `/students/${user_id}/%course_id%`;
    if (course_id && course) {
      backLink = `/students/${user_id}`;
      breadcrumbs.push({
        text: course.title,
        to: strFormat(courseLink, {
          "%course_id%": course_id,
        }),
      });
    }
    this.setState({ breadcrumbs, backLink });
  };
  

  closeEditModal = () => {
    this.setState({
      editModalOpen: false,
      // renderEditModal: false,
      // id: null,
    });
  };

  closeDialogHandler() {
    this.setState({ confirmDialog: false, delID: null });
  }

  closeEditPasswordModal = () => {
    this.setState({
      editPasswordModalOpen: false,
    });
  };

  async deleteUserHandler() {
    const {reduxUser, onShowMessage} = this.props;
    const { delID, backLink } = this.state;

    if( delID === reduxUser?.id ){
      onShowMessage("You cannot delete yourself");
      return false;
    }
    const result = await UserService.deleteOrganizationUser(delID);
    if (result) {
      onShowMessage(`User with id ${delID} was deleted.`, "info");
      this.setState({ redirect: backLink });
    } else {
      onShowMessage(
        `Error deleting user, please remove assigned programs before deletion.`,
        "error"
      );
    }
    this.closeDialogHandler();
  }

  calculateProgramProgress(courses) {
    // const { currentUser } = this.state;
    const { currentUser } = this.props;

    console.debug("Courses:", courses);
    if (!Array.isArray(courses) || !courses[0]) return 0;

    const result = courses.map((c) => {
      const course =
        currentUser.course_progress &&
        currentUser.course_progress.find(
          (item) => item.course_id === c.course_id
        );
      if (!course || course.progress === null) return 0;
      return course.progress;
    });
    let sum = result.reduce(function (sum, value) {
      return sum + value;
    }, 0);

    return Math.round(sum / result.length);
  }

  onPasswordChangeSubmit = async (password) => {
    const { onPasswordChange, onShowMessage } = this.props;
    // this should not happen
    if (!typeof onPasswordChange === "function")
      return onShowMessage("Wrong handler! Please, reaload page.");

    // if successfully changed - close the modal
    if (await onPasswordChange(password)) {
      this.closeEditPasswordModal();
      return true;
    }

    // if failed, return false
    return false;
  };

  render() {
    const {
      currentUser,
      reduxUser,
      programs,
      // users,
      error,
      // onPasswordChange,
      onStudentUpdate,
      onShowMessage,
      _t,
      // fetchTranslations,
    } = this.props;

    const showOrganizationProfile =
      currentUser &&
      Array.isArray(currentUser.credentials) &&
      currentUser.credentials.includes("organization");
    //console.log("UserProfile: ", this.props);
    const { group_id, user_id, course_id } = this.props.match.params;
    const courseLink =
      group_id && user_id
        ? `/groups/${group_id}/${user_id}/%course_id%`
        : `/students/${user_id}/%course_id%`;
    const {
      // renderEditModal,
      editModalOpen,
      editPasswordModalOpen,
      breadcrumbs,
      backLink,
      course,
      redirect,
      confirmDialog,
      delID = null ,
    } = this.state;
    const course_progress = {};
    // if onStudentUpdate is set, it's a student profile, not Organization user
    const isStudentProfile = Boolean(onStudentUpdate);
    const renderEditModal = isStudentProfile;

    // console.debug("user render currentUser", currentUser)
    if (!error && (!currentUser || !programs)) return <Spinner />;
    if (error) return <Error error={error} />;
    if (redirect) return <Redirect to={redirect} />;
    return (
      <Container className="user-profile">
        {breadcrumbs && (
          <AutoBreadcrumbs items={breadcrumbs} {...{ backLink }} backText={_t("Back")} />
        )}
        {course_id && !course && <Spinner />}
        {course_id && course && course_progress && (
          <GroupStudentCourse
            {...{ user_id, course, programs, course_progress }}
          />
        )}
        {!course_id && (
          <>
            <Typography variant="h1" color="primary">
              {_t("User profile")}
            </Typography>
            <Grid container className="details-container" spacing={4}>
              <Grid
                item
                lg={3}
                md={4}
                sm={4}
                xs={12}
                className="image-container"
              >
                <img
                  src={currentUser.image || `${PUBLIC_URL}/userPlaceholder.png`}
                  alt="user-placeholder"
                  className="image"
                ></img>
              </Grid>
              <Grid item lg={6} md={5} sm={5} xs={12} className="details-text">
                {[
                  { title: "#", value: currentUser.id },
                  { title: _t("ID"), value: currentUser.citizen_id },
                  { title: _t("First Name"), value: currentUser.name },
                  { title: _t("Last Name"), value: currentUser.last_name },
                  { title: _t("Email"), value: currentUser.email },
                  {
                    title: _t("Status"),
                    value: currentUser.status
                      ? currentUser.status.status_title || "?"
                      : "-",
                  },
                  {
                    title: _t("Credentials"),
                    value: Array.isArray(currentUser.credential_titles)
                      ? currentUser.credential_titles.join(", ")
                      : "-",
                  },
                ].map((detail, ind) => (
                  <DetailsRow {...detail} key={`detail-${ind}`} />
                ))}
              </Grid>
              <Grid
                item
                lg={3}
                md={3}
                sm={3}
                xs={12}
                className="buttons-container"
              >
                {/* <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={ backLink }
                >
                  Back
                </Button> */}
                <ButtonGroup className="setting-buttons">
                  {isStudentProfile && (
                    <Button
                      variant="contained"
                      onClick={() =>
                        this.setState({
                          editModalOpen: true,
                        })
                      }
                      color="primary"
                    >
                      {_t("Edit user")}
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    onClick={() =>
                      this.setState({
                        editPasswordModalOpen: true,
                      })
                    }
                    color="secondary"
                  >
                    {_t("Change Password")}
                  </Button>
                </ButtonGroup>
                { 
                // hide the Delete button when the profile belongs to current user (can't delete self)
                currentUser.id !== reduxUser.id &&
                <Button
                  onClick={() =>
                    this.setState({ confirmDialog: true, delID: user_id })
                  }
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  // disabled = {currentUser.id === reduxUser.id }
                >
                  {_t("Delete")}
                </Button>
                }
              </Grid>
            </Grid>
            {showOrganizationProfile && (
              <OrganizationProfile {...{ currentUser, onShowMessage }} />
            )}

            
            {currentUser.programs && (
              <UserProgress {...{ currentUser, programs, courseLink }} />
            )}

            {renderEditModal && (
              <ModalEditStudent
                user={currentUser}
                onModalOpen={editModalOpen}
                onClose={this.closeEditModal}
                onUpdate={onStudentUpdate}
              />
            )}
            {delID && <ConfirmDialog 
              header={_t("Deleting a student")}
              prompt={_t("Are you sure you want to delete the student?")}
              onClose={() => this.setState({delID: null})}
              onConfirm={() => this.deleteUserHandler()}
              open={Boolean(delID)}

            />}
            {/* {confirmDialog && delID && (
              <Dialog
                open={confirmDialog}
                onClose={() => this.setState({ confirmDialog: false, delID: null })}
              >
                <DialogTitle>
                  {_t("Are you sure you want to delete the student?")}
                </DialogTitle>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.setState({ confirmDialog: false, delID: null })}
                  >
                    {_t("Cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => this.deleteUserHandler()}
                  >
                    {_t("Delete")}
                  </Button>
                </DialogActions>
              </Dialog>
            )} */}
            <PasswordChangeDialog
              open={Boolean(editPasswordModalOpen)}
              headerText = {_t("Password change")}
              promptText = {_t("Please choose new password")}
              cancelText = {_t("Cancel")}
              saveText = {_t("Save")}
              passwordLabelText = {_t("Password")}
              password2LabelText = {_t("Confirm password")}
              passwordErrorText = {_t("Passwords don't match")}
              onClose={this.closeEditPasswordModal}
              onSubmit={this.onPasswordChangeSubmit}
            />
          </>
        )}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};

const stateToProps = (state) => ({ reduxUser: state.auth.user });

export default connect(stateToProps, mapDispatchToProps)(WithTranslations(UserProfile));
