import React from "react";
import {
  Grid,
  Button,
  Typography,
  // Modal,
  TextField,
  // Switch,
  List,
  ListItem,
  ListItemText,
  // ListItemSecondaryAction,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Tab,
  Tabs,
  ButtonGroup,
  CircularProgress,
} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import UserService from "../../../services/UserService";
import { connect } from "react-redux";
import { showMessage } from "../../../redux/notificationActions";
import ProgramService from "../../../services/ProgramService";
import ConfirmDialog from "../ConfirmDialog";
import {ModalWindow} from "@ses-education/courses-components";
import {
  AssignmentInd,
  // DeleteOutline as DeleteOutlineIcon,
  AddBox as AddBoxIcon,
  Delete,
  Close,
} from "@material-ui/icons";
// import ReactFileReader from "react-file-reader";
import OrganizationService from "../../../services/OrganizationService";
import { validEmail } from "../../../utils/regEx";
import Spinner from "../Spinner";
import "./modal-edit-student.scss";
import WithTranslations from "../../WithTranslations";
import LocalizedAdminTable from "../localized-admin-table";
import { strFormat } from "../../../utils/functions";
class ModalEditStudent extends React.Component {
  state = {
    user: null,
    programs: [],
    groups: [],
    user_groups: [],
    tabSelector: 0,
    errors: {},
    // users: [],
    image: {},
    // changeStatusDialog: false,
    assignProgramDialog: false,
    assignGroupDialog: false,
    selectedProgram: null,
    selectedGroup: null,
    groupToUnassign: false,
    programToUnassign: false,
    confirmDialogProps: null
  };
  componentDidMount = async () => {
    let { user } = this.props;
    if (user.programs) {
      user.programs = [
        ...user.programs.map((p) => {
          return {
            ...p,
            id: p.program_id,
          };
        }),
      ];
    }
    this.setState({ user });
    await this.fetchPrograms();
    await this.fetchGroups();
    await this.fetchUsers();
    this.props.fetchTranslations([
      "Cancel",
      "Assign",
      "name",
      "Actions",
      `User details`,
      "User ID",
      "Citizen ID",
      "User Name",
      "User Last Name",
      "User Email",
      "Activate",
      "Are you sure you want to activate user %username%?",
      "Are you sure you want to suspend user %username%?",
      "Are you sure you want to unassign '%program%' from this student?",
      "Are you sure you want to remove the student from the group '%group%'?",
      "Choose program to assign to user %username%",
      "Suspend",
      "Submit changes",
      `Assigned Programs`,
      "Assigned programs",
      "Assign program",
      "No programs assigned",
      `Assigned Groups`,
      "Assigned groups",
      "Assign group",
      "No groups assigned",
      "Search",
      "Unknown error",
      "Field must be at least 2 charachters long",
      "Email required",
      "Email already exists",
      "Invalid email address",
      "Field must be maximum 100 charachters long",
      "citizen id already exist",
      "Could not update image: user is not saved",
      "No image selected",
      "Image successfully updated",
      "Program with id '%pid%' was added to user with id %uid%",
      "Program with id '%pid%' was removed from user with id '%uid%'",
      "group with id '%gid%' was added to user with id '%uid%'",
      "group with id '%gid%' was removed from user with id '%uid%'",
      "Status",
      "Removing user from program"
    ])
  };
  componentDidUpdate = async (prevProps) => {
    if (prevProps.user !== this.props.user && this.props.user) {
      //console.log("got in componentDidUpdate")
      const { user } = this.props;
      if (user && user.programs) {
        user.programs = [
          ...user.programs.map((p) => {
            return {
              ...p,
              id: p.program_id,
            };
          }),
        ];
      }
      this.setState({ user }, this.fetchGroups);
    }
    if(prevProps._language != this.props._language){
      await this.fetchPrograms();
    }
  };
  fetchPrograms = async () => {
    const {_t} = this.props;
    const programs = await ProgramService.getPrograms();
    if (programs) {
      this.setState({ programs });
    } else {
      this.props.onShowMessage(
        OrganizationService.error || _t("Unknown error")
      );
    }
  };
  fetchGroups = async () => {
    const groups = await OrganizationService.getStudentGroups();
    const { user } = this.state;
    const {_t} = this.props;
    if (groups) {
      console.log("groups", groups);
      const user_groups = groups
        .filter((group) => {
          if (user && !user.credentials.includes("teacher")) {
            return (group.students || []).find(
              (student) => student.id === this.state.user.id
            );
          }
          if (user && user.credentials.includes("teacher")) {
            return (group.teachers || []).find(
              (student) => student.id === this.state.user.id
            );
          }
        })
        .map((g) => {
          return {
            ...g,
            id: g.student_group_id,
          };
        });
      // console.log("fetchGroups", groups, user_groups);
      this.setState({ groups, user_groups });
    } else {
      this.props.onShowMessage( OrganizationService.error || _t("Unknown error"), "error");
    }
  };

  async fetchUsers() {
    const { onShowMessage, _t } = this.props;
    try {
      let users = await UserService.getUsers();

      if (users) {
        this.setState({ users });
        return true;
      } else {
        onShowMessage( UserService.error || _t("Unknown error"), "error");
        return false;
      }
    } catch (err) {
      this.props.onShowMessage( UserService.error || _t("Unknown error"), "error");
      return false;
    }
  }
  
  /**
     * Sets props for ConfirmDialog and shows it
     * @param {*} prompt 
     * @param {*} onConfirm 
     */
  askConfirmation = ( prompt, onConfirm, header = "Please, confirm" ) => {
    // console.debug("asking confirmation", prompt, onConfirm, header);
    this.setState({
      confirmDialogProps:
      {
        prompt, 
        onConfirm: () => {
          // call the callback
          onConfirm();
          // close the dialog
          this.setState({confirmDialogProps: null})
        }, 
        header
      }})
  }

  assignProgramToUserHandler = async () => {
    const { selectedProgram, programs, user } = this.state;
    const { onUpdate, onShowMessage, _t } = this.props;
    const result = await ProgramService.assignProgramToUser(
      user.id,
      selectedProgram
    );
    if (result) {
      // send update to parent component
      await onUpdate(user);
      // find the desired program among all possible
      const assignedProgram = programs.find(
        (p) => p.program_id === selectedProgram
      );
      // manually add the assigned program to modal state to trigger rerender
      this.setState((prevState) => ({
        user: {
          ...this.state.user,
          programs: [
            ...(prevState.user.programs || []),
            { ...assignedProgram, id: assignedProgram.program_id },
          ],
        },
        assignProgramDialog: false,
        selectedProgram: null,
      }));
      onShowMessage(
        strFormat( _t("Program with id '%pid%' was added to user with id %uid%"), {"%pid%": selectedProgram, "%uid%": user.id}),
        "info"
      );
    }
  };
  unassignProgramFromUserHandler = async (program_id) => {
    const { user, onUpdate, onShowMessage, _t } = this.props;

    // close confirmation dialog
    this.setState({ programToUnassign: false });

    const result = await ProgramService.unassignProgramFromUser(
      user.id,
      program_id
    );
    if (result) {
      await onUpdate();

      // manually eject the deleted program from modal state to trigger rerender
      this.setState((prevState) => ({
        user: {
          ...this.state.user,
          programs: [
            ...prevState.user?.programs?.filter(
              (p) => p.program_id !== program_id
            ) || [],
          ],
        },
      }));
      onShowMessage(
        strFormat(_t("Program with id '%pid%' was removed from user with id '%uid%'"), {"%pid%": program_id, "%uid%": user.id}),
        "info"
      );
    }
  };
  assignGroupToUserHandler = async () => {
    const { selectedGroup } = this.state;
    const { user, onUpdate, onShowMessage, _t } = this.props;
    const func =
      user && user.credentials.includes("teacher")
        ? "assignTeachers"
        : "assignStudents";
    const result = await OrganizationService[func](selectedGroup, [user.id]);
    if (result) {
      // send update to parent component
      await onUpdate(user);
      await this.fetchGroups();
      onShowMessage(
        strFormat( _t(`group with id '%gid%' was added to user with id '%uid%'`),{
          "%gid%": selectedGroup,
          "%uid%": user.id
        } ) ,
        "info"
      );
      this.setState({
        assignGroupDialog: false,
        selectedGroup: null,
      });
    } else {
      onShowMessage(
        OrganizationService.error ||
          _t("Unknown error"),
        "error"
      );
    }
  };
  unassignGroupFromUserHandler = async (student_group_id) => {
    const { user, onUpdate, onShowMessage, _t } = this.props;

    // close confirmation dialog
    this.setState({ groupToUnassign: false });
    const func =
      user && user.credentials.includes("teacher")
        ? "unassignTeachers"
        : "unassignStudents";
    const result = await OrganizationService[func](student_group_id, [user.id]);
    if (result) {
      await onUpdate();
      await this.fetchGroups();
      onShowMessage(
        strFormat( _t(`group with id '%gid%' was removed from user with id '%uid%'`),{
          "%gid%": student_group_id,
          "%uid%": user.id
        } ) ,
        "info"
      );
    } else {
      onShowMessage(
        OrganizationService.error ||
          _t("Unknown error"),
        "error"
      );
    }
  };
  changeStatusHandler = async () => {
    const {  onShowMessage, onUpdate, _t, reduxUser} = this.props;
    const {user} = this.state;

    if( reduxUser.id === user.id ){
      // do nothing - cannot change self status
      console.debug("Attempt to change self status: can't do!")
      return false;
    }
    
    // if previous user status is created or suspended - we activate them, 
    // otherwise (active, dormant) - suspend them
    const newStatus = ["created", "suspended"].includes(user.status.status_id) ? "activate" : "suspend" ;

    if (user.status.status_id === newStatus) {
      console.debug("Attempt to change status to same value: nothing to do")
      return false; //do nothing, the state is the same
    }

    const result = await UserService.changeUserStatus(
      user.id,
      
      newStatus
    );

    if (!result) {
      onShowMessage( UserService.error || _t("Unknown error"), "error");
      // onShowMessage(
      //   `Status change for user ${user.name} ${user.last_name} failed on server.`,
      //   "error"
      // );
    } else {
      onUpdate();
    }
    

    // this.setState(
    //   (prevState) => ({
    //     user: {
    //       ...prevState.user,
    //       status: {
    //         ...prevState.user.status,
    //         status_id:
    //           prevState.user.status.status_id !== "active"
    //             ? "active"
    //             : "suspended",
    //       },
    //     },
    //   }),
    //   async () => {
    //     const { user: puser, onShowMessage, onUpdate, _t } = this.props;
    //     if (user.status.status_id !== puser.status.status_id) {
    //       const result = await UserService.changeUserStatus(
    //         user.id,

    //         // ["created", "suspended"].includes(user.status.status_id) ? "activate" : "suspend" 
            
    //       );
    //       if (!result) {
    //         onShowMessage( UserService.error || _t("Unknown error"), "error");
    //         // onShowMessage(
    //         //   `Status change for user ${user.name} ${user.last_name} failed on server.`,
    //         //   "error"
    //         // );
    //       } else {
    //         onUpdate();
    //       }
    //     }
    //   }
    // );
    // send status changes to server if needed
    // this.setState({ changeStatusDialog: false });
  };
  submitHandler = async (ev) => {
    ev.preventDefault();
    const { user } = this.state;
    const { onUpdate, onShowMessage, onClose } = this.props;

    let dataRes = await UserService.updateOrganizationUser(user, user.id);

    // when creating new user dataRes returns as an object {id: some-int}
    // when editing existing user dataRes returns as integer
    if (typeof dataRes === "object" && dataRes.id) dataRes = dataRes.id;

    // //update user image
    // const formData = {
    //   image: this.state.image,
    // };
    // console.log("image:", formData, this.state.user.id);
    // const result = UserService.updateOrganizationUserImage(
    //   formData,
    //   this.state.user.id
    // );
    // if (!result) {
    //   onShowMessage(
    //     `Image change for user ${user.name} ${user.last_name} failed on server.`,
    //     "error"
    //   );
    // }

    // send update back to users page
    if (dataRes) {
      await onUpdate();
      onClose();
      onShowMessage(`User with id ${user.id} was updated.`, "info");
    } else {
      onShowMessage(
        UserService.error || "Unknown error while update user",
        "error"
      );
    }
  };

  validateUser = () => {
    const { user } = this.state;
    const {_t} = this.props;
    let errors = {};
    if (user.name.length < 2) {
      errors["name"] = _t("Field must be at least 2 charachters long");
    }
    if (user.last_name.length < 2) {
      errors["last_name"] = _t("Field must be at least 2 charachters long");
    }
    const emailIsValid = this.validateEmail(user.email);

    if (emailIsValid.error) {
      errors["email"] = emailIsValid.error;
    }
    const idValid = this.validateId(user.citizen_id);

    if (idValid.error) {
      errors["citizen_id"] = idValid.error;
    }
    this.setState({ errors });
  };

  validateEmail(email) {
    const { user, users } = this.state;
    const {_t} = this.props;

    if (email.length === 0) {
      return { error: _t("Email required") };
    }
    if (users.find((u) => u.email === email && u.id !== user.id)) {
      return { error: _t("Email already exists") };
    }
    if (!validEmail.test(email)) {
      return { error: _t("Invalid email address") };
    }
    return true;
  }
  validateId(Id) {
    const { users, user } = this.state;
    const {_t} = this.props;
    if (Id) {
      if (Id.length > 100) {
        return { error: _t("Field must be maximum 100 charachters long") };
      }
      if (
        users.find((u) => u.citizen_id === user.citizen_id && u.id !== user.id)
      ) {
        return { error: _t("citizen id already exist") };
      }
    }

    return true;
  }
  /**
   * Handle image update
   * @param {*} files
   * @returns
   */
  handleFiles = async ({ target }) => {
    const { user, onShowMessage, onUpdate, _t } = this.props;
    const { id } = user;

    // check that id is passed
    if (!id) {
      onShowMessage(_t("Could not update image: user is not saved"), "error");
      return false;
    }

    const image = target.files && target.files[0] ? target.files[0] : null;
    // this.setState({ image }, () => console.log("image", this.state));
    if (!image) {
      onShowMessage(_t("No image selected"), "error");
      return false;
    }

    // build form data with file
    const data = new FormData();
    data.append("image", image);

    if (!(await UserService.updateUserImage(id, data))) {
      onShowMessage(UserService.error || _t("Unknown error"), "error");
      return;
    }

    // reload user data on image update
    onUpdate();

    onShowMessage(_t("Image successfully updated"));
  };

  render = () => {
    const {
      user,
      programs,
      groups,
      user_groups,
      tabSelector,
      errors,
      assignProgramDialog,
      assignGroupDialog,
      selectedProgram,
      selectedGroup,
      programToUnassign,
      groupToUnassign,
      // changeStatusDialog,

      confirmDialogProps
    } = this.state;
    console.debug("confirmDialogProps:", confirmDialogProps);

    const { user: open, users, onClose, onModalOpen, _t, reduxUser } = this.props;
    const buttons = (
      <Grid item className="buttons-container">
        <Button variant="contained" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={
            Boolean(Object.keys(errors).length) ||
            JSON.stringify(user) === JSON.stringify(open)
          }
          style={{
            marginLeft: "10px",
          }}
        >
          {"Submit changes"}
        </Button>
      </Grid>
    );
    const group_buttons = (
      <ButtonGroup>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            this.setState({ assignGroupDialog: false, selectedGroup: null })
          }
        >
          {_t("Cancel")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => this.assignGroupToUserHandler()}
        >
          {_t("Assign")}
        </Button>
      </ButtonGroup>
    );
    const Programs_columns = [
      { field: "id", hide: true },
      { field: "title", flex: 1, headerName: _t("name") },
      {
        field: "actions",
        headerName: _t("Actions"),
        flex: 1,
        renderCell: ({ row }) => {
          return (
            <ButtonGroup>
              <Button
                onClick={
                  // () => this.setState({ programToUnassign: row })
                  // this.unassignProgramFromUserHandler(
                  //   row.program_id
                  // )
                  () =>
                  this.askConfirmation(
                    `Are you sure you want to remove user from the program '${row.title}'?`,
                    () => {
                      this.unassignProgramFromUserHandler(row.program_id);
                    },
                    _t("Removing user from program")
                  )
                }
                style={{ cursor: "pointer", fill: "#db0047" }}
              >
                <Delete />
              </Button>
            </ButtonGroup>
          );
        },
      },
    ];
    const Groups_columns = [
      { field: "id", hide: true },
      { field: "title", flex: 1, headerName: _t("name") },
      {
        field: "actions",
        headerName: _t("Actions"),
        flex: 1,
        renderCell: ({ row }) => {
          return (
            <ButtonGroup>
              <Button
                onClick={
                  // () => this.setState({ groupToUnassign: row })
                  () =>
                  this.askConfirmation(
                    `Are you sure you want to remove the student from the group '${row.title}'?`,
                    () => {
                      this.unassignGroupFromUserHandler(row.student_group_id);
                    },
                    _t("Removing user from group")
                  )
                }
                style={{ cursor: "pointer", fill: "#db0047" }}
              >
                <Delete />
              </Button>
            </ButtonGroup>
          );
        },
      },
    ];
    const tabs = [
      {
        label: _t(`User details`),
        html: (
          <div className="user-details">
            {user && (
              <>
                <TextField
                  id="edit-user-id"
                  label={_t("User ID")}
                  variant="outlined"
                  className="text-field"
                  // style={{ marginBottom: "6px" }}
                  disabled={true}
                  value={user.id}
                />
                <TextField
                  id="edit-user-citizen-id"
                  label={_t("Citizen ID")}
                  variant="outlined"
                  className="text-field"
                  // style={{ marginBottom: "6px" }}
                  error={Boolean(errors["citizen_id"])}
                  helperText={errors["citizen_id"]}
                  onChange={(event) => {
                    const user = {
                      ...this.state.user,
                      citizen_id: event.target.value,
                    };
                    this.setState({ user }, () => this.validateUser());
                  }}
                  value={user.citizen_id}
                />
                <TextField
                  id="edit-user-name"
                  label={_t("User Name")}
                  variant="outlined"
                  className="text-field"
                  // style={{ marginBottom: "6px" }}
                  error={Boolean(errors["name"])}
                  helperText={errors["name"]}
                  onChange={(event) => {
                    const user = {
                      ...this.state.user,
                      name: event.target.value,
                    };
                    this.setState({ user }, () => this.validateUser());
                  }}
                  value={user.name}
                />
                <TextField
                  id="edit-user-last-name"
                  label={_t("User Last Name")}
                  variant="outlined"
                  className="text-field"
                  // style={{ marginBottom: "6px" }}
                  error={Boolean(errors["last_name"])}
                  helperText={errors["last_name"]}
                  onChange={(event) => {
                    const user = {
                      ...this.state.user,
                      last_name: event.target.value,
                    };
                    this.setState({ user }, () => this.validateUser());
                  }}
                  value={user.last_name}
                />
                <TextField
                  id="edit-user-email"
                  label={_t("User Email")}
                  variant="outlined"
                  inputProps={{
                    type: "email",
                  }}
                  className="text-field"
                  // style={{ marginBottom: "6px" }}
                  error={Boolean(errors["email"])}
                  helperText={errors["email"]}
                  onChange={(event) => {
                    const user = {
                      ...this.state.user,
                      email: event.target.value,
                    };
                    this.setState({ user }, () => this.validateUser());
                  }}
                  value={user.email}
                />
              </>
            )}
            <div className="flex row">
              <div className="image-container">
                <label className="image-selector" htmlFor="image-file">
                  <input
                    type="file"
                    id="image-file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={this.handleFiles}
                  />
                  <img
                    src={
                      (user && user.image) ||
                      `${process.env.PUBLIC_URL}/static/images/userPlaceholder.jpg`
                    }
                    alt="user-placeholder"
                  />
                </label>
              </div>
              <div className="activity-and-buttons-container">
                {user && (
                  <div className="modal-switch">
                    <div className="flex column align-items-center p-10">
                      <Typography color="primary">{_t("Status")}</Typography>
                      <span className="status">
                        <AssignmentInd />
                        {user.status.status_title}
                      </span>
                    </div>

                    { 
                      // don't show change status button if it's the current user
                      (reduxUser.id !== user.id) &&
                      <Button
                        variant="contained"
                        color={
                          user.status.status_id !== "active"
                            ? "primary"
                            : "secondary"
                        }
                        size="small"
                        onClick={() =>
                          // this.setState({ changeStatusDialog: true })
                          this.askConfirmation(
                            // prompt
                            (user && user.status.status_id !== "active")
                            ? `Are you sure you want to activate user ${user.name} ${user.last_name}?`
                            : `Are you sure you want to suspend user ${user.name} ${user.last_name}?`,
                            
                            // onConfirm
                            this.changeStatusHandler
                            ,
                            // header
                            "Changing user status"
                          )
                        }
                      >
                        {user.status.status_id !== "active"
                          ? _t("Activate")
                          : _t("Suspend")}
                      </Button>
                    }
                  </div>
                )}
              </div>
            </div>
            
            <Grid item className="buttons-container">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={
                  Boolean(Object.keys(errors).length) ||
                  JSON.stringify(user) === JSON.stringify(open)
                }
              >
                {_t("Submit changes")}
              </Button>
            </Grid>
          </div>
        ),
      },
      {
        label: _t(`Assigned Programs`),
        credentials: "student",
        html: (
          <div className="assigned-programs">
            {user && programs && (
              <Dialog
                open={assignProgramDialog}
                onClose={() =>
                  this.setState({
                    assignProgramDialog: false,
                    selectedProgram: null,
                  })
                }
                className={"assign-program-dialog"}
              >
                <DialogTitle>
                  { strFormat(_t("Choose program to assign to user %username%"), {"%username%": `${user.name} ${user.last_name}`}) }
                </DialogTitle>
                <DialogContent>
                  <List>
                    {user &&
                      programs
                        .filter(
                          (p) =>
                            !user.programs ||
                            (user.programs &&
                              !user.programs.find(
                                (pp) => pp.program_id === p.program_id
                              ))
                        )
                        .map((program) => {
                          const labelId = `checkbox-list-label-${program}`;
                          return (
                            <ListItem
                              key={program.program_id}
                              dense
                              button
                              onClick={() =>
                                this.setState({
                                  selectedProgram: program.program_id,
                                })
                              }
                              selected={program.program_id === selectedProgram}
                            >
                              <ListItemText
                                id={labelId}
                                primary={`${program.program_id} - Program ${program.title}`}
                              />
                            </ListItem>
                          );
                        })}
                  </List>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      this.setState({
                        assignProgramDialog: false,
                        selectedProgram: null,
                      })
                    }
                  >
                    {_t("Cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => this.assignProgramToUserHandler()}
                  >
                    {_t("Assign")}
                  </Button>
                </DialogActions>
              </Dialog>
            )}
            <div className="buttons-container space-between">
              <span className="title">{_t("Assigned programs")}</span>
              {user && (user.programs || []).length < programs.length && (
                <Button
                  variant="contained"
                  className="bg-green color-white"
                  onClick={() => this.setState({ assignProgramDialog: true })}
                  size="small"
                >
                  <AddBoxIcon />
                  {_t("Assign program")}
                </Button>
              )}
            </div>
            {user &&
              ((user.programs && user.programs.length === 0) ||
                !user.programs) && (
                <Typography style={{ textAlign: "center" }}>
                  {_t("No programs assigned")}
                </Typography>
              )}
            {user && user.programs && (
              <LocalizedAdminTable
                autoHeight
                searchPlaceholder = {_t("Search")}
                columns={Programs_columns}
                rows={user.programs}
              />
            )}
            {!user && <Spinner />}
          </div>
        ),
      },
      {
        label: _t(`Assigned Groups`),
        html: (
          <div className="assigned-groups">
            {user && groups && user_groups && (
              <ModalWindow
                open={assignGroupDialog}
                onClose={() =>
                  this.setState({
                    assignGroupDialog: false,
                    selectedGroup: null,
                  })
                }
                buttons={group_buttons}
              >
                <Typography>
                  {`Choose group to assign to user ${user.name} ${user.last_name}`}
                </Typography>
                <List>
                  {user &&
                    groups &&
                    user_groups &&
                    groups
                      .filter(
                        (p) =>
                          !user_groups.find(
                            (pp) => pp.student_group_id === p.student_group_id
                          )
                      )
                      .map((group) => {
                        const labelId = `checkbox-list-label-${group}`;
                        return (
                          <ListItem
                            key={group.student_group_id}
                            dense
                            button
                            onClick={() =>
                              this.setState({
                                selectedGroup: group.student_group_id,
                              })
                            }
                            selected={group.student_group_id === selectedGroup}
                          >
                            <ListItemText
                              id={labelId}
                              primary={`${group.student_group_id} - Group ${group.title}`}
                            />
                          </ListItem>
                        );
                      })}
                </List>
              </ModalWindow>
            )}
            <div className="buttons-container space-between">
              <span className="title">{_t("Assigned groups")}</span>
              {user && user_groups && user_groups.length < groups.length && (
                <Button
                  variant="contained"
                  className="bg-green color-white"
                  onClick={() => this.setState({ assignGroupDialog: true })}
                  size="small"
                >
                  <AddBoxIcon />
                  {_t("Assign group")}
                </Button>
              )}
            </div>

            {user && user_groups && user_groups.length === 0 ? (
              <Typography style={{ textAlign: "center" }}>
                {_t("No groups assigned")}
              </Typography>
            ) : user && user_groups ? (
              <LocalizedAdminTable
                autoHeight
                searchPlaceholder = {_t("Search")}
                columns={Groups_columns}
                rows={user_groups}
              />
            ) : (
              <Spinner />
            )}
          </div>
        ),
      },
    ].filter(
      (u) =>
        !u.credentials || (user && user.credentials.includes(u.credentials))
    );
    const cancel = (
      <div className="cancel-button-container">
        <Button onClick={onClose}>
          <Close />
        </Button>
      </div>
    );
    console.log("user", user);

    if (Array.isArray(users)) return <CircularProgress />;
    return (
      <>
        <ModalWindow
          open={Boolean(open) && onModalOpen}
          onClose={onClose}
          onSubmit={this.submitHandler}
          header={cancel}
          //{...{ buttons }}
          className="edit-student"
        >
          <Tabs value={tabSelector}>
            {tabs.map((tab, index) => (
              <Tab
                label={tab.label}
                onClick={() => this.setState({ tabSelector: index })}
              />
            ))}
          </Tabs>
          <SwipeableViews
            // when not apply the SwipeableViews width extend the ModalWindow width
            style={{ width: "100%" }}
            index={tabSelector}
            onChangeIndex={(tabSelector) => this.setState({ tabSelector })}
          >
            {tabs.map((tab) => tab.html)}
          </SwipeableViews>
        </ModalWindow>
        
        {
        <ConfirmDialog
            open={Boolean(confirmDialogProps)}
            {...confirmDialogProps}
            onClose={() => this.setState({confirmDialogProps: null})}
          />
        }
      </>
      
    );
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};

const stateToProps = (state) => {
  return {
    reduxUser: state.auth.user
  }
}
export default connect( stateToProps, mapDispatchToProps)(WithTranslations(ModalEditStudent));
