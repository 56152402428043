import Base from "./baseClientService";

class ProgressService extends Base {
  static async getUserCourseProgress(user_id, course_id) {
    return await this.GET(
      `/organization/users/progress/${user_id}/course/${course_id}`
    );
  }

  static async resetCourseProgress(user_id, course_id) {
    return await this.POST(
      `/organization/users/progress/${user_id}/course/${course_id}/reset`
    );
  }

  static async resetExperimentProgress(user_id, experiment_id) {
    return await this.POST(
      `/organization/users/progress/${user_id}/experiment/${experiment_id}/reset`
    );
  }

  // static async resetExperimentPageProgress( user_id, experiment_id, page_slug ) {
  static async resetExperimentPageProgress(user_id, experiment_id) {
    // return await this.POST(`/organization/users/progress/${user_id}/experiment/${experiment_id}/page/${page_slug}/reset`);
    return await this.POST(
      `/organization/users/progress/${user_id}/experiment/${experiment_id}/page/reset`
    );
  }
}

export default ProgressService;
