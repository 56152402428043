import React from "react";
import { Typography, Button } from "@material-ui/core";
import { SaveAlt, FilterList, Check, Close } from "@material-ui/icons";
import AsyncButton from "../../../../template/AsyncButton";
import FilterForm from "../../../../template/filter-form";
import Error from "../../../../template/Error";
import Spinner from "../../../../template/Spinner";
import { Redirect } from "react-router-dom";
import OrganizationService from "../../../../../services/OrganizationService";
import "./report-courses.scss";
import ProcessSpinner from "../../../../template/ProcessSpinner";
import ReportCourseChapter from "./report-course-chapter";

/**
 * Chapters to display in the report. Change the array to display only needed chapters
 */
const display_chapters = ["summary"];

class ReportCourses extends React.Component {
  state = {
    studentCoursesOriginal: [],
    studentCourses: [],
    error: null,
    onfilter: null,
    onRedirect: false,
    fields: [],
    processSpinnerMessage: null,
    redirect: null,
  };

  componentDidMount = () => {
    this.setState(
      {
        // set spinner on
        processSpinnerMessage: "Fetching data from server...",
      },
      () => {
        this.props.fetchTranslations([
          "Courses Report",
          "export To Excel",
          "filter",
          "Experiment",
          "No data found",
        ]);

        this.populatestudentCourses();
      }
    );
  };
  componentDidUpdate = async (prevProps) => {
    const course_ids = [
      ...(this.props.course_ids?.replace(/\s+/g, "").split("|") || []),
    ];
    const courses = Object.keys(
      this.state.fields.find((field) => field.id === "courses")?.options || {}
    );
    if(this.state.onRedirect){
      this.setState({onRedirect: false, redirect: null}, this.populatestudentCourses);
    }
    // TODO: figure out if we need this at all
    // if (
    //   prevProps.students !== this.props.students ||
    //   (Array.isArray(courses) && courses.length !== course_ids.length)
    // ) {
    //   this.setState(
    //     { redirect: null, fields: [] }
    //     // this.populatestudentCourses
    //   );
    // }

    if (this.state.redirect && courses.length === course_ids.length) {
      // this.setState({ redirect: null });
    }
  };
  fetchCourseData = async () => {
    const { students } = this.props;
    const { fields } = this.state;
    if (Array.isArray(students)) {
      const result = await OrganizationService.getByUsersAndCourses(
        students.map((st) => st.id),
        fields.find((field) => field.id === "courses").value
      );
      const { onShowMessage } = this.props;
      if (!result) {
        onShowMessage(
          OrganizationService.error || `Unknown error fetching courses Data`,
          "error"
        );
        // reset spinner
        this.setState({ processSpinnerMessage: null });
        return false;
      }
      this.setState({
        studentCourses: result,
        studentCoursesOriginal: result,
        processSpinnerMessage: null,
      });
    }
  };
  populatestudentCourses = async () => {
    const { students, onShowMessage } = this.props;

    const student_ids = students.map((s) => s.user_id);

    console.debug("student ids:", student_ids);
    // return;
    let courses = await OrganizationService.getOrganizationCoursesByUsers(
      student_ids
    );

    if (!Array.isArray(courses)) {
      return onShowMessage(OrganizationService.error || "Unknown error!");
    }
    const selected_course_ids = this.props.course_ids?.replace(/\s+/g, "") ? [
      ...(this.props.course_ids?.replace(/\s+/g, "").split("|") || []),
    ] : [...(courses.map(c=> `${c.course_id}`) || [])];

    courses = courses.reduce((res, c) => ({
      ...res,
      [c.course_id]: `${c.course_code} : ${c.title}`,
    }),{});

    // let courses = {};
    // if (Array.isArray(students)) {
    //   students.map((st) => {
    //     if (Array.isArray(st.assigned_courses)) {
    //       st.assigned_courses.map((c) => {
    //         if (
    //           !selected_course_ids[0] ||
    //           selected_course_ids.find((cc) => cc === `${c.course_id}`)
    //         )
    //           courses[c.course_id] = c.title;
    //       });
    //     }
    //   });
    // }
    this.setState(
      {
        fields: [
          ...(this.state.fields.filter((field) => field.id !== "courses") ||
            []),
          {
            id: "courses",
            label: "Select courses",
            // type: "chips",
            type: "multiselect",
            //fieldsToCompare: [ "status"],
            validate: (value) => !(value && Array.isArray(value) && value.length > 0) && 
            "must have at least one course selected",
            options: courses,
            value: selected_course_ids,
            processSpinnerMessage: "populating list...",
          },
        ],
      },
      async () => await this.fetchCourseData()
    );
  };
  // populatestudentCourses = () => {
  //   const { students } = this.props;
  //   const course_ids = [
  //     ...(this.props.course_ids?.replace(/\s+/g, "").split("|") || []),
  //   ];
  //   let courses = {};
  //   if (Array.isArray(students)) {
  //     students.map((st) => {
  //       if (Array.isArray(st.assigned_courses)) {
  //         st.assigned_courses.map((c) => {
  //           if (
  //             !course_ids[0] ||
  //             course_ids.find((cc) => cc === `${c.course_id}`)
  //           )
  //             courses[c.course_id] = c.title;
  //         });
  //       }
  //     });
  //   }
  //   this.setState(
  //     {
  //       fields: [
  //         ...(this.state.fields.filter((field) => field.id !== "courses") ||
  //           []),
  //         {
  //           id: "courses",
  //           label: "Select courses",
  //           // type: "chips",
  //           type: "multiselect",
  //           //fieldsToCompare: [ "status"],
  //           options: courses,
  //           value: Object.keys(courses),
  //           processSpinnerMessage: "populating list...",
  //         },
  //       ],
  //     },
  //     async () => await this.fetchCourseData()
  //   );
  // };
  filterAll = (filter) => {
    console.log("filter test", filter);
    let { fields } = this.state;
    const { studentCoursesOriginal } = this.state;
    // update fields
    fields = fields.map((field) => {
      if (field.id in filter) {
        field.value = filter[field.id];
      }
      return field;
    });
    // filter courses
    //let studentCourses;
    fields.map((field) => {
      if (field.id === "courses") {
        // studentCourses = studentCoursesOriginal.filter((course) =>
        //   field.value.includes(`${course.course_id}`)
        // );
        if (field.value.join("|") !== this.props.course_ids?.replace(/\s+/g, "")){
          this.setState({
            onRedirect: true,
            redirect: `/reports/course/${
              Object.keys(field.options || {}).length > field.value.length
                ? field.value.join("|")
                : ""
            }`,
            studentCourses: null,
          });
        }
      }
    });
    this.setState({ fields });
  };
  fetchReport = async () => {
    const { studentCourses } = this.state;
    const result = await OrganizationService.courseReportExcel({
      studentCourses,
    });
    const { onShowMessage } = this.props;
    if (!result) {
      onShowMessage(
        OrganizationService.error || `Unknown error fetching report`,
        "error"
      );
      return false;
    }
    const ab = new ArrayBuffer(result.data.length);
    const view = new Uint8Array(ab);
    for (let i = 0; i < result.data.length; ++i) {
      view[i] = result.data[i];
    }
    //console.log(ab,result.data[0]);
    const blob = new Blob([ab]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "COURSE_REPORT.xlsx");
    link.click();
    window.URL.revokeObjectURL(url);
    console.log("your file has downloaded!");
  };
  render() {
    const { _t, report, students, studentsprogress } = this.props;
    const {
      error,
      redirect,
      onfilter,
      fields,
      studentCourses,
      studentCoursesOriginal,
      processSpinnerMessage,
    } = this.state;
    if (report && !error && !(students && studentsprogress)) {
      return <Redirect to={"/reports"} />;
    }
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    return (
      <div className="course-report">
        {error && <Error error={error} />}
        {!error && !studentCourses && <Spinner />}
        {!error && studentCourses && (
          <>
            <ProcessSpinner
              header={processSpinnerMessage}
              open={Boolean(processSpinnerMessage)}
            />
            <Typography
              color="primary"
              variant="h1"
              className="flex justify-content-space-between"
            >
              {_t("Courses Report")}
              <div className="right-side">
                <AsyncButton
                  variant="contained"
                  // className="add-button bg-orange color-white"
                  color="primary"
                  onClick={() => this.fetchReport()}
                  icon={<SaveAlt />}
                >
                  {_t("export To Excel")}
                </AsyncButton>
                <Button
                  variant="contained"
                  // className="add-button bg-orange color-white"
                  color="primary"
                  onClick={(e) => this.setState({ onfilter: e.target })}
                >
                  {<FilterList />}
                  {_t("filter")}
                </Button>
              </div>
            </Typography>
            {Array.isArray(studentCourses) &&
              studentCourses.map((course) => {
                const { course_code, title, experiments } = course;
                return (
                  <div className="course-report-item ">
                    <span>course</span>
                    <Typography variant="h2" color="primary">
                      {course_code} {title}
                    </Typography>
                    <div className="course-item-experiments bubble">
                      {Array.isArray(experiments) &&
                        experiments.map((experiment) => {
                          const { title, chapters } = experiment;
                          return (
                            <div className="experiment">
                              <span>{_t("Experiment")}</span>
                              <Typography variant="h3" color="primary">
                                {title}
                              </Typography>
                              {/*-----------------------------------------------------------------------*/}
                              {Array.isArray(chapters) &&
                                chapters
                                  // filter by display_chapters array
                                  .filter((c) =>
                                    display_chapters.includes(c.chapter_id)
                                  )
                                  .map((c) => (
                                    <ReportCourseChapter {...c} _t={_t} />
                                  ))}
                              {/*-----------------------------------------------------------------------*/}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
          </>
        )}
        {onfilter && (
          <FilterForm
            className="filter-form report-courses"
            anchorEl={onfilter}
            fields={fields}
            onCancel={() => this.setState({ onfilter: null })}
            onUpdate={(filter) => {
              this.filterAll(filter);
              this.setState({ onfilter: null });
            }}
          />
        )}
      </div>
    );
  }
}

export default ReportCourses;
