import React from 'react'
import {Select, MenuItem} from '@material-ui/core';
import { FilterList as Filter } from '@material-ui/icons';

/**
 * Standard filter select element for top row in tables
 */
class FilterSelect extends React.Component{

    render(){
        const {
            value,
            items = [], 
            ItemRenderer=({value, title}) =>  <MenuItem value={value}>{title}</MenuItem> ,
            IconComponent = Filter
            , ...other
        } = this.props;

        return(
        <div className='flex align-self-stretch'>
          <Select
            value={value}
            IconComponent={IconComponent}
            {...other}
            
          >
            {
            Array.isArray(items) &&
            //  items.map( i => <ItemRenderer {...i} />
             items.map( i => <MenuItem value={i.value}>{i.title}</MenuItem> 
             )
             }

          </Select>
        </div>

        )
    }
}

export default FilterSelect