import React from "react";
import {
  Typography,
  Grid,
  Divider,
  Button,
  LinearProgress,
  Container,
} from "@material-ui/core";
import {ExpandMore as ExpandMoreIcon, Home} from "@material-ui/icons";
import StudentItem from "./items/StudentItem";
import UserService from "../../../services/UserService";
import { countUnique, separateArray } from "../../../utils/functions";
import Spinner from "../../template/Spinner";
import { GridOverlay } from "@material-ui/data-grid";

import { Link } from "react-router-dom";
import "./style.scss";
import ProgramService from "../../../services/ProgramService";
import OrganizationService from "../../../services/OrganizationService";
import AutoBreadcrumbs from '../../template/CustomBreadcrumbs';
import LocalizedDataGrid from "../../template/localized-Data-Grid";

class Reports extends React.Component {
  state = {
    students: [],
    statuses: {},
    loading: false,
    error: null,
    rows: [],
  };

  componentDidMount() {
    this.setState({ loading: true });
    const usersLoaded = this.fetchUsers();
    const programsLoaded = this.fetchPrograms();
    if (usersLoaded && programsLoaded) {
      this.setState({ error: null, loading: false });
    }
  }

  async fetchUsers() {
    const users = await UserService.getUsers();
    if (!users) {
      this.setState({ error: UserService.error });
      return;
    }
    console.log("users in REPORTS", users);
    const students = users.filter((u) => u.credentials && u.credentials.includes("student"));
    const userStatuses = this.gatherStatus(students);

    const statusList = (await OrganizationService.getStatusList()) || []; //this.gatherStatus(students);

    const statuses = statusList
      ? statusList.reduce(
          (res, status) => [
            ...res,
            { ...status, count: userStatuses[status.status_id] || 0 },
          ],
          []
        )
      : statusList.map((s) => ({ ...s, count: 0 }));

    this.setState({ students, statuses });
    return true;
  }

  async fetchPrograms() {
    let programs = await ProgramService.getPrograms();
    if (!programs) {
      this.setState({ error: ProgramService.error });
      return;
    }

    console.log("programs in REPORTS", programs);
    this.setState({ programs });
    this.populateRows(programs);
    return true;
  }

  populateRows(programs) {
    let rows = [];
    programs.forEach((p) => {
      let numOfStudents = 0;
      let active = 0;
      let suspended = 0;
      let dormant = 0;
      if (p.students.length !== 0) {
        p.students.forEach((element) => {
          numOfStudents += element.total_students;
          if (element.status === "active") active = element.total_students;
          if (element.status === "suspended")
            suspended = element.total_students;
          if (element.status === "archived") dormant = element.total_students;
        });
      }

      const item = {
        id: p.program_id,
        program_name: p.title,
        num_of_courses: p.course_ids ? p.course_ids.length : 0,
        num_of_students: numOfStudents,
        active,
        suspended,
        dormant,
      };
      rows.push(item);
    });
    this.setState({ rows });
  }

  loadingOverlay() {
    return (
      <GridOverlay>
        <div style={{ position: "absolute", top: 0, width: "100%" }}>
          <LinearProgress />
        </div>
      </GridOverlay>
    );
  }

  /**
   * checks all available statuses from organization users and returns them in an array
   * @param {Array} users
   * @returns {Array}
   */
  gatherStatus(users) {
    const array = users.reduce(function (arr, val) {
      arr.push(val.status.status_id);
      return arr;
    }, []);
    // convert ["active", "active", "dormant"] to {active: 2, dormant: 1}
    return countUnique(array);
  }

  render() {
    const { students, statuses, programs, loading, error, rows } = this.state;

    const columns = [
      /* { field: 'id', headerName: 'ID', flex: 0.5 }, */
      { field: "program_name", headerName: "Name", flex: 1 },
      {
        field: "num_of_courses",
        headerName: "# of Courses",
        flex: 1,
      },
      { field: "num_of_students", headerName: "# of Students", flex: 1 },
      { field: "active", headerName: "Active", flex: 1 },
      { field: "suspended", headerName: "Suspended", flex: 1 },
      { field: "dormant", headerName: "Dormant", flex: 1 },
    ];

    return <Container className="reports">
      <AutoBreadcrumbs 
      items={[
        { icon: <Home/> , to: "/" },
        { text: "Reports" }
      ]}
      />
      <Typography variant="h1" color="primary">Reports</Typography>
      {Object.keys(statuses).length === 0 ? (
        <Spinner />
      ) : (
        <Grid container>
          {!students ? (
            <Spinner />
          ) : (
            <Grid item xs={12} className="bubble student-bubble">
              <Typography variant="h3">Students </Typography>
              <Grid container className="students-info">
                {/* allways show overall amount of students  */}
                <StudentItem title="Overall" value={students.length} />
                <Divider orientation="vertical" flexItem />
                {statuses
                  ? separateArray(
                      // this effectively separates array elements with second parameter
                      statuses.map((status) => (
                        <StudentItem
                          title={status.status_title}
                          value={status.count}
                        />
                      )),
                      <Divider orientation="vertical" flexItem />
                    )
                  : "Error reading statuses"}

                {/* only show status if it exist in one of the students */}
                {
                  // Object.entries(statuses)
                  //   ? Object.entries(statuses).map((item, ind) => {
                  //       // amount of statuses to show
                  //       const last = Object.keys(statuses).length;
                  //       return (
                  //         <>
                  //           <StudentItem title={item[0]} value={item[1]} />
                  //           {/* hide divider after last child */}
                  //           {ind + 1 !== last ? (
                  //             <Divider orientation='vertical' flexItem />
                  //           ) : null}
                  //         </>
                  //       );
                  //     })
                  //   : null
                }
              </Grid>

              <div className="button-container">
                <Button
                  to={"/students"}
                  component={Link}
                  variant="contained"
                  color="secondary"
                  endIcon={<ExpandMoreIcon />}
                >
                  Manage
                </Button>
                <Button
                  to={"/reports/students"}
                  component={Link}
                  variant="contained"
                  color="primary"
                  endIcon={<ExpandMoreIcon />}
                >
                  View
                </Button>
              </div>
            </Grid>
          )}
          {!programs ? (
            <Spinner />
          ) : (
            <Grid item xs={12} className="bubble program-bubble">
              <Typography variant="h3">Programs</Typography>
              <Grid container className="programs-list">
                {rows.length > 0 ? (
                  <LocalizedDataGrid
                    components={{
                      LoadingOverlay: this.loadingOverlay,
                    }}
                    rows={rows}
                    columns={columns}
                    density="compact"
                    hideFooter={
                      rows.length > 4 && rows.length < 10 ? true : false
                    }
                    pageSize={10}
                    loading={loading}
                  />
                ) : (
                  <Spinner />
                )}
              </Grid>
              <div className="button-container">
                <Button
                  to={"/programs"}
                  component={Link}
                  variant="contained"
                  color="secondary"                  
                  endIcon={<ExpandMoreIcon />}
                >
                  Manage
                </Button>
                <Button
                  to={"/reports/programs"}
                  component={Link}
                  variant="contained"
                  color="primary"                  
                  endIcon={<ExpandMoreIcon />}
                >
                  View
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      )}
    </Container>;
  }
}

export default Reports;
