import React from "react";
import {
  // Container,
  Typography,
} from "@material-ui/core";
// import CustomCardList from "@bit/ses-education.ses-courses-shared-components.template.custom-card-list";
import WithTranslations from "../../WithTranslations";
import { Link } from "react-router-dom";
import { fixUrl } from "../../../utils/functions";

const {REACT_APP_MEDIA_URL} = process.env;

class Program extends React.Component {
  componentDidMount() {
    this.props.fetchTranslations(["Course list"]);
  }
  render() {
    const { program_id, title, description, courses, students, _t } =
      this.props;
    // const coursesParsed = Array.isArray(courses)
    //   ? courses.map((c) => ({
    //       ...c,
    //       description: c,
    //     }))
    //   : [];
    console.debug(courses);
    return (
      <div className="single-program">
        <Typography variant="h1" color="primary">
          {title}
        </Typography>
        <Typography variant="body1">{description}</Typography>
        {courses && courses[0] && (
          <>
            <Typography variant="h2">{_t("Course list")}</Typography>

            <ul className="program-course-list">
              {courses.map((c) => (
                <li>
                  <Link to={`/programs/${program_id}/${c.course_id}`} class="flex flex-row">
                    <div className="custom-card-image-container" 
                    style={{backgroundImage:
                      c.image ?
                      `url("${REACT_APP_MEDIA_URL}${fixUrl(c.image)}")` :
                      '' //TODO: replace with default image
                    }}
                    // style={{backgroundImage:`url("${fixUrl(c.images)}")`}}
                    >
                    </div>
                    <div> 
                      <div>{c.title}</div>
                      <div>{c.course_code}</div>
                      <div className="small">{c.description && c.description}</div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    );
  }
}

export default WithTranslations(Program);
