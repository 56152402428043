import React from "react";
import {
  Button,
  Select,
  Typography,
  Modal,
  ButtonGroup,
} from "@material-ui/core";
import { DeleteSweep, Delete, Publish } from "@material-ui/icons";
import { connect } from "react-redux";
import { showMessage } from "../../../redux/notificationActions";
// import SingleStudentForm from "./singleStudentForm";
import "./modal-add-students-styles.scss";
// import UserService from "../../../services/UserService";
import ProgramService from "../../../services/ProgramService";
import { debounce } from "../../../utils/functions";
import { validEmail } from "../../../utils/regEx";
import OrganizationService from "../../../services/OrganizationService";
import WithTranslations from "../../WithTranslations";
import Spinner from "../Spinner";
import ReactFileReader from "react-file-reader";
import ConfirmDialog from "../ConfirmDialog";
import SingleStudentField from "./SingleStudentField";
import HelpButton from "../HelpButton";
import Passwords from "./passwords";
import ProcessSpinner from "../ProcessSpinner";
import LocalizedDataGrid from "../localized-Data-Grid";

const user_template = {
  citizen_id: "",
  name: "",
  last_name: "",
  email: "",
  validated: false,
  errors: {},
  new: true,
};
const test_user = {
  citizen_id: "1",
  name: "tt",
  last_name: "tt",
  email: "tt",
  validated: false,
  errors: {},
  new: true,
};
class ModalAddStudents extends React.Component {
  state = {
    users: [{ ...user_template }],
    // users: [],
    programs: null,
    selectedProgram: 0,
    selectedGroup: 0,
    allValid: true,
    groups: null,
    selectedUsers: null,
    confirmationText: null,
    onConfirm: null,
    createdPasswords: null,
    processTitle: null,
  };

  debouncedUpdateUser = null;

  closeModal() {
    this.setState({
      users: [],
      programs: null,
      groups: null,
      selectedProgram: null,
      selectedUsers: null,
      confirmationText: null,
      onConfirm: null,
      processTitle: null,
    });

    this.props.onModalClose();
  }

  handleProgramSelection = (event) => {
    this.setState({ selectedProgram: event.target.value });
  };
  handleGroupSelection = (event) => {
    //console.log("handleGroupSelection", event.target.value);
    this.setState({ selectedGroup: event.target.value });
  };

  async componentDidMount() {
    this.debouncedUpdateUser = debounce(this.validateAndUpdateUser.bind(this));
    this.props.fetchTranslations([
      "Select program",
      "Import",
      "Close",
      "Cancel",
      "Yes",
      "selectedProgramAssignment",
      "Fetching programs from server failed.",
      `Fetching groups from server failed.`,
      "Field must be at least 1 character long",
      "Email required",
      "Email already exist",
      "Invalid email address",
      "Field must be maximum 100 charachters long",
      "citizen id already exist",
      "Wrong result when creating users passwords, please try again manually",
      "Unknown error",
      "Please correct all errors",
      "Error reading users list",
      "Please, fill in at least one user data",
      "Unknown error while adding students",
      "users were successfully registered.",
      "users were not registered due to errors",
      `Unknown error assigning students`,
      "Id",
      "First Name",
      "Last Name",
      "Email",
      "Add Students",
      "Are you sure you want to remove all new student?",
      "Clear form",
      "Are you sure you want to remove selected new student?",
      "Remove selected",
      "Program",
      "None",
      "Group",
      "Select student group",
      "Register",
    ]);
    await this.fetchPrograms();
    await this.fetchGroups();
  }

  async componentDidUpdate(prevProps){
    if(prevProps._language != this.props._language) await this.fetchPrograms();
  }
  async fetchPrograms() {
    const { _t } = this.props;
    const programs = await ProgramService.getPrograms();
    if (programs) {
      this.setState({ programs });
    } else {
      this.props.onShowMessage(
        OrganizationService.error || _t("Fetching programs from server failed.")
      );
    }
  }
  async fetchGroups() {
    const { _t } = this.props;
    const groups = await OrganizationService.getStudentGroups();
    if (groups) {
      console.log("groups", groups);
      this.setState({ groups });
    } else {
      this.props.onShowMessage(_t(`Fetching groups from server failed.`), "error");
    }
  }
  //read csv file and add the users to this.state.users
  csvToList = (csv) => {
    //flag for if all users are valid
    var valid = true;
    var reader = new FileReader();
    //call this function when file is uploads
    reader.onload = (e) => {
      //csv users list
      let users = [];
      //console.log("csv",users);
      // Use reader.result
      //split result by line and run for loop on each line
      reader.result.split("\n").forEach((item, ind) => {
        //check if line is't the header or empty
        if (
          ind > 0 &&
          (item.split(/[,\t;|]/gm).length > 1 ||
            item.replace(/[\r\t\n\s,\t;|]/gm, "").length > 0)
        ) {
          //remove all \r from line windows add by the end of line \r\n insted of \n
          item = item.replace("\r", "");
          //console.log("csv1",item,users);
          //get all user fields
          var user = item.split(/[,\t;|]/gm);
          //creating new user from user fields without validation
          var new_user = {
            citizen_id: user[0] || "",
            name: user[1] || "",
            last_name: user[2] || "",
            email: user[3] || "",
            validated: false,
            errors: {},
            new: true,
          };
          //get new_user with validation
          var validateNewUser = this.validateUser(new_user);
          //update all users validation flag
          valid = valid && validateNewUser.validated;
          //add new user to users list
          users = [...users, validateNewUser];
          // users.push({
          //   citizen_id: user[0],
          //   name: user[1],
          //   last_name: user[2],
          //   email: user[3],
          //   validated: false,
          //   errors: {},
          //   new: true,
          // });
          //console.log("csv",users);
        }
      });
      //check if all users are valid then add a empty user to users list
      if (valid) {
        users = [...users, user_template];
      }
      //console.log("csv after forEach",users);
      //update state with new users list
      this.setState({ users, allValid: valid });
    };
    //teling the reader to read the csv file as text
    reader.readAsText(csv[0]);
  };

  editUserInfo(index, user, fieldName) {
    // debounced validation
    this.debouncedUpdateUser(index, user, fieldName);
  }
  //validate user and return new user with validation errors
  validateUser = (user) => {
    const { _t } = this.props;
    let validated;
    let errors = {};
    let { citizen_id, name, last_name, email } = user;
    // TODO: get min name length from organization config
    if (typeof name !== "string" || name.length === 0) {
      errors["name"] = _t("Field must be at least 1 character long");
    }
    // TODO: get min last name length from organization config
    if (typeof last_name !== "string" || last_name.length === 0) {
      errors["last_name"] = _t("Field must be at least 1 character long");
    }
    const emailIsValid = this.validateEmail(email, -1);

    if (emailIsValid.error) {
      errors["email"] = emailIsValid.error;
    }
    const idValid = this.validateId(citizen_id, -1);

    if (idValid.error) {
      errors["citizen_id"] = idValid.error;
    }
    // Set validated value. validated is true only if there is NO errors
    validated = Object.keys(errors).length === 0;
    user.validated = validated;
    user.errors = errors;
    return user;
  };
  validateAndUpdateUser = (index, user) => {
    const { _t } = this.props;
    let { users } = this.state;
    let validated;
    let errors = {};
    const server_errors = users[index].server_errors;

    let { citizen_id, name, last_name, email } = user;

    // Do all validations
    if (typeof name !== "string" || name.length === 0) {
      errors["name"] = _t("Field must be at least 1 character long");
    }
    if (typeof last_name !== "string" || last_name.length === 0) {
      errors["last_name"] = _t("Field must be at least 1 character long");
    }
    if (server_errors) {
      Object.entries(user).map(([key, value]) => {
        if (server_errors[key] && server_errors[key].value === value) {
          errors[key] = server_errors[key].error;
        }
      });
    }
    // console.debug("validateAndUpdateUser validating email. users are:\n", users);
    const emailIsValid = this.validateEmail(email, index);

    if (emailIsValid.error) {
      errors["email"] = emailIsValid.error;
    }

    const idIsValid = this.validateId(citizen_id, index);

    if (idIsValid.error) {
      errors["citizen_id"] = idIsValid.error;
    }

    // Set validated value. validated is true only if there is NO errors
    validated = Object.keys(errors).length === 0;

    // update user with validation data
    users[index] = {
      ...user,
      validated,
      errors,
      new: false, // flag that the user was updated
    };

    // check for invalidated users in array. Init with value of the checked user
    let allValid = validated;
    console.debug("user validated:", validated);

    console.debug("allValid:", validated);
    // cycle while i < length and all users are valid. If not valid - exit immediately
    // We start with currently checked user's 'validated' value,
    // so if it's false - the cycle won't run at all
    for (let i = 0; i < users.length && allValid === true; i++) {
      allValid =
        allValid &&
        // this check excludes users with new===true,
        // so the empty "new" user isn't considered invalidated and does not get removed
        users[i].new
          ? true
          : users[i].validated;
    }

    // if there are non-valid users and last user is new (the "new" form), remove the last user
    // This will "hide" new user form when there are errors in previous users.
    if (!allValid && users.length > 0 && users[users.length - 1].new === true) {
      users.pop();
    }

    // if all users are valid and last user isn't "new", add "new" empty user to users array
    if (allValid && users.length > 0 && users[users.length - 1].new !== true) {
      users = [...users, { ...user_template }];
    }

    // update users array and allValid flag in state
    this.setState({ users, allValid });
  };

  /**
   * checks if new email already in the system
   * @param {*} email
   * @param {*} userIndex - index of user the email belongs to (we have to exclude the user from comparison)
   * @returns
   */
  validateEmail(email, userIndex) {
    const { _t } = this.props;
    const { users } = this.state;
    const { existingUsers } = this.props;
    // emails of all new users, except for the user the mail belongs to
    const newEmails = users.reduce(
      (res, user, ind) => (ind !== userIndex ? [...res, user.email] : res),
      []
    );

    // emails of users that are already in the system
    const existingEmails =
      existingUsers.length > 0 ? existingUsers.map((u) => u.email) : [];

    if (email.length === 0) {
      return { error: _t("Email required") };
    }
    if (newEmails.includes(email) || existingEmails.includes(email)) {
      return { error: _t("Email already exist") };
    }
    if (!validEmail.test(email)) {
      return { error: _t("Invalid email address") };
    }
    return true;
  }

  validateId(Id, userIndex) {
    const { users } = this.state;
    const { existingUsers, _t } = this.props;

    // ID of all new users, except for the user the ID belongs to
    const newIds = users.reduce(
      (res, user, ind) => (ind !== userIndex ? [...res, user.citizen_id] : res),
      []
    );

    // ID of users that are already in the system
    const existingIds =
      existingUsers.length > 0 ? existingUsers.map((u) => u.citizen_id) : [];

    if (Id.length > 100) {
      return { error: _t("Field must be maximum 100 charachters long") };
    }
    if (Id && (newIds.includes(Id) || existingIds.includes(Id))) {
      return { error: _t("citizen id already exist") };
    }

    return true;
  }

  /**
   * Create passwords for selected users and show them within a modal window
   * @param {*} userIds
   */
  async createUsersPasswords(userIds) {
    console.debug("createUsersPasswords called for users", userIds);
    const { onShowMessage, _t } = this.props;
    try {
      const createdPasswords = await OrganizationService.createUsersPasswords(
        userIds
      );

      console.debug("createdPasswords: ", createdPasswords);

      if (!Array.isArray(createdPasswords) || createdPasswords.length === 0) {
        onShowMessage(
          _t("Wrong result when creating users passwords, please try again manually"),
          "error"
        );
      }

      // show passwords in a modal
      this.setState({ createdPasswords });
    } catch (e) {
      console.debug("createUsersPasswords error:\n", e);

      onShowMessage(e.message || _t("Unknown error"), "error");
    }
  }

  async submitHandler() {
    const { onChanges, onShowMessage, groupId, onAssign, _t } = this.props;
    const { users, selectedProgram, selectedGroup, allValid } = this.state;

    // if not all valid - can't send
    if (!allValid) {
      onShowMessage(_t("Please correct all errors"), "error");
      return false;
    }

    // handle possible wrong data
    if (!Array.isArray(users)) {
      onShowMessage(_t("Error reading users list"), "error");
      return false;
    }

    // filter the empty user entry & non-validated users
    const validatedUsers = users
      .filter((u) => u.validated === true)
      .map((u) => {
        const { id, ...other } = u;
        //console.log(other)
        return other;
      });
    //console.log(validatedUsers)
    // handle case when no users were sent
    if (validatedUsers.length === 0) {
      onShowMessage(_t("Please, fill in at least one user data"), "error");
      return false;
    }

    // console.debug("submitHandler users:", users )
    let data = {
      users: validatedUsers,
    };

    // add programs, if any
    const programs = [selectedProgram].filter((p) => p && p > 0);
    console.debug("programs", programs);
    if (programs.length > 0) data = { ...data, programs };

    // start process
    this.setProcess("Creating users...");

    const result = await OrganizationService.createAndAssign(data);
    console.log("ModalAddStudent - submit result: ", result);
    if (!result || !Array.isArray(result)) {
      this.props.onShowMessage(
        OrganizationService.error || _t("Unknown error while adding students"),
        "error"
      );

      this.setProcess(null);
      return;
    }
    // gather validated users with server side errors
    let registeredIds = [];
    const errorUsers = result.reduce(
      (res, u) => {
        // do not include successfully registered users, increment their count
        if (!u.error) {
          // registeredCount++;
          // collect registered users' ids
          registeredIds = [...registeredIds, u.user_id];
          return res;
        }
        const [field] = Object.keys(u);
        // get index of user with error within validatedUser array
        const userIndex = validatedUsers.findIndex(
          (uu) => uu[field] === u[field]
        );
        if (userIndex === -1) return res;

        let userData = validatedUsers[userIndex];
        userData.validated = false;
        // add "error" field to user.errors
        return [
          ...res,
          {
            ...userData,
            server_errors: { [field]: { value: u[field], error: u.error } },
            errors: { [field]: u.error },
          },
        ];
      },

      []
    );

    // get organization type from org config
    const { type } = this.props?.organization?.config || {};
    console.debug("Organization type:", type);

    let registeredCount = registeredIds.length;

    if (registeredCount > 0) {
      onShowMessage(
        `${registeredCount} ${_t("users were successfully registered.")}`,
        "success"
      );

      // TODO: if standalone, update passwords and show them in a new modal window

      if (type === "standalone") {
        console.debug("call createUsersPasswords with", registeredCount);
        this.setProcess("Generating passwords...");
        await this.createUsersPasswords(registeredIds);
        this.setProcess(null);
      }

      if (groupId) {
        const userslist = result.map((item) => item.user_id);
        //get onAssign from groups view only
        onAssign(userslist, groupId);
        console.debug("onAssign finished");
      } else {
        if (selectedGroup !== 0) {
          console.log("enter selectedGroup if");
          const userslist = result.map((item) => item.user_id);
          //go here from /student. /student dont have a function for assigning students so created one localy
          this.onAssignStudents(userslist, selectedGroup);
        }
        onChanges();
        console.debug("onChanges finished");
      }

      console.debug("ModalAddStudents closed");
    }
    // update users list with list of users with errors (if no errors - the list will be empty)
    this.setState({ users: errorUsers, allValid: false, processTitle: null });

    if (errorUsers.length > 0) {
      onShowMessage(
        `${errorUsers.length} ${_t("users were not registered due to errors")}`,
        "error"
      );
    }

    // if organization is standalone and there were users registered, we need the modal to stay open...
    // we also need it to stay open if there were users with errors
    if (
      !(type === "standalone" && registeredCount > 0) &&
      errorUsers.length === 0
    )
      this.closeModal();
  }

  /**
   * Showinf process spinner
   * @param {*} processTitle
   */
  setProcess = (processTitle) => {
    this.setState({ processTitle });
  };

  onAssignStudents = async (students, group_id) => {
    const { onShowMessage, _t } = this.props;
    if (!(await OrganizationService["assignStudents"](group_id, students))) {
      onShowMessage(
        OrganizationService.error || _t(`Unknown error assigning students`),
        "error"
      );
      return false;
    }
    return true;
  };
  onSelect = (users) => {
    this.setState({ selectedUsers: users });
  };
  addId = (users) => {
    return users.map((user, ind) => ({ ...user, id: `${ind}` }));
  };
  onRemove = (RemoveUsers) => {
    if (!RemoveUsers) {
      this.setState({ users: [user_template], selectedUsers: null });
    } else {
      let users = this.state.users;
      RemoveUsers = RemoveUsers.map((u) => parseInt(u));
      users = users.filter((user, ind) => {
        return !RemoveUsers.includes(ind);
      });
      if (users.length === 0) {
        users = [user_template];
      }
      users.map((user, ind) => this.editUserInfo(ind, user));
      this.setState({ users, selectedUsers: null });
    }
  };
  onChange = (params, value) => {
    const { users } = this.state;
    const ind = parseInt(params.id);
    this.editUserInfo(ind, { ...users[ind], [params.field]: value });
  };
  render() {
    const {
      users,
      programs,
      groups,
      selectedProgram,
      selectedGroup,
      selectedUsers,
      allValid,
      confirmationText,
      onConfirm,
      createdPasswords,
      processTitle,
    } = this.state;
    const { onModalOpen, groupId } = this.props;
    const { translationError, translationsLoaded, _t } = this.props;
    if (!translationError && !translationsLoaded) return <Spinner />;
    console.log("render this.state:", users, this.state);
    console.debug("AddstudentsModal props", this.props);
    //console.log("Add Id To Users",this.addId(users));
    const columns = [
      {
        field: "citizen_id",
        flex: 1,
        headerName: _t("Id"),
        renderCell: (params) => {
          //console.log("render this.params",params);
          return (
            <SingleStudentField
              {...{ params }}
              label={_t("Id")}
              onChange={this.onChange}
            />
          );
        },
      },
      {
        field: "name",
        flex: 1,
        headerName: _t("First Name"),
        renderCell: (params) => {
          return (
            <SingleStudentField
              {...{ params }}
              label={_t("First Name")}
              onChange={this.onChange}
            />
          );
        },
      },
      {
        field: "last_name",
        flex: 1,
        headerName: _t("Last Name"),
        renderCell: (params) => {
          return (
            <SingleStudentField
              {...{ params }}
              label={_t("Last Name")}
              onChange={this.onChange}
            />
          );
        },
      },
      {
        field: "email",
        flex: 1,
        headerName: _t("Email"),
        renderCell: (params) => {
          return (
            <SingleStudentField
              {...{ params }}
              label={_t("Email")}
              onChange={this.onChange}
            />
          );
        },
      },
    ];

    // console.debug("Group rows", rows)
    const onCloseConfirmDialog = () =>
      this.setState({ onConfirm: null, confirmationText: null });

    return (
      <>
        <Modal
          className="add-students-modal"
          open={onModalOpen}
          onClose={() => this.closeModal()}
        >
          <div className="add-students-root-container">
            <div className="header-container">
              <Typography className="header">{_t("Add Students")}</Typography>
              <div className="buttons-container">
                <ButtonGroup style={{ marginRight: "1em" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    startIcon={<DeleteSweep />}
                    onClick={() =>
                      this.setState({
                        confirmationText:
                          _t("Are you sure you want to remove all new student?"),
                        onConfirm: async () => this.onRemove(),
                      })
                    }
                  >
                    {_t("Clear form")}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    startIcon={<Delete />}
                    onClick={() =>
                      this.setState({
                        confirmationText:
                          _t("Are you sure you want to remove selected new student?"),
                        onConfirm: async () => this.onRemove(selectedUsers),
                      })
                    }
                    disabled={!selectedUsers || selectedUsers.length === 0}
                  >
                    {_t("Remove selected")}
                  </Button>
                </ButtonGroup>
                <ReactFileReader
                  handleFiles={this.csvToList}
                  fileTypes={".csv"}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    // size="small"
                    startIcon={<Publish />}
                  >
                    {_t("Import")}
                  </Button>
                </ReactFileReader>
                <HelpButton translation_id={"studentImportHelp"} />
              </div>
            </div>

            <div className="students-form flex grow-1 column justify-content-stretch">
              <div className="add-student-rows flex column grow-1">
                <LocalizedDataGrid
                  {...{ rows: this.addId(users) || [user_template], columns }}
                  autoHeight
                  pageSize={5}
                  rowHeight={80}
                  onSelectionModelChange={(model) => {
                    console.log(model);
                    this.onSelect(model);
                  }}
                  checkboxSelection
                  disableSelectionOnClick
                />
              </div>

              <div className="assign-program-container">
                <Typography className="title">
                  {_t("Select program")}
                </Typography>
                <Select
                  className="program-select"
                  // native
                  value={selectedProgram}
                  onChange={(event) => this.handleProgramSelection(event)}
                  label={_t("Program")}
                >
                  <option value={0}>{_t("None")}</option>
                  {programs
                    ? programs.map((p) => (
                        <option value={p.program_id}>{p.title}</option>
                      ))
                    : null}
                </Select>
                <Typography className="description">
                  {_t("selectedProgramAssignment")}
                </Typography>
                {!groupId && (
                  <>
                    <Select
                      className="program-select"
                      // native
                      value={selectedGroup}
                      onChange={(event) => this.handleGroupSelection(event)}
                      label={_t("Group")}
                    >
                      <option value={0}>{_t("None")}</option>
                      {groups
                        ? groups.map((p) => (
                            <option value={p.student_group_id}>
                              {p.title}
                            </option>
                          ))
                        : null}
                    </Select>
                    <Typography className="description">
                      {_t("Select student group")}
                    </Typography>
                  </>
                )}
              </div>

              <div className="buttons-container">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.closeModal()}
                >
                  {_t("Close")}
                </Button>
                <Button
                  onClick={() => this.submitHandler()}
                  variant="contained"
                  color="primary"
                  style={{
                    marginLeft: "10px",
                  }}
                  // disable button if not all users are valid or only one user in array
                  disabled={!allValid || users.length === 1}
                >
                  {_t("Register")}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        {
          // display for passwords in standalone version
        }
        <Passwords
          passwords={createdPasswords}
          open={Boolean(createdPasswords)}
          // open={true}
          onClose={() => this.setState({ createdPasswords: null })}
        />

        <ConfirmDialog
          open={typeof onConfirm === "function"}
          onConfirm={() => {
            onConfirm();
            onCloseConfirmDialog();
          }}
          cancelText = {_t("Cancel")}
          confirmText = {_t("Yes")}
          prompt={confirmationText}
          // clear on close
          onClose={onCloseConfirmDialog}
        />
        {/**
         * This is the modal window that shows process, it should be on top of everything and it won't close until the process is done
         */}
        <ProcessSpinner open={processTitle} header={processTitle} />
      </>
    );
  }
}

const stateToProps = (state) => ({
  organization: state.auth?.user?.organizations[0],
});

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};
export default WithTranslations(
  connect(stateToProps, mapDispatchToProps)(WithTranslations(ModalAddStudents))
);
