import { notificationsActionTypes} from '@ses-education/courses-components'

console.debug("notificationsActionType", notificationsActionTypes );
const ActionTypes = {
  ...notificationsActionTypes,

  SHOW_CONTACT: 'SHOW_CONTACT',
  HIDE_CONTACT: 'HIDE_CONTACT',
  SEND_CONTACT: 'SEND_MESSAGE',
  RESET_CONTACT: 'RESET_MESSAGE',

  SHOW_MODAL: 'SHOW_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',

  TOGGLE_DRAWER: 'TOGGLE_DRAWER',

  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  UPDATE_USER: 'UPDATE_USER',
  SET_USAGE_TIMESTAMP: 'USETIME',
  RENEW_TOKEN: 'RENEWTOKEN',

  SET_LANG: "SET_LANG",
};

export default ActionTypes;
