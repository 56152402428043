import { IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React from "react";
import ConfirmDialog from "../ConfirmDialog";
import ProcessSpinner from "../ProcessSpinner";
import "./image-selector.scss";

class ImageSelector extends React.Component {
  state = {
    showConfirmDelete: false,
    processTitle: null,
  };
  confirmDeletion = (processTitle) => {
    this.setState({ showConfirmDelete: true, processTitle });
  };

  render() {
    const {
      image,
      defaultImage,
      onDelete,
      onSelect,
      confirmationText = "Delete image?",
      closeButtonClass = "image-selector-close",
      deleteText = "Deleting program image...",
    } = this.props;
    const { showConfirmDelete, processTitle } = this.state;
    console.debug("ImageSelector", image, defaultImage);
    return (
      <label className="image-selector" htmlFor="image-file">
        {image && (
          <IconButton
            onClick={() => this.confirmDeletion(deleteText)}
            className={closeButtonClass}
          >
            <Delete />
          </IconButton>
        )}
        <input
          type="file"
          id="image-file"
          name="image"
          accept="image/*"
          style={{ display: "none" }}
          onChange={onSelect}
        />
        <div
          className="display"
          style={{
            backgroundImage: `url(${image || defaultImage})`,
          }}
        ></div>
        <ConfirmDialog
          open={showConfirmDelete}
          prompt={confirmationText}
          onConfirm={async () => {
            await onDelete();
            this.setState({ showConfirmDelete: false, processTitle: null });
          }}
          onClose={() => this.setState({ showConfirmDelete: false, processTitle: null })}
        />
        <ProcessSpinner open={processTitle && !showConfirmDelete} header={processTitle} />
      </label>
    );
  }
}

export default ImageSelector;
