import React from 'react'
import "./style.scss";
import { Email, Person, AssignmentInd } from "@material-ui/icons";

class UserDescriptionHeader extends React.Component{

    render(){
        const {name, last_name, email, citizen_id, full_name = `${name} ${last_name}`} = this.props;
        return(
            <div className="personal-data">
                <span><Person/>{full_name}</span>
                <span><Email/>{email}</span>
                {citizen_id && <span><AssignmentInd/>{citizen_id}</span>}
            </div>
        );
    }
}

export default UserDescriptionHeader