import React from "react";
// import Login from "./login";
import { connect } from "react-redux";
import Actions from "../../redux/actions";
import ActionTypes from "../../redux/actionTypes";

let usageDefer = Date.now();
const deferUsageDelay = process.env.REACT_APP_USAGE_DEFER_TIMER || 1000; // ms

/**
 * HOC to incapsulate authorization
 */
const AuthComponent = (
  Authorized,
  NonAuthorized = () => <>Non-authorized component</>
) => {
  const mapStateToProps = (state) => {
    const { user, tokenTimestamp } = state.auth;
    return { user,tokenTimestamp };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
        setUsageTime: () => dispatch({ type: ActionTypes.SET_USAGE_TIMESTAMP }),
      // onClose: () => dispatch({ type: A.HIDE_CONTACT }),
      // sent : () => dispatch({type: A.SEND_CONTACT} ),
      // error : message => dispatch({type: A.SHOW_MESSAGE, payload:{message, type: "error"}}),
      // resetMessage: () => dispatch( {type: A.RESET_CONTACT} )
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class extends React.Component {
      
        componentDidMount() {
        document.getElementById("root").addEventListener("click", () => {
          clearTimeout(usageDefer);
          usageDefer = setTimeout(
            () => this.props.setUsageTime(),
            deferUsageDelay
          );
        });

        Actions.onTokenUpdate(this.props.tokenTimestamp);
      }

      render() {
        const { user } = this.props;
        return user ? (
          <Authorized {...this.props} />
        ) : (
          <NonAuthorized {...this.props} />
        );
      }
    }
  );
};

export default AuthComponent;
