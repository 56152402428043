import { Button, Container, Typography, ButtonGroup } from "@material-ui/core";
import React from "react";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import PersonIcon from "@material-ui/icons/Person";
import DeleteIcon from "@material-ui/icons/Delete";
import AutoBreadcrumbs from "../../template/CustomBreadcrumbs";
import { Home } from "@material-ui/icons";
import TeacherForm from "./teacher-form";
import ConfirmDialog from "../../template/ConfirmDialog";
import { Link } from "react-router-dom";
import UserService from "../../../services/UserService";
import WithTranslations from "../../WithTranslations";
import LocalizedAdminTable from "../../template/localized-admin-table";
import OrganizationService from "../../../services/OrganizationService";
import Passwords from "../../template/ModalAddStudents/passwords";
import ProcessSpinner from "../../template/ProcessSpinner";



class TeachersList extends React.Component {
  state = {
    id: null,
    loading: false,
    delID: null,
    error: null,
    renderAddModal: false,
    user: null,
    createdPasswords: null,
    processTitle: null
  };

  componentWillMount() {
    this.props.fetchTranslations([
      "ID",
      "Name",
      "Email",
      "Status",
      "Actions",
      "View",
      "Delete",
      "Add teacher",
      "Teachers",
      "Search",
      "Wrong result when creating users passwords, please try again manually"
    ])
  }
  closeDialogHandler() {
    this.setState({ confirmDialog: false, delID: null });
  }

  async deleteUserHandler() {
    const { delID } = this.state;
    const {onShowMessage, fetchUsers } = this.props;

    const result = await UserService.deleteOrganizationUser(delID);
    if (result) {
      onShowMessage(`User with id ${delID} was deleted.`, "info");
      fetchUsers();
    } else {
      onShowMessage(
        `Error deleting user, please remove assigned programs before deletion.`,
        "error"
      );
    }
    this.closeDialogHandler();
  }

  closeAddModal() {
    this.setState({
      //   addModalOpen: false,
      renderAddModal: false,
    });
  }

  /**
   * Showinf process spinner
   * @param {*} processTitle
   */
  setProcess = (processTitle) => {
    this.setState({ processTitle });
  };

  onFormSubmit = async (data) => {
    // let { credentials } = data;

    // // check if it's an array
    // credentials = Array.isArray(credentials) ? credentials : [];

    // // check if contains teacher already, if not - add
    // credentials = credentials.includes("teacher")
    //   ? credentials
    //   : [...credentials, "teacher"];
    console.debug("onFormSubmit called with", data);
    // get organization type from org config
    const type = this.props?.currentUser?.organizations[0]?.config?.type;
    console.debug("props", this.props);
    console.debug("Organization type:", type);

    data = { ...data, credentials: "teacher" };
    const { onShowMessage, fetchUsers, _t } = this.props;
    console.debug("submitted:", data);

    const newTeacherId = await UserService.updateOrganizationUser(data);

    if (! newTeacherId ) {
      onShowMessage(UserService.error || _t("Unknown error"), "error");
      return false;
    }

    // not a standalone - we don't need anything else
    if( type !== "standalone"){
      // reload list
      fetchUsers();
  
      // clear and close the form
      return true;
    }

    // create password
    try{
      const createdPasswords = await OrganizationService.createUsersPasswords([newTeacherId]);

      if (!Array.isArray(createdPasswords) || createdPasswords.length === 0) {
        onShowMessage(
          _t("Wrong result when creating users passwords, please try again manually"),
          "error"
        );
      } else {
        this.setState({createdPasswords})
      }
  
      // reload list
      fetchUsers();
  
      // clear and close the form
      return true;
    
    }catch(e){
      onShowMessage(e.message || _t("Unknown error"),"error");
      // reload list
      fetchUsers();

      return false;
    }
    
  };

  render() {
    const {
      loading,
      //   rows,
      //   error,
      //   confirmDialog,
      fetchUsers,
      currentUser,
      _t,
    } = this.props;
    let {users} = this.props;
    users = users.map(u=>({...u, full_name: `${u.name} ${u.last_name}`}));
    const { delID, renderAddModal, createdPasswords, processTitle } = this.state;


    const columns = [
        {
          field: "citizen_id",
          headerName: _t("ID"),
          flex: 1,
          renderCell: (params) => params.value || "N/A",
        },
        { field: "full_name", headerName: _t("Name"), flex: 1.5,
          // renderCell: (params) => (
          //   <Link to={`/teachers/${params.id}`}>{params.value}</Link>
          // )
         },
        { field: "email", headerName: _t("Email"), flex: 1.5 },
        {
          field: "status",
          headerName: _t("Status"),
          flex: 1,
          renderCell: (params) => params.value.status_title,
        },
        //   {
        //     field: "progress",
        //     headerName: "Progress",
        //     flex: 1.5,
        //     renderCell: (params) => <div>{params.value}</div>,
        //   },
        {
          field: "buttons",
          headerName: _t("Actions"),
          flex: 2,
          renderCell: (params) => (
            <div>
              <ButtonGroup>
                <Button
                  component={Link}
                  to={`/teachers/${params.id}`}
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<PersonIcon />}
                >
                  {_t("View")}
                </Button>
                <Button
                  onClick={() =>
                    // this.setState({ confirmDialog: true, delID: params.id })
                    this.setState({ delID: params.id })
                  }
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<DeleteIcon />}
                >
                  {_t("Delete")}
                </Button>
              </ButtonGroup>
            </div>
          ),
        },
      ];

    console.debug("renderAddModal in render:", renderAddModal);
    const buttons = [
      <div className="buttons">
            <Button
              onClick={() => {
                this.setState(
                  {
                    //   addModalOpen: true,
                    renderAddModal: true,
                  },
                  () =>
                    console.debug("renderAddModal:", this.state.renderAddModal)
                );
              }}
              variant="contained"
              startIcon={<GroupAddIcon />}
              className="add-button"
            >
              {_t("Add teacher")}
            </Button>
          </div>
    ];
    return (
      <Container className="user-list">
        <AutoBreadcrumbs
          items={[{ icon: <Home />, to: "/" }, { text: _t("Teachers") }]}
        />
        <Typography variant="h1" color="primary">
          {_t("Teachers")}
        </Typography>
        <div className="bubble">
          <div
            style={{
              height: "70vh",
              width: "100%",
            }}
          >
            <LocalizedAdminTable
              components={{
                LoadingOverlay: this.props.loadingOverlay,
              }}
              rows={users}
              searchPlaceholder = {_t("Search")}
              buttons = {buttons}
              columns={columns}
              pageSize={10}
              rowHeight={70}
              disableSelectionOnClick
              autoHeight
              searchFields = {["full_name", "email"]}
              loading={loading}
            />
            <ConfirmDialog
              open={Boolean(delID)}
              prompt={`Are you sure you want to delete user with id ${delID} ?`}
              onConfirm={() => this.deleteUserHandler()}
              onClose={() => this.closeDialogHandler()}
            />

           
            {renderAddModal && (
              <TeacherForm
                open={renderAddModal}
                onSubmit={this.onFormSubmit}
                onClose={() => this.closeAddModal()}
                currentUser={currentUser}
              />
            )}

          {
            // show created passwords, if any
            createdPasswords && 
            <Passwords
            passwords={createdPasswords}
            open={Boolean(createdPasswords)}
            // open={true}
            onClose={() => this.setState({ createdPasswords: null })}
            />
          }
          </div>
        </div>
        {/**
         * This is the modal window that shows process, it should be on top of everything and it won't close until the process is done
         */}
        <ProcessSpinner open={processTitle} header={processTitle} />
      </Container>
    );
  }
}

export default WithTranslations(TeachersList);
