import { TextField } from '@material-ui/core';
import React from 'react';

export default class SingleStudentField extends React.Component {
    state={
        value: "",
    }
    componentDidMount = () => {
        const {params} = this.props;
        this.setState({value: params.value})
    };
    componentDidUpdate = (prevProps) => {
        const {params} = this.props;
        if(prevProps.params.value !== params.value){
            this.setState({value: params.value})
        }
    }
    render() {
        const {params,onChange,label} = this.props;
        //console.log(params);
        const {errors} = params.row;
        return (
            <div 
                className={`add-student-row${errors['error'] ? ' server-side-error' : '' }`} 
                data-server-side-error={errors['error']}
            >
                <TextField
                  //id={`user-${params.id}-form-${params.field}`}
                  className='student-field'
                  label={label}
                  variant='outlined'
                  error={errors[params.field] ? true : false}
                  helperText={errors[params.field] ? errors[params.field] : false}
                  onChange={(event) =>{
                  //console.log("params.row",params.row,event.target)
                      //editUserInfo(parseInt(params.id), {...params.row, [params.field]: event.target.value })
                      this.setState({value: event.target.value})
                      onChange(params,event.target.value)
                  }
                  }
                  value={this.state.value}
                />
          </div>
        );
    }
}