import ActionTypes from "../redux/actionTypes";
import Base from "./baseClientService";
import ProgramService from "./ProgramService";

class AuthService extends Base {
  static async login(login, password) {
    try {
      const result = await this.POST("/auth/organization/login", {
        login,
        password,
      });
      if (result) {
        const { user, token } = result;

        // dispatch redux action
        this.dispatch(this.actionTypes.LOGIN, { user, token });
        return result;
      } else {
        console.error("Wrong result received: ", result);
        return false;
      }
    } catch (e) {
      console.debug("Error: ", e.message);
      this.Error(e.message);
      return false;
    }
  }

  static async logout() {
    try {
      // attempt to logout session
      await this.POST("/auth/organization/logout");

      // clear programs cache to remove organization programs list
      ProgramService.clearCache();

      this.dispatch(this.actionTypes.LOGOUT);
      return true;
    } catch (e) {
      // logout frontend in any case
      this.dispatch(this.actionTypes.LOGOUT);
      console.error("Error logging out: ", e.message);
      return false;
    }
  }

  static async changePassword(password) {
    try {
      await this.POST("/auth/organization/change-password", { password });
      return true;
    } catch (e) {
      // console.error('Error changing password: ', e.message);
      return this.Error(e.message);
    }
  }

  static async changeStudentPassword(id, password) {
    try {
      await this.POST(`/auth/organization/change-student-password/${id}`, {
        password,
      });
      return true;
    } catch (e) {
      // console.error('Error changing password: ', e.message);
      return this.Error(e.message);
    }
  }

  static async changeTeacherPassword(id, password) {
    try {
      await this.POST(`/auth/organization/change-teacher-password/${id}`, {
        password,
      });
      return true;
    } catch (e) {
      // console.error('Error changing password: ', e.message);
      return this.Error(e.message);
    }
  }

  static async resetPassword(email) {
    try {
      const result = await this.POST("/auth/organization/reset-password", {
        email,
      });
      return result;
    } catch (e) {
      return this.Error(e.message);
    }
  }

  static async checkHash(hash) {
    try {
      const result = await this.POST(
        `/auth/organization/reset-password/check/${hash}`
      );
      console.log("checkHash - result: ", result);
      return result;
    } catch (e) {
      console.error("Reset password hash invalid: ", e.message);
      return this.Error(e.message);
    }
  }

  static async updatePasswordAfterReset(password, hash) {
    try {
      const result = await this.POST(
        "/auth/organization/reset-password/update",
        {
          password,
          hash,
        }
      );
      console.log("updatePasswordAfterReset - result: ", result);
      return result;
    } catch (e) {
      console.error("Error updating password after reset: ", e.message);
      return this.Error(e.message);
    }
  }

  static async renew() {
    try {
      const result = await this.POST("/auth/renew");
      // console.log('AuthService - renew - result: ', result);
      if (result && result.token) {
        const { token } = result;

        this.dispatch(ActionTypes.RENEW_TOKEN, token);
        return true;
      } else {
        console.error("Wrong data received: ", result);
      }
    } catch (e) {
      console.error("Error renewing token: ", e.message);
      return false;
    }
  }
}

Base.extend(AuthService);

export default AuthService;
