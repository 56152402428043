// import { Avatar, Typography } from "@material-ui/core";
import React from "react";
import Spinner from "../../template/Spinner";
import UserProgress from "../Users/user-progress";
import GroupStudentCourse from "./group-student-course";
import "./group-student.scss";
import UserDetails from "./user-details";

class GroupStudent extends React.Component {
  render() {
    const { current_user, id: user_id,  course_progress, programs, course_id, course, courseLink } = this.props;        
    console.debug("GroupStudent props:", this.props);
    return (
      <>
        <UserDetails {...this.props}
          user={current_user}
        />
        { !course_id && 
          <UserProgress
            {...{
              currentUser: current_user,
              programs,
              courseLink
            }}
          />
        }
        { course_id && !course && <Spinner /> }
        { course_id && course && <GroupStudentCourse {...{user_id, course, programs, course_progress}} /> }
      </>
    );
  }
}

export default GroupStudent;
