/**
 * informative description of various user statuses in the system
 * @param {String} status
 * @returns {String} description
 */
export function statusDescription(status) {
  switch (status) {
    case 'registered':
      return "User created but not yet confirmed, administration may change user's personal information";
    case 'active':
      return 'User looged in for the first time, making changes to personal information is no longer possible';
    case 'done':
      return 'User acomplished all assigned courses';
    case 'suspended':
      return 'User account deemed as temporary inactive by admin decision';
    case 'dormant':
      return 'User account deactivated due to inactivity';
    default:
      break;
  }
}
