import { Home } from "@material-ui/icons";
import React from "react";
import UserProfileDisplay from "./UserProfileDisplay";

import UserService from "../../../services/UserService";
import ProgramService from "../../../services/ProgramService";
import AuthService from "../../../services/AuthService";
import { showMessage } from "../../../redux/notificationActions";
import { connect } from "react-redux";
import WithTranslations from "../../WithTranslations";
import { __RouterContext } from "react-router";

class UserProfileController extends React.Component {
  state = {
    currentUser: null,
    programs: null,
    users: null,
    error: null,
  };

  componentDidMount() {
    this.props.fetchTranslations([
      "Students",
      "Profile",
    ])
    this.fetchPrograms();
    this.fetchUsers();
  }

  componentDidUpdate(prevProps) {
    const { user_id } = this.props.match.params;
    const { user_id: prev_user_id } = prevProps.match.params;
    if (user_id !== prev_user_id) this.fetchUsers();
  }

  async fetchUsers() {
    const users = await UserService.getUsers();
    let currentUser = null;
    if (!users) {
      return this.setState({ users: false, error: UserService.error });
    }
    // if id wasnt passed in the url - its a profile
    // if (!currentUser) {
    if (!this.props.match.params.user_id) {
      currentUser = this.props.reduxUser;
    } else {
      currentUser = users.find(
        (item) => item.id === parseInt(this.props.match.params.user_id)
      );
    }
    console.log("CURRENT USER: ", currentUser);
    this.setState({ currentUser, users });
  }

  async fetchPrograms() {
    try {
      const programs = await ProgramService.getPrograms();
      if (programs) {
        this.setState({
          programs,
        });

        return true;
      } else {
        this.setState({ error: "Failed loading programs" });
        return false;
      }
    } catch (err) {
      this.props.onShowMessage(
        `Fetching organization programs from server failed.`,
        "error"
      );
      return false;
    }
  }

  /**
   * Handles changing student password from student list
   * @param {*} password
   * @returns
   */
  changeStudentPassword = async (password) => {
    const { currentUser } = this.state;
    if (!currentUser)
      return this.props.onShowMessage("No user selected", "error");

    if (!(await AuthService.changeStudentPassword(currentUser.id, password))) {
      return this.props.onShowMessage(AuthService.error, "error");
    }

    this.props.onShowMessage("Password successfully changed", "success");
    return true;
  };

  /**
   * Handles changing organization user password
   * @param {*} password
   * @returns
   */
  changeUserPassword = async (password) => {
    if (!(await AuthService.changePassword(password))) {
      return this.props.onShowMessage(AuthService.error, "error");
    }
    this.props.onShowMessage("Password successfully changed", "success");
    return true;
  };

  onStudentUpdate = () => {
    this.fetchPrograms();
    this.fetchUsers();
  };

  render() {
    const { currentUser } = this.state;
    const {_t} = this.props;
    const user_id = this.props.match.params.user_id;
    // const user = this.state.currentUser;

    let displayProps =
      currentUser && this.props.match.params.user_id
        ? // student profile
          {
            ...this.state,
            onPasswordChange: this.changeStudentPassword,
            onStudentUpdate: this.onStudentUpdate,
            breadcrumbs: [
              { icon: <Home />, to: "/" },
              { text: _t("Students"), to: "/students" },
              {
                text: `${currentUser.name} ${currentUser.last_name}`,
                to: `/students/${user_id}`,
              },
            ],
            backLink: "/students",
            // updatePasswordURL: `/change-student-password/${this.props.match.params.user_id}`,
          }
        : // organization user profile
          {
            ...this.state,
            isProfile: true,
            // updatePasswordURL: "/change-password",
            onStudentUpdate: this.onStudentUpdate,
            onPasswordChange: this.changeUserPassword,
            breadcrumbs: [{ icon: <Home />, to: "/" }, { text: _t("Profile") }],
            backLink: "/",
          };

    return <UserProfileDisplay {...{ ...this.props, ...displayProps }} />;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};

const stateToProps = (state) => ({ reduxUser: state.auth.user });

export default connect(stateToProps, mapDispatchToProps)(WithTranslations(UserProfileController));
