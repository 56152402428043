import { Button } from "@material-ui/core";
import { Assignment, Cancel } from "@material-ui/icons";
// import { Archive, Delete } from "@material-ui/icons";
import React from "react";
import DebounceSearch from "../../template/DebounceSearch";
import LocalizedDataGrid from "../../template/localized-Data-Grid";
import "./style.scss";

class SelectUsersForm extends React.Component {
  state = {
    selected: [],
    search: "",
  };

  onSelect = (selected) => {
    this.setState({ selected });
  };

  onSearch = (search) => {
    this.setState({ search });
  };

  render() {
    const { onAssign = console.debug, onClose = console.debug, users } = this.props;
    let { selected, search } = this.state;

    // fail safe for non-string values
    search = typeof search === "string" ? search.toLowerCase() : "";

    if (!users) return "Loading...";
    if (!Array.isArray(users))
      return "Error loading users, please try refreshing the page";

    //   console.debug( users );

    const columns = [
      {
        field: "name",
        flex: 1,
        headerName: "Name",
        valueGetter: (params) =>
          `${params.row.name}${
            params.row.last_name ? ` ${params.row.last_name}` : ""
          }`,
      },
      {
        field: "citizen_id",
        flex: 1,
        headerName: "Citizen ID",
      },
      //   {
      //     field: "actions",
      //     flex: 1,
      //     headerName: "Actions",
      //     renderCell: (params) => (<ButtonGroup>
      //         {
      //             isOrganization &&
      //             <Button variant="contained" color="secondary"><Delete/> Remove</Button>
      //         }
      //         <Button variant="contained"><Archive/>Archive</Button>
      //     </ButtonGroup>)
      //   },
    ];

    return (
      <>
        <DebounceSearch onChange={this.onSearch} fullWidth className="mb-6"/>
        <LocalizedDataGrid
          className="select-users-form w-100p"
          autoHeight
          rowHeight={30}
          rows={
            // if search is set, filter the users by name and citizen id
            search
              ? users.filter((u) => {
                  return (
                    u?.name?.toLowerCase().includes(search) ||
                    u?.citizen_id?.toLowerCase().includes(search)
                  );
                })
              : // no search is set - return all users
                users
          }
          columns={columns}
          onSelectionModelChange={this.onSelect}
          checkboxSelection
          pageSize={10}
        />
        <div className="align-self-stretch flex grow-1 justify-content-space-evenly pt-6">
          <Button
            variant="contained"
            color="primary"
            disabled={!Boolean(selected.length > 0)}
            onClick={() => onAssign(selected)}
          >
            <Assignment /> Assign
          </Button>
          <Button variant="contained" color="secondary" onClick={() => onClose() }>
            <Cancel /> Cancel
          </Button>
        </div>
      </>
    );
  }
}

export default SelectUsersForm;
