import { Button, ButtonGroup } from "@material-ui/core";
import React from "react";
import { showMessage } from "../../../redux/notificationActions";
import store from "../../../redux/store";
import ProgressService from "../../../services/ProgressService";
import Error from "../../template/Error";
import ConfirmDialog from "../../template/ConfirmDialog";
import experiment_pages from "../../../data/experiment_pages.json";
import "./style.scss";
import Spinner from "../../template/Spinner";
import AsyncButton from "../../template/AsyncButton";
import { ChevronLeft, FirstPage, Undo } from "@material-ui/icons";
import WithTranslations from "../../WithTranslations";
import LocalizedAdminTable from "../../template/localized-admin-table";

const msg = (text, level) => store.dispatch(showMessage(text, level));

const PagesProgressDisplay = ({ pages, experiment_progress }) => (
  <ul className="pages-progress-display">
    {
      // cycle through experiment pages' ids
      Object.keys(experiment_pages).map((p) => {
        // const e_page = experiment_pages[p]

        console.debug("ppd: pages: ", pages, "\npage ID:", p);
        // if pages is an array...
        const item = Array.isArray(pages)
          ? // find page with the same page slug as current page id
            pages.find((pp) => pp.page_slug === p) || {}
          : {};
        return <li className={`${item.status || "nope"} `}></li>;
      })
    }
    <li className="text">{experiment_progress || 0}%</li>
  </ul>
);

class GroupStudentCourse extends React.Component {
  state = {
    progress: null,
    error: null,
    confirmResetCourse: null,
    confirmResetPage: null,
    confirmMessage: null,
    onConfirm: null,
  };

  componentDidMount() {
    this.fetchData();
    this.props.fetchTranslations([
      'Experiment',
      'Status',
      'Progress',
      'Score',
      'Actions',
      "Reset experiment",
      "Reset last page",
      'Progress',
      'Score',
      "Are you sure you want to completely reset this course progress?",
      'Reset',
      "Search",
    ])
  }

  fetchData = async () => {
    const { user_id, course } = this.props;
    if (!user_id || !course || !course.course_id) {
      console.error(
        "GroupStudentCourse.fetchData error: user_id, course or course.course_id not stated"
      );
      this.setState({ error: "Error in passed data" });
    }
    try {
      const progress = await ProgressService.getUserCourseProgress(
        user_id,
        course.course_id
      );

      this.setState({ progress });
    } catch (e) {
      this.setState({ progress: false, error: e.message });
    }
  };

  confirmCourseReset = () => {};

  resetCourseProgress = async () => {
    try {
      const { user_id, course } = this.props;
      const { course_id } = course || {};

      if (!user_id || !course) {
        console.debug(
          "resetCourseProgress no user_id or course_id:",
          user_id,
          course_id
        );
        msg(
          "Could not reset course progress due to wrong data received",
          "error"
        );
        return;
      }

      await ProgressService.resetCourseProgress(user_id, course_id);
      msg("Course progress successfully reset");
      this.fetchData();
    } catch (e) {
      msg(e.message, "error");
    }
  };

  resetExperimentProgress = async (experiment_id) => {
    try {
      const { user_id, course } = this.props;

      if (!user_id) {
        console.debug("resetExperimentProgress no user_id:", user_id);
        msg(
          "Could not reset experiment progress due to wrong data received",
          "error"
        );
        return;
      }

      await ProgressService.resetExperimentProgress(user_id, experiment_id);
      msg("Experiment progress successfully reset");
      this.fetchData();
    } catch (e) {
      msg(e.message, "error");
    }
  };

  resetPageProgress = async (experiment_id, page) => {
    try {
      const { user_id } = this.props;

      if (!user_id) {
        console.debug("resetPageProgress no user_id:", user_id);
        msg(
          "Could not reset page progress due to wrong data received",
          "error"
        );
        return;
      }

      await ProgressService.resetExperimentPageProgress(
        user_id,
        experiment_id,
        page
      );
      msg("Page progress successfully reset");
      this.fetchData();
    } catch (e) {
      msg(e.message, "error");
    }
  };

  render() {
    console.debug("group student course props", this.props);
    const { program_id, course, course_progress, _t } = this.props;

    const {
      progress,
      error,
      confirmResetCourse,
      confirmResetPage,
      confirmMessage,
      onConfirm,
    } = this.state;

    if (error) return <Error {...{ error }} />;

    console.debug("course_progress:", progress);

    const rows =
      progress && Array.isArray(progress.experiment_progress)
        ? progress.experiment_progress.map((pp, index) => ({
            ...pp,
            index,
            id: pp.experiment_id,
          }))
        : null;

    // this is the progress of current experiment in course
    const _currentExperimentProgress =
      progress?.experiment_progress.find(
        (exp) => exp.experiment_id === progress.current_experiment_id
      ) || {};

    // the index of current experiment's progress in the array
    const currentExperimentIndex = progress?.experiment_progress.findIndex(
      (exp) => exp.experiment_id === progress.current_experiment_id
    );

    // this is the progress of experiment that's previous to the current experiment in course
    // it's either undefined (if current exp is the first one) or an object
    const _prevExperimentProgress =
      progress?.experiment_progress[currentExperimentIndex - 1];

    console.debug(
      "_prevExperimentProgress:",
      _prevExperimentProgress,
      "_currentExperimentProgress:",
      _currentExperimentProgress
    );

    const columns = rows
      ? [
          { field: "id", hide: true },
          {
            field: "index",
            headerName: "#",
            renderCell: ({ value }) => <span>{value + 1}</span>,
            // flex: "0 0",
          },
          {
            field: "title",
            headerName: _t("Experiment"),
            flex: 1,
          },
          {
            field: "status",
            headerName: _t("Status"),
            flex: 1,
          },
          {
            field: "progress",
            headerName: _t("Progress"),
            flex: 1,
            renderCell: ({ row }) => {
              const { pages, experiment_progress } = row;
              console.debug("pages:", pages);
              return (
                <PagesProgressDisplay {...{ pages, experiment_progress }} />
              );
            },
          },
          {
            field: "experiment_grade",
            headerName: _t("Score"),
            flex: 1,
            // renderCell: ({ value }) => (value > 0 ? value : "not started"),
          },

          {
            field: "actions",
            flex: 2,
            headerName: _t("Actions"),
            renderCell: ({ row }) => {
              console.debug("course progress:", course);
              console.debug("row.experiment_id:", row.experiment_id);

              // shorten the following checks by returning empty string if part of the conditions arent met
              if (!progress || !Array.isArray(progress.experiment_progress))
                return <>-</>;

              // whether the row corresponds to "previous" experiment
              const rowIsPrevious =
                _prevExperimentProgress &&
                row.experiment_id === _prevExperimentProgress.experiment_id;

              // whether the row corresponds to "current" experiment
              const rowIsCurrent =
                row.experiment_id === _currentExperimentProgress?.experiment_id;

              // We show the 'reset chapter' button either if the row is for current experiment and its progress is not 0%
              // or if it's for previous experiment and is 100%, while the current chapter is 0%
              const showResetChapterButton =
                (rowIsCurrent &&
                  _currentExperimentProgress.experiment_progress > 0) ||
                (rowIsPrevious &&
                  _prevExperimentProgress.experiment_progress === 100 &&
                  _currentExperimentProgress.experiment_progress === 0);

              // We show the 'reset experiment' button if the row is for current experiment and it's not 0%
              const showResetExperimentButton =
                rowIsCurrent &&
                _currentExperimentProgress.experiment_progress > 0;

              // can reset if...
              // const canReset =
              // chapter is not locked
              // ["open", "done"].includes(row.status) &&
              // this is the current experiment
              // _experimentProgress.experiment_id ===  parseInt(row.experiment_id);
              // row.experiment_progress > 0 &&
              // progress.current_experiment_id &&
              // progress.current_experiment_id === parseInt(row.experiment_id);

              // const canResetChapter = progress && Array.isArray(progress.experiment_progress) &&
              // progress.experiment_progress.find(pp => pp.experiment_id === progress.current_experiment_id)
              // .experiment_progress > 0;
              // const canResetExperiment = _experimentProgress.experiment_progress > 0;

              // const prevExperimentIndex =
              //   progress.experiment_progress.length > 0 &&
              //   progress.experiment_progress.findIndex(
              //     (exp) => exp.experiment_id === progress.current_experiment_id
              //   ) - 1;
              // const prevExperiment =
              //   prevExperimentIndex > -1 &&
              //   progress.experiment_progress[prevExperimentIndex];
              // console.debug("row:", row);
              return (
                <>
                  {/* {canReset && canResetExperiment && ( */}
                  <ButtonGroup>
                    {showResetExperimentButton && (
                      <AsyncButton
                        size="small"
                        color="secondary"
                        variant="contained"
                        icon={<FirstPage />}
                        onClick={async () =>
                          new Promise((resolve) => {
                            this.setState({
                              confirmMessage: `Are you sure you want to reset progress for this experiment: ${row.title} ?`,
                              onConfirm: async () => {
                                this.setState({
                                  confirmMessage: null,
                                  onConfirm: null,
                                });
                                await this.resetExperimentProgress(
                                  row.experiment_id
                                );
                                resolve(true);
                              },
                            });
                          })
                        }
                      >
                        {_t("Reset experiment")}
                      </AsyncButton>
                    )}
                    {showResetChapterButton && (
                      <AsyncButton
                        size="small"
                        color="secondary"
                        variant="contained"
                        icon={<ChevronLeft />}
                        onClick={async () =>
                          new Promise((resolve) => {
                            this.setState({
                              confirmMessage: `Are you sure you want to reset the last page's progress in this experiment: ${row.title} ?`,
                              onConfirm: async () => {
                                this.setState({
                                  confirmMessage: null,
                                  onConfirm: null,
                                });
                                await this.resetPageProgress(
                                  row.experiment_id,
                                  row.current_page
                                );
                                resolve(true);
                              },
                            });
                          })
                        }
                      >
                        {_t("Reset last page")}
                      </AsyncButton>
                    )}
                  </ButtonGroup>
                  {/* )} */}
                  {/* {prevExperiment &&
                prevExperiment.experiment_progress > 0 &&
                !canResetExperiment &&
                prevExperiment.experiment_id ===
                  parseInt(row.experiment_id) && (
                  <ButtonGroup>
                    <AsyncButton
                      size="small"
                      color="secondary"
                      variant="contained"
                      onClick={async () =>
                        new Promise((resolve) => {
                          this.setState({
                            confirmMessage: `Are you sure you want to reset page summary progress in this experiment: ${row.title} ?`,
                            onConfirm: async () => {
                              this.setState({
                                confirmMessage: null,
                                onConfirm: null,
                              });
                              await this.resetExperimentProgress(
                                progress.current_experiment_id
                              );
                              resolve(true);
                            },
                          });
                        })
                      }
                    >
                      Reset last page
                    </AsyncButton>
                  </ButtonGroup>
                )} */}
                </>
              );
            },
          },
        ]
      : [];

    console.debug("GroupStudentCourse course_progress:", course_progress);
    console.debug("GroupStudentCourse progress:", progress);
    return (
      <div className="group-student-course">
        <h2>
          {course.course_code} : {course.title}
        </h2>
        {progress && progress.progress_percent > 0 && (
          <>
            <span class="course-progress">
              {progress.progress_percent < 100 ? (
                <>{_t("Progress")}: {progress.progress_percent || 0}%</>
              ) : (
                <>{_t("Score")}: {progress.total_score || 0}</>
              )}
              <AsyncButton
                variant="contained"
                color="secondary"
                size="small"
                // onClick={() => this.setState({ confirmResetCourse: true })}
                onClick={async () =>
                  new Promise((resolve) => {
                    this.setState({
                      confirmMessage:
                        _t("Are you sure you want to completely reset this course progress?"),
                      onConfirm: async () => {
                        this.setState({
                          confirmMessage: null,
                          onConfirm: null,
                        });
                        await this.resetCourseProgress();
                        resolve(true);
                      },
                    });
                  })
                }
              >
                {_t("Reset")}
              </AsyncButton>
            </span>
          </>
        )}

        {!progress && <Spinner />}
        {rows && <LocalizedAdminTable
         autoHeight 
         {...{ columns, rows }} 
         searchPlaceholder = {_t("Search")}
         />}

        <ConfirmDialog
          open={confirmResetCourse}
          header="Resetting course progress"
          prompt="Are you sure you want to reset course progress completely?"
          onConfirm={() => {
            this.setState({ confirmResetCourse: false });
            this.resetCourseProgress();
          }}
          onClose={() => this.setState({ confirmResetCourse: false })}
        />
        <ConfirmDialog
          open={confirmMessage && onConfirm}
          header="Reset"
          prompt={confirmMessage}
          onConfirm={onConfirm}
          onClose={() =>
            this.setState({ confirmMessage: null, onConfirm: null })
          }
        />
      </div>
    );
  }
}

export default WithTranslations(GroupStudentCourse);
