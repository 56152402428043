import { Container, Typography } from '@material-ui/core';
import React from 'react'
import ReportsStudentsList from './reports-students-list';
import "./style.scss";
import AutoBreadcrumbs from '../../../template/CustomBreadcrumbs';
import { Home } from "@material-ui/icons";
import WithTranslations from '../../../WithTranslations'
class ReportsStudents extends React.Component{
    state = {
        breadcrumbs: [],
        title: null,
    }
    componentDidMount() {
        this.props.fetchTranslations([
            'Reports',
            "Back",
            "Grade Report",
            "Programs Report",
            "Extended Grade Report",
            "Course Report",
            "Reports",
        ])
        this.prepState();
    }
    componentDidUpdate(prevProps) {
        if(prevProps.match.params.report !== this.props.match.params.report){
            this.prepState();
        }
        if(prevProps._language !== this.props._language || (!prevProps.translationsLoaded && this.props.translationsLoaded)){
            this.prepState();
        }
    }
    prepState = () => {
        const {_t} = this.props;
        let breadcrumbs = [
            { icon: <Home/> , to: "/"},
        ];
        const {report} = this.props.match.params;
        let title = "";
        switch(report){
            case "students":
                breadcrumbs.push({text: _t("Reports"), to: "/reports"});
                breadcrumbs.push({text: _t("Grade Report")});
                break;
            case "programs":
                breadcrumbs.push({text: _t("Reports"), to: "/reports"});
                breadcrumbs.push({text: _t("Programs Report")});
                break;
            case "extended":
                breadcrumbs.push({text: _t("Reports"), to: "/reports"});
                breadcrumbs.push({text: _t("Extended Grade Report")});
                break;
            case "course":
                breadcrumbs.push({text: _t("Reports"), to: "/reports"});
                breadcrumbs.push({text: _t("Course Report")});
                break;
            default:
                title = _t("Reports");
                breadcrumbs.push({text: _t("Reports")});
                break;
        }
        this.setState({breadcrumbs,title});
    }
    render(){
        const {report, course_ids} = this.props.match.params;
        const {breadcrumbs,title} = this.state;
        const {_t} = this.props;
        return(
            <Container>
                <AutoBreadcrumbs 
                items={breadcrumbs}
                backLink={report?`/reports`:null}
                backText={_t("Back")}
                />
                {!report &&<Typography variant="h1" color="primary">{_t(title)}</Typography>}
                <ReportsStudentsList report = {report} course_ids = {course_ids}/>
            </Container>
        );
    }
}

export default WithTranslations(ReportsStudents)