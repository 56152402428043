import React from 'react'
import "./style.scss";
import {AutoBreadcrumbs} from '@ses-education/courses-components';
import { Button } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { Link } from 'react-router-dom';

class CustomBreadcrumbs extends React.Component{

    render(){
        let { rightSide, backLink, backText, ...other } = this.props;

        rightSide = rightSide || backLink ?<div className="flex row align-center"><Button component={Link} to={backLink}><ChevronLeft />{backText || "Back"}</Button></div> : null;

        return(
            <div className="custom-breadcrumbs">
                <AutoBreadcrumbs {...other} />
                { rightSide && rightSide }
            </div>
        )
    }
}

export default CustomBreadcrumbs