import {
  Grid,
  LinearProgress,
  Tooltip,
  Typography,
  Zoom,
} from "@material-ui/core";
import { SaveAlt } from "@material-ui/icons";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import OrganizationService from "../../../../../../services/OrganizationService";
import AsyncButton from "../../../../../template/AsyncButton";
import Spinner from "../../../../../template/Spinner";
import UserDescriptionHeader from "./user-description-header";
import LocalizedDataGrid from "../../../../../template/localized-Data-Grid";
import { Redirect } from "react-router-dom";

const Progress = (props) => (
  <span className="linear-progress-bar">
    {props.showValue && <span className="value">{props.value}%</span>}
    <LinearProgress
      className="progress"
      value={props.value}
      valueBuffer={100}
      variant="determinate"
    />
  </span>
);
class ReportStudentsExtended extends React.Component {
  state = {
    userToRender: null,
  };
  componentDidMount() {
    if (this.props.studentsprogress && this.props.students) {
      this.setState({ userToRender: null }, this.fetchNext);
    }
    this.props.fetchTranslations([
      "Extended Grade report",
      "Experiment",
      "Grade",
      "Final Grade",
      "no progress",
      "export To Excel",
    ]);
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.studentsprogress &&
      this.props.students &&
      (prevProps.students !== this.props.students ||
        prevProps.studentsprogress !== this.props.studentsprogress)
    ) {
      this.setState({ userToRender: null }, this.fetchNext);
    } else if (
      prevProps.students !== this.props.students ||
      prevProps.studentsprogress !== this.props.studentsprogress
    ) {
      this.setState({ userToRender: null });
    }
  }
  fetchNext = () => {
    const { students, studentsprogress } = this.props;
    console.log(students);
    let userToRender = [...(this.state.userToRender || [])];
    const numberOfUsers = Math.min(
      userToRender.length + 5,
      (students || []).length
    );
    userToRender = Array.isArray(students)
      ? students.slice(0, numberOfUsers)
      : [];
    userToRender = userToRender.map((user) => {
      if (Array.isArray(user.assigned_courses)) {
        user.assigned_courses = user.assigned_courses.map((course) => {
          const course_progress = (user.course_progress || []).find(
            (c) => c.course_id === course.course_id
          );
          let studentprogress;
          if (studentsprogress) {
            ((studentsprogress || []).filter(
              (s) => s.user_id === user.id
            ) || []).map(c => {
              const item = (c.course_progress || []).find(cp => cp.course_id === course.course_id)
              if(item){
                studentprogress = item.progress;
              }
            })
            studentprogress = (studentprogress || []).map((p) => {
              console.log("studentprogress", p);
              const {
                experiment_id,
                experiment_grade,
                experiment_progress,
                ordering,
                title,
              } = p;
              return {
                id: title,
                ordering,
                grade: experiment_grade ? (
                  experiment_grade
                ) : (
                  <Progress showValue={true} value={experiment_progress || 0} />
                ),
              };
            });
          }
          return {
            ...course,
            course_progress,
            studentprogress,
          };
        });
      }
      return {
        ...user,
      };
    });
    this.setState({ userToRender }, () => console.log(userToRender));
  };
  fetchReport = async () => {
    const { students, studentsprogress } = this.props;
    const data = {
      users: students,
      course_progress: studentsprogress,
    };
    const result = await OrganizationService.ExtendedReportExcel(data);
    const { onShowMessage } = this.props;
    if (!result) {
      onShowMessage(
        OrganizationService.error || `Unknown error fetching report`,
        "error"
      );
      return false;
    }
    const ab = new ArrayBuffer(result.data.length);
    const view = new Uint8Array(ab);
    for (let i = 0; i < result.data.length; ++i) {
      view[i] = result.data[i];
    }
    //console.log(ab,result.data[0]);
    const blob = new Blob([ab]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "EXTENDED_GRADE_REPORT.xlsx");
    link.click();
    window.URL.revokeObjectURL(url);
    console.log("your file has downloaded!");
  };
  render() {
    const { filters, groups, students, studentsprogress, report, _t } =
      this.props;
    const { userToRender } = this.state;
    if (report && !(students && studentsprogress)) {
      return <Redirect to={"/reports"} />;
    }
    const columns = [
      {
        field: "id",
        headerName: _t("Experiment"),
        flex: 2.5,
        sortable: false,
        renderCell: ({ value }) => (
          <Tooltip TransitionComponent={Zoom} title={value} arrow>
            <span className="experiment-cell-text">{value}</span>
          </Tooltip>
        ),
      },
      {
        field: "grade",
        flex: 1,
        headerName: _t("Grade"),
        sortable: false,
        renderCell: ({ value }) => (
          <span className="table-cell-text">{value}</span>
        ),
      },
    ];

    return (
      <div class="grade-report">
        <Typography
          variant="h1"
          color="primary"
          className="grade-report-header"
        >
          {_t("Extended Grade report")}
          <AsyncButton
            variant="contained"
            // className="add-button bg-orange color-white"
            color="primary"
            onClick={this.fetchReport}
            icon={<SaveAlt />}
            size="small"
          >
            {_t("export To Excel")}
          </AsyncButton>
        </Typography>

        {!userToRender && <Spinner />}
        <InfiniteScroll
          dataLength={(userToRender || []).length}
          next={this.fetchNext}
          hasMore={(userToRender || []).length < (students || []).length}
          loader={<Spinner />}
        >
          {studentsprogress &&
            userToRender &&
            Array.isArray(userToRender) &&
            userToRender.map((user) => (
              <div class="user-grades">
                <UserDescriptionHeader {...user} />
                <Grid container spacing={3}>
                  {Array.isArray(user.assigned_courses) &&
                    user.assigned_courses.map((course) => {
                      // if course.studentprogress is an array - copy it to a new array and sort it by ordering
                      // (if we don't copy it, the sort will be by reference and it looks like it's read-only)
                      const rows = Array.isArray(course.studentprogress)
                        ? [...course.studentprogress].sort((a, b) => {
                            // course.studentprogress.sort((a, b) => {
                            if (a && b && a.ordering !== b.ordering) {
                              return a.ordering - b.ordering;
                            }
                            return a.id - b.id;
                          })
                        : // if course.studentprogress is not an array, render empty array
                          [];
                      return (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <div className="course-grades">
                            <h3>
                              <Tooltip
                                TransitionComponent={Zoom}
                                title={course.title}
                                arrow
                                className="course-title"
                              >
                                <span>{course.title}</span>
                              </Tooltip>
                              {course.course_progress &&
                                course.course_progress.status !== "done" && (
                                  <span className="progress">
                                    {course.course_progress.progress}%
                                  </span>
                                )}
                              {course.course_progress &&
                                course.course_progress.status === "done" && (
                                  <div className="gradeHeader">
                                    <span>{_t("Final Grade")}:</span>
                                    <span className="progress">
                                      {course.course_progress.total_score}
                                    </span>
                                  </div>
                                )}
                            </h3>
                            {!course.course_progress && (
                              <p>{_t("no progress")}</p>
                            )}
                            {course.course_progress &&
                              course.studentprogress && (
                                <LocalizedDataGrid
                                  className="course-grades-grid"
                                  loading={!Boolean(course.studentprogress)}
                                  {...{ columns }}
                                  disableSelectionOnClick
                                  rows={rows}
                                  pageSize={7}
                                  rowHeight={20}
                                  autoHeight
                                  hideFooterPagination={true}
                                  disableColumnMenu={true}
                                />
                              )}
                          </div>
                        </Grid>
                      );
                    })}
                </Grid>
              </div>
            ))}
        </InfiniteScroll>
      </div>
      //     ))}
      // </div>
    );
  }
}

export default ReportStudentsExtended;
