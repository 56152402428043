import {
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Grid,
  TextField,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import React from "react";
import ProgramService from "../../../services/ProgramService";
import ConfirmDialog from "../../template/ConfirmDialog";
import Error from "../../template/Error";
import ImageSelector from "../../template/ImageSelector";
import Spinner from "../../template/Spinner";
import WithTranslations from "../../WithTranslations";
import SelectList from "../../template/SelectList";
import "./style.scss";
import { objectsAreEqual } from "../../../utils/functions";

const defaultProgram = {
  program_id: null,
  title: "",
  description: "",
  images: [],
  courses: [],
};

const {
  REACT_APP_REACT_APP_MEDIA_URL = "https://images.ses-education.com/courses",
  REACT_APP_DEFAULT_PROGRAM_IMAGE = "course-no-image.png"
} = process.env;

const defaultImage = `${REACT_APP_REACT_APP_MEDIA_URL}/${REACT_APP_DEFAULT_PROGRAM_IMAGE}`
  // "https://images.ses-education.com/courses/course-no-image.png";

class EditProgram extends React.Component {
  state = {
    // currently edited program (default program, if no program is passed)
    program: {},
    // the original (default program, if no program is passed)
    original: {},


    tab: 0,
    onConfirmDialog: false,
    
    // object of all courses hashed by course id
    allCourses: null,
    
    // course ids in the program
    // we store them here as additional array
    // to ensure the re-render on update
    // which didn't necessarily happen
    // on program update with new course_ids field
    course_ids: [],

    // search terms
    searchNotInProgram: "",
    searchInProgram: ""
  };


  componentDidMount() {
    this.props.fetchTranslations([
      "Add program",
      "Edit program",
      "Title",
      "Description",
      "programEditPrompt",
      "Available courses",
      "Courses in program",
      "Save courses list",
      "Reset to previous",
      "Search...",
      "Close",
      "Program image",
      "There are unsaved changes in program, do you really want to close and loose the changes?",
      "Properties",
      "Courses",
      "Cancel",
      "Yes",
      "Add",
    ]);

    this.initProgram();
  }
  componentDidUpdate(prevProps) {
    if (!objectsAreEqual( prevProps.program, this.props.program) ){
      this.initProgram();
    }
  }

  initProgram() {
    let { program } = this.props;
    // failsafe for when program is not received or received as not an object
    program = program && typeof program === "object" ? program : {};

    if( !this.state.allCourses )
          this.fetchAllCourses();

    let {course_ids = [], courses = []} = program;

    // when creating a program, this comes in as null
    courses = Array.isArray(courses) ? courses : [];
    course_ids = course_ids.filter( c => c ); // filter out empty elements

    this.setState(
      {
        program: {
          ...defaultProgram,
          ...program,
          // have to spread courses and course ids
          // because elsewise they point to the same object!
          course_ids: [...course_ids],
          courses: [...courses]
        },

        // set original program to compare later
        original: {
          ...defaultProgram,
          ...program,
          course_ids: [...course_ids],
          courses: [...courses]
        },

        // store course ids as a separate array 
        // because we were haveing problems with re-rendering 
        // when updating the array within the program object
        course_ids: [...course_ids]
      }
    );
  }

  fetchAllCourses = async () => {
    // const { program } = this.state;
    let allCourses = await ProgramService.getAllCourses();

    // hash allCourses by course id
    allCourses = allCourses ? allCourses.reduce( (res, c) => {
      // here, c.course_id converts to string, so we need to have it in mind when fetching course from the object
      return {...res, [c.course_id] : c }
    }, {}) : null;

    // console.debug("allCourses", allCourses);

    
    // const coursesNotInProgram = this.filterCourses(allCourses, program);
    this.setState({
      allCourses,
      // coursesNotInProgram,
      // if allCourses is falsy, set error to service's error value
      error: allCourses ? null : ProgramService.error || "Unknown error",
    });
  };



  onSave = () => {
    const { onSave = console.debug } = this.props;
    if (typeof onSave === "function") {
      onSave(this.state.program);
      return;
    }
    console.debug("onSave is not a function");
  };


  onImageSelect = async (ev) => {
    //console.debug(image);

    // use onSaveImage callback to save image to database
    const { onSaveImage = console.debug } = this.props;
    if (typeof onSaveImage === "function") {
      // send program id and course id list
      await onSaveImage(ev);
      return;
    }
    console.debug("onSaveImage is not a function");
  };

  onImageDelete = async () => {
    // use onSaveImage callback to save image to database
    const { onDeleteImage = console.debug } = this.props;
    if (typeof onDeleteImage === "function") {
      // send program id and course id list
      await onDeleteImage();
      return;
    }
    console.debug("onDeleteImage is not a function");
  };
  /**
   * Move course from available to program courses or vice versa
   * @param {object} course - the course object
   * @param {string} operation either "add" or "remove". If "add" - course is added to program courses
   */
  onCourseClick = (course_id, operation) => {

    // console.debug("onCourseClick", course_id, operation);
    let { program, allCourses } = this.state;
    let { course_ids = [] } = program;

    // have to convert course_id to string
    const courseMoving = allCourses[`${course_id}`];

    if( !courseMoving ){
      return console.error("no course selected!");
    }

    switch( operation ){
      case "add":
        // add the course
        course_ids.push(course_id);
        break;
      case "remove":
        // remove the course by filtering it out
        course_ids = course_ids.filter( cid => cid !== course_id);
        break;
    }

    // filter out duplicates
    course_ids = [...new Set(course_ids)];


    // sort ids by course code using allCourses
    course_ids.sort((a, b) => allCourses[a]?.course_code.localeCompare( allCourses[b]?.course_code));
    
    // update program with new course ids
    program = { ...program, course_ids };
    // prepare updated list of courses not in program
    // const coursesNotInProgram = this.filterCourses(allCourses, program);
    // update program and the list
    this.setState({
      program,

      // store course_ids here to enforce re-render
      course_ids: [...course_ids]
    });
  };

  resetCoursesToOriginal = () => {
    const { original } = this.state;
    const { program } = this.state;

    // fail safe for when original courses is empty
    const originalCourses = Array.isArray(original.course_ids)
      ? original.course_ids
      : [];

    this.setState({
      program: { ...program, course_ids: [...originalCourses] },
      course_ids: [...originalCourses]
    });
  };

  onFieldChange = ({ target }) => {
    const { name, value } = target;
    // update single field within state.program
    this.setState((prevState) => ({
      program: {
        ...prevState.program,
        [name]: value,
      },
    }));
  };

  /**
   * Search comparison for both course lists
   * @param {*} item
   * @param {*} searchString
   * @returns
   */
  onSearch = (course_id, searchString) => {
    searchString = searchString.toLowerCase();
    const {allCourses} = this.state;
    const item = allCourses[`${course_id}`];
    return (
      (item?.course_code &&
        item.course_code.toLowerCase().includes(searchString)) ||
      (item?.title && item.title.toLowerCase().includes(searchString))
    );
  };

  render() {
    const { _t, onClose } = this.props;
    const {
      program,
      original,
      tab,
      // coursesNotInProgram,
      allCourses,
      course_ids = [],
      error,
      onConfirmDialog,
    } = this.state;
    

    // while not received all courses, show spinner
    if (allCourses === null) return <Spinner />;

    // if error received, show the error
    if (error) return <Error error={error} />;

    // allCourses isn't null and error not set (allCourses isn't false)
    // hence allCourses is an object, we can get its keys:
    const allCourseIds = Object.keys(allCourses).map( cid => parseInt(cid));

    const { program_id, title, description, images = [] } = program;
    
    // ids of all courses not in the program
    const coursesNotInProgram = allCourseIds.filter( cid => !course_ids.includes( cid ) );

    // console.debug("allCourses", allCourses);
    // console.debug("program", program);
    // console.debug("course_ids", course_ids)
    // console.debug("coursesNotInProgram", coursesNotInProgram)


    const programDirty = !objectsAreEqual(program, original); //JSON.stringify(rest) !== JSON.stringify(restOriginal);
    const coursesDirty =
      JSON.stringify(course_ids) !== JSON.stringify(original.course_ids);
    
    // helper component to render list item
    const ListItemRenderer = ({item: cid}) => {
      const item = allCourses[cid];
      // console.debug("ListItemRenderer allCourses",allCourses);
      // console.debug("ListItemRenderer cid",cid);
      // console.debug("ListItemRenderer item",cid);
      
      return (
      <>
        <span>{item?.course_code || "???"}</span>
        <span>{item?.title || "---"}</span>
      </>
    )};

    const programIsNew = !program_id;

    // console.debug("coursesNotInProgram", coursesNotInProgram);

    return (
      <div className="edit-program">
        {/* <h1>{program_id ? _t("Edit program") : _t("Add program")}</h1> */}
        {program_id && (
          <Tabs
            value={tab}
            onChange={(e, v) => this.setState({ tab: v })}
            indicatorColor="secondary"
            textColor="primary"
            className="tabs-container"
          >
            <Tab label={_t("Properties")} value={0} />
            <Tab label={_t("Courses")} value={1} />
          </Tabs>
        )}
        {tab === 0 && (
          <div className="form properties  m-top-10">
            <Grid container spacing={2} className="fields-and-image">
              {/* if program is new, no image is shown, hence the with of the fields is 12 */}
              <Grid item className="fields" xs={12} 
              md={programIsNew ? 12 : 8}
              >
                <TextField
                  label={_t("Title")}
                  value={title}
                  name="title"
                  onChange={this.onFieldChange}
                  variant="outlined"
                  fullWidth
                  className="form-field"
                />
                <TextField
                  label={_t("Description")}
                  value={description}
                  name="description"
                  onChange={this.onFieldChange}
                  multiline
                  minRows={6}
                  fullWidth
                  className="form-field"
                  variant="outlined"
                />
              </Grid>
              { !programIsNew && 
                <Grid item xs={12} md={4} className="image-selector-container flex column grow-1">
                  
                  <fieldset className="border">
                      <>
                        <legend>{_t("Program image")}</legend>
                        
                          <ImageSelector
                            image={Array.isArray(images) ? images[0] : null}
                            {...{ defaultImage }}
                            onSelect={this.onImageSelect}
                            onDelete={this.onImageDelete}
                          />
                        
                      </>
                  </fieldset>
                </Grid>
              }
            </Grid>
          </div>
        )}
        {tab === 1 && (
          <div className="form courses">
            <div className="prompt">{_t("programEditPrompt")}</div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <h3>{_t("Available courses")}</h3>
                <SelectList
                  items={coursesNotInProgram}
                  onItemClick={(c) => this.onCourseClick(c, "add")}
                  ItemRenderer={ListItemRenderer}
                  onSearch={this.onSearch}
                  searchProps={{
                    placeholder: _t("Search..."),
                    className: "bg-white radius-10"
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h3>{_t("Courses in program")}</h3>
                <SelectList
                  items={course_ids}
                  onItemClick={(c) => this.onCourseClick(c, "remove")}
                  ItemRenderer={ListItemRenderer}
                  onSearch={this.onSearch}
                  searchProps={{
                    placeholder: _t("Search..."),
                    className: "bg-white radius-10"
                  }}
                />
              </Grid>
            </Grid>
            <div className="buttons">
              <ButtonGroup>
                {/* <Button
                  variant="contained"
                  color="primary"
                  onClick={this.onSaveCourses}
                  disabled={!coursesDirty}
                >
                  {_t("Save courses list")}
                </Button> */}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.resetCoursesToOriginal}
                  disabled={!coursesDirty}
                  onSearch={this.onSearch}
                >
                  {_t("Reset to previous")}
                </Button>
              </ButtonGroup>
              {/* <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  if (programDirty || coursesDirty) {
                    this.setState({ onConfirmDialog: true });
                  } else {
                    onClose();
                  }
                }}
              >
                {_t("Close")}
              </Button> */}
            </div>
          </div>
        )}
         <div className="buttons">
              <Button
                variant="contained"
                color="primary"
                onClick={this.onSave}
                disabled={!programDirty}
              >
                {program_id ? _t("Save") : _t("Add")}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  if (programDirty ) {
                    this.setState({ onConfirmDialog: true });
                  } else {
                    onClose();
                  }
                }}
              >
                {_t("Close")}
              </Button>
            </div>
        <ConfirmDialog
          open={onConfirmDialog}
          cancelText={_t("Cancel")}
          confirmText={_t("Yes")}
          prompt={_t(
            "There are unsaved changes in program, do you really want to close and loose the changes?"
          )}
          onConfirm={onClose}
          onClose={() => this.setState({ onConfirmDialog: false })}
        />
      </div>
    );
  }
}

export default WithTranslations(EditProgram);
