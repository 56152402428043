import React from "react";
import "./App.scss";
import Main from "./components/Main";
import Notification from "./components/template/Notification";
import { ThemeProvider } from "@material-ui/core";
import theme1 from "./data/theme1";
import "@ses-education/courses-components/dist/main.css";
import "@ses-education/content-parser/dist/main.css";

const { REACT_APP_INCLUDE_FONTS } = process.env;

class App extends React.Component {
  /**
   * Adds a stylesheet to the page DOM.
   * @param {*} href
   * @param {*} rel
   * @param {*} crossorigin
   */
  addStylesheet = (href, rel = "stylesheet", crossorigin = false) => {
    const link = document.createElement("link");
    link.href = href;
    link.rel = rel;
    if (crossorigin) {
      link.crossorigin = crossorigin;
    }
    document.head.appendChild(link);
  };

  componentDidMount() {
    // FONTS INCLUSION

    // if we are NOT including fonts, use web links
    if (!REACT_APP_INCLUDE_FONTS) {
      // add Noto Sans font from goolge fonts
      this.addStylesheet("https://fonts.googleapis.com", "preconnect");
      this.addStylesheet("https://fonts.gstatic.com", "preconnect", true);
      this.addStylesheet("./fonts/noto-sans/font-web.css");
      return;
    }
    // we ARE including fonds: include Noto Sans font from local file
    this.addStylesheet("./fonts/noto-sans/font-local.css");
  }

  render() {
    return (
      <ThemeProvider theme={theme1}>
        <Main />
        <Notification />
      </ThemeProvider>
    );
  }
}

export default App;
