import React from "react";
import "./style.scss";
import {ModalWindow} from "@ses-education/courses-components";
import { CircularProgress } from "@material-ui/core";

/**
 * Shows a modal that blocks the complete screen with passed message and shows a (customizable) spinner, until the 'open' prop is set to false
 *
 * ## props:
 * - {bool} open - whether the modal is open
 * - {string} header - the text to show
 * - {ReactComponent} SpinnerComponent - react component to show as spinner. Defaults to CircularProgress
 * the rest of props are passed to ModalWindow component from ses-courses-shared-components.modal-window
 */
class ProcessSpinner extends React.Component {
  render() {
    const { SpinnerComponent = CircularProgress, ...other } = this.props;

    return (
      <ModalWindow
        {...other}
        onClose={(ev, reason) => {
          // don't close on escape and backdrop click
          if (["escapeKeyDown", "backdropClick"].includes(reason)) return false;
        }}
        formClassName="process"
      >
        <div className="flex column align-center">
          <SpinnerComponent />
        </div>
      </ModalWindow>
    );
  }
}

export default ProcessSpinner;
