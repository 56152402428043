import React from "react";
import { List, ListItem } from "@material-ui/core";
import {CheckBox, CheckBoxOutlineBlank} from '@material-ui/icons';
import "./select-list.scss";
import DebounceSearch from "../DebounceSearch";

/**
 * Renders a List of items and allows to click on them
 * @prop {function} onSeach( item, searchString) - callback function to be called when search is changed. Receives object from list to search within and the search string;
 */
class SelectList extends React.Component {
  state = {
    search: "",
    filteredItems: [],
    selectedItems: []
  };

  componentDidMount() {
    this.setState({
      filteredItems: this.filterItems(this.props.items, this.state.search),
    });
  }
  componentDidUpdate(prevProps) {
    const { items } = this.props;
    if (JSON.stringify(items) !== JSON.stringify(prevProps.items)) {
      this.setState({
        filteredItems: this.filterItems(items, this.state.search),
      });
    }
  }

  filterItems = (items, search) => {
    const { onSearch } = this.props;
    if (search === "" || !search || typeof onSearch !== "function")
      return items;
    console.debug("filterItems", items, search);
    return items.filter((item) => onSearch(item, search));
  };

  onSearch = (search) => {
    console.debug("setting search", search);
    const filteredItems = this.filterItems(this.props.items, search);
    this.setState({ search, filteredItems });
  };

  onClick = (item) => {

    console.debug("SelectList.onClick", item);

    const {multipleSelect, idField, onItemClick} = this.props;
    if( !multipleSelect ){
      onItemClick(item);
      return;
    }

    let {selectedItems } = this.state;

    // ifField not supplied: return item and array of this item only
    if( !idField ){
      console.error("SelectList: idField is empty, cannot select multiple items");
      selectedItems = [item];
      this.setState({selectedItems}, () => {
        onItemClick(item, selectedItems);
      });
      
      return;

    }

    // multiple select: add item to selected or remove if already in
    const itemIndex = selectedItems.findIndex( i => i[idField] === item[idField] );
    if( itemIndex >= 0 ){
      console.debug("item already in, removing", item[idField]);
      selectedItems.splice(itemIndex, 1);
    } else {
      console.debug("adding item", item[idField]);
      selectedItems.push(item);
    }
    this.setState({selectedItems});
    this.setState({selectedItems}, () => {
      onItemClick(item, selectedItems);
    });
  }

  render() {
    let {
      items,
      className,
      onItemClick = console.debug,
      onSearch,
      searchProps = {},
      
      maxHeight = "calc(6em + 2em)", // 1.5em per line plus 2x1em padding
      
      listId,
      noItemsMessage = "no items",
      ItemRenderer = (item) => <span>{(item && item.title) || "-"}</span>,
      beforeSearch = <></>,
      afterSearch = <></>,

      multipleSelect=false,
      uncheckedIcon=<CheckBoxOutlineBlank/>,
      checkedIcon=<CheckBox/>,
      idField=null, // this must be non-empty if multipleSelect is true. It's the field the items are compared by
      ...other
    } = this.props;
    className = className ? `${className} select-list` : "select-list";

    console.debug("selectList items:", items);

    const { search, filteredItems, selectedItems } = this.state;

    const selectedIds = idField ? selectedItems.map( i => i[idField]) : [];

    return (
      <div className={className}>
        <div className="actions">
          {beforeSearch}
          <DebounceSearch
            fullWidth
            search={search}
            onChange={this.onSearch}
            {...searchProps}
            //   variant="outlined"
          />
          {afterSearch}
        </div>
        <div className={`select-list-container${multipleSelect ? " multiple" : ""}`}>
          <List {...other}>
            {
              // if no items are provided, show a message
              (!Array.isArray(items) || items.length === 0) && noItemsMessage
            }
            {Array.isArray(items) &&
              filteredItems.map((item, index) => (
                <ListItem
                  key={`${listId}-item-${index}`}
                  // onClick={() => onItemClick(item)}
                  onClick={() => this.onClick(item)}
                  className={`select-list-item${ idField && selectedIds.includes(item[idField]) ? " selected" : ""}`}
                >
                  <ItemRenderer {...{ item, index }} />
                </ListItem>
              ))}
          </List>
        </div>
      </div>
    );
  }
}

export default SelectList;
