import React from "react";

class HelpText extends React.Component {
  render() {
    const { html, ...other } = this.props;
    return <div {...other} dangerouslySetInnerHTML={{ __html: html }} />;
  }
}

export default HelpText;
