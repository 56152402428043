import React from 'react'
import {ModalWindow} from '@ses-education/courses-components'
import SelectList from '../../template/SelectList';
import {Button} from '@material-ui/core';

class SelectGroups extends React.Component{

    state = {
        selectedItems: null
    }

    onSelect = (item, selectedItems) => {
        console.debug("item:", item, "selectedItems:", selectedItems);
        this.setState({selectedItems})
    }

    

    render(){

        const { items, onClose, onAssign, _t, ...other} = this.props;
        const {selectedItems} = this.state;

        return(<ModalWindow 
            onClose={onClose}
            {...other}
        >
            <SelectList items={items} 
                onItemClick={this.onSelect} 
                ItemRenderer={ ({item}) => <span className='flex justify-content-space-between w-100p'>
                    <span>{item.title}</span>
                    <span>
                    ({item.student_count || 0})
                    </span>
                </span>}
                onSearch={(item, search) => item.title.toLowerCase().includes(search.toLowerCase())}
                multipleSelect={true}
                idField="student_group_id"
            />
            <div className="flex justify-content-space-between align-self-stretch mt-12">
              <Button disabled={!selectedItems || selectedItems.length===0} variant="contained" color="primary" onClick={() => onAssign(selectedItems) }>{_t("Assign")} {selectedItems && <> ({selectedItems.length})</>}</Button>
              <Button variant="contained" color="secondary" onClick={onClose}>{_t("Cancel")}</Button>
            </div>
        </ModalWindow> )
    }
}

export default SelectGroups