import { createStore, applyMiddleware, compose, combineReducers } from "redux"
import thunk from 'redux-thunk'
import auth  from './authReducers'
import ui from "./uiReducers"
import { notificationsReducers as reducers} from '@ses-education/courses-components'

const store  = createStore( combineReducers({
  auth, 
  ui,
  reducers,
}), compose(applyMiddleware(thunk)))
export default store; 