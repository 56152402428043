// import { createTheme } from "@material-ui/core/style";

import { createTheme } from "@material-ui/core";

const theme1 = createTheme({
  palette: {
    primary: {
      main: "#636035",
    },
    secondary: {
      main: "#db0047",
    },
  },
  typography: {
    fontFamily: ["Noto Sans", "Helvetica", "sans-serif"].join(", "),
    fontWeight: 400,
  },
});

export default theme1;
