import {
  Chip,
  Menu,
  MenuItem,
  TextField,
  Select,
  Typography,
  Button,
  InputLabel,
} from "@material-ui/core";
import { ClearAll } from "@material-ui/icons";
import React from "react";
import SelectListExtended from "../SelectList/select-list-extended";
import "./filter-form.scss";

const defaultFields = [
  //   {
  //     // id of the filter (can be any string)
  //     id: "status",
  //     // label to show in form
  //     label: "Select status",
  //     // type of the filter.  We will start with the "chips" filter (see explanations below)
  //     type: "dropdown",
  //     // an array of field names that the filter will look into
  //     fieldsToCompare: ["status"],
  //     // options (if the filter has several options) as **key => label** pairs
  //     options: {
  //       "": "All",
  //       active: "Active",
  //       dormant: "Dormant",
  //       blocked: "Disabled",
  //     },
  //     // current value of the filter. Should be stored in form's state, changed within the form and then returned in the result object along with other values
  //     value: "",
  //   },
  //   {
  //     id: "programs",
  //     label: "Select programs to show",
  //     type: "chips",
  //     fieldsToCompare: ["status"],
  //     options: {
  //       1: "Auto-Tech",
  //       2: "Mechatronics",
  //       8: "Electronics",
  //       24: "Electricity 101",
  //       55: "Electricity",
  //       103: "Refrigeration & Air-conditioning",
  //       7: "Automotive",
  //     },
  //     value: [2, 8, 103],
  //   },
  //   {
  //     id: "search",
  //     label: "Search in name and email",
  //     type: "textfield",
  //     fieldsToCompare: ["name", "email"],
  //     value: "",
  //   },
];
class FilterForm extends React.Component {
  state = {
    filters: null,
    errors: {},
  };
  componentDidMount = async () => {
    const { fields = defaultFields } = this.props;
    let filters = {};
    fields?.map((field) => {
      filters[field.id] = field.value;
    });
    this.setState({ filters });
  };
  componentDidUpdate = async (prevProps) => {
    if (prevProps.fields !== this.props.fields) {
      // this.setState({ filters: {} });
      const { fields = defaultFields } = this.props;
      let filters = {};
      fields?.map((field) => {
        filters[field.id] = field.value;
      });
      this.setState({ filters });
    }
  };
  handleFilter = (value, key) => {
    const { validate } =
      this.props.fields?.find((field) => field.id === key) || {};
    let error;
    if (validate instanceof Function) {
      error = validate(value);
    }
    this.setState({
      filters: {
        ...this.state.filters,
        [key]: value,
      },
      errors: {
        ...this.state.errors,
        [key]: error,
      },
    });
  };
  render() {
    const {
      anchorEl,
      fields = defaultFields,
      header = "Filters",
      onCancel = console.debug,
      className = "filter-form",
      onUpdate,
      showMessage = console.debug,

      /**
       * Message that will be shown when attempting to deselect last item in multiple list
       */
      lastListItemMessage = "Cannot deselect last item!",
      ...other
    } = this.props;
    const { filters, errors } = this.state;
    const disabledOk = Object.values(errors).reduce(
      (res, item) => res && item,
      true
    );
    return (
      <Menu
        {...{ anchorEl, className, ...other }}
        open={Boolean(anchorEl)}
        // transformOrigin={{ horizontal: "center", vertical: "top" }}

        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        getContentAnchorEl={null}
        onClose={onCancel}
      >
        <Typography variant={"h1"}>{header}</Typography>
        {filters &&
          fields?.map((field) => {
            switch (field.type) {
              case "chips":
                return (
                  <li className={errors[field.id] ? "has-errors" : ""}>
                    <InputLabel id="chips-select-label">
                      {field.label}
                    </InputLabel>
                    {errors[field.id] && (
                      <div>
                        <span className="error-message">
                          {errors[field.id]}
                        </span>
                      </div>
                    )}
                    <Select
                      multiple
                      fullWidth
                      className="chips-select"
                      value={filters[field.id]}
                      onChange={(event, child) => {
                        // if it's the only item left, do not allow deselection
                        console.debug(
                          "onChange child:",
                          child,
                          "value:",
                          filters[field.id]
                        );
                        if (
                          filters[field.id].length === 1 &&
                          filters[field.id][0] === child.props.value
                        ) {
                          showMessage(lastListItemMessage, "warning");
                          return false;
                        }
                        this.handleFilter(event.target.value, field.id);
                      }}
                      labelId="chips-select-label"
                      label={field.label}
                      MenuProps={{
                        id: "chips-item-list",
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      renderValue={(selected) => {
                        return (
                          <div>
                            {selected.map((key) => (
                              <Chip key={key} label={field.options[key]} />
                            ))}
                          </div>
                        );
                      }}
                    >
                      {Object.entries(field.options).map(([key, value]) => (
                        <MenuItem value={key}>{value}</MenuItem>
                      ))}
                    </Select>
                  </li>
                );
              case "multiselect":
                // multi-select list with search
                return (
                  <li
                    className={`menu-items ${
                      errors[field.id] ? "has-errors" : ""
                    }`}
                    // stop propagation to cut off "Select by typing" behavior within menu items
                    onKeyDown={(ev) => ev.stopPropagation()}
                  >
                    <InputLabel id="multiselect-select-label">
                      {field.label}
                    </InputLabel>
                    {errors[field.id] && (
                      <div>
                        <span className="error-message">
                          {errors[field.id]}
                        </span>
                      </div>
                    )}
                    <SelectListExtended
                      items={field.options}
                      selectedItems={field.value}
                      // ItemRenderer={(item) => <span>{item[1]}</span>}
                      onChange={(ids) => {
                        console.debug("filter form received:", ids);
                        this.handleFilter(ids, field.id);
                      }}
                    />
                  </li>
                );
              case "dropdown":
                return (
                  <li className={errors[field.id] ? "has-errors" : ""}>
                    {errors[field.id] && (
                      <div>
                        <span className="error-message">
                          {errors[field.id]}
                        </span>
                      </div>
                    )}
                    <Select
                      value={filters[field.id]}
                      onChange={(event) => this.handleFilter(event, field.id)}
                      label={field.label}
                      fullWidth
                    >
                      {Object.entries(field.options).map(([key, value]) => (
                        <MenuItem value={key}>{value}</MenuItem>
                      ))}
                    </Select>
                  </li>
                );
              case "textfield":
                return (
                  <li className={errors[field.id] ? "has-errors" : ""}>
                    {errors[field.id] && (
                      <div>
                        <span className="error-message">
                          {errors[field.id]}
                        </span>
                      </div>
                    )}
                    <TextField
                      label={field.label}
                      value={filters[field.id]}
                      onChange={(event) => this.handleFilter(event, field.id)}
                      variant="outlined"
                      fullWidth
                    />
                  </li>
                );
            }
          })}
        <li className="buttons">
          <Button
            onClick={() => onUpdate(filters)}
            variant="contained"
            color="primary"
            disabled={disabledOk}
          >
            OK
          </Button>
          <Button onClick={onCancel} variant="contained" color="secondary">
            Cancel
          </Button>
        </li>
      </Menu>
    );
  }
}

export default FilterForm;
