import React from 'react'
import { Container, Typography } from '@material-ui/core'
import OrganizationService from "../../../services/OrganizationService";
import AutoBreadcrumbs from '../../template/CustomBreadcrumbs';
import {Home} from "@material-ui/icons";
import {showMessage} from "../../../redux/notificationActions"
import Error from '../../template/Error';
import Spinner from '../../template/Spinner';
import ProgramsReportItem from './programs.report.item';

class ProgramsReport extends React.Component{

    state = {
        programs: null,
        error: null
    }
    componentDidMount(){
        this.fetchData();
    }

    async fetchData(){
        try{
            const programs = await OrganizationService.getProgramsProgress();
            this.setState({programs, error: null})
        }catch(e){
            console.debug("ProgramsReport:", e.message)
            // showMessage( OrganizationService.error || "Unknown error", "error")
            this.setState({ programs: false, error: OrganizationService.error || "Unknown error" })
        }
    }

    render(){
        const {programs, error} = this.state

        if( programs === false && error ) return <Error error={error} />
        
        if( !programs || !Array.isArray(programs) ) return <Spinner />
        
        return(
            <Container className="programs-report">
                <AutoBreadcrumbs 
                items={[
                    { icon: <Home/> , to: "/" },
                    { text: "Reports", to: "/reports" },
                    { text: "Programs"}
                ]}
                backLink="/reports"
                />
                <Typography variant="h1">Programs report</Typography>
                <div className="program-list bubble">
                    {
                        programs.map( p => <ProgramsReportItem key={`program-${p.program_id}`} {...p} /> )
                    }
                </div>
            </Container>
        )
    }
}

export default ProgramsReport