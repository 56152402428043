import { Button, FormControl, FormLabel, Menu, MenuItem, Select, TextField, 
  // Typography 
} from "@material-ui/core";
import React from "react";
// import Spinner from "../../template/Spinner";
import {ModalWindow} from "@ses-education/courses-components";
import WithTranslations from "../../WithTranslations";
import { strFormat } from "../../../utils/functions";

const defaultState = {
  group: {},
  original: null,
  error: null,
}

class GroupForm extends React.Component {
  state = {
    ...defaultState
  };

  componentDidMount() {

    this.props.fetchTranslations([
      "Save",
      "Cancel",
      "Create new group",
      "Title",
      "Description (optional)",
      "Group status",
      "Active",
      "Archived",
      "Edit group #%id%"
    ]);      
    this.dataToState();
  }
  componentDidUpdate( prevProps) {
      if( prevProps.user !== this.props.user)
        this.dataToState();
  }

  dataToState() {
    const { group = {} } = this.props;
    this.setState({ group, original: { ...group } });
  }

  setValue = ({ target }) => {
    const { name, value, check } = target;

    let { group } = this.state;

    group = { ...group, [name]: value };
    this.setState({ group });
  };

  validate(ev){
    const autovalidated = ev.currentTarget.reportValidity();

    // TODO: check values of each field
    return autovalidated;
  }

  onFormSubmit = async (ev) => {
    // stop sending the form
    ev.preventDefault();

    const {onSubmit, onClose} = this.props;

    // console.debug("form data:", this.state.group)
    // validate form and call callback
    if( this.validate(ev) && typeof onSubmit === "function" && await onSubmit( this.state.group )){
        // calback returned true - clear and close the form
        this.setState({
          ...defaultState
        })

        if( typeof onClose === "function" ) onClose();
    }
  }

  render() {
    const { onSubmit, onClose, _t, ...other } = this.props;
    const { group } = this.state;
    console.debug("group", group);
    
    if( !other.open ) return null;
      

    const { student_group_id: id, title, description, group_status } = group;

    const buttons = <>
    <Button variant="contained" color="primary" type="submit">
              {_t("Save")}
            </Button>
            <Button variant="contained" color="secondary" onClick={onClose}>
              {_t("Cancel")}
            </Button>
    </>

    return (
      <ModalWindow 
      formClassName="group-form" header={id ? strFormat( _t("Edit group #%id%"), {"%id%": id}) : _t("Create new group")} 
      onSubmit={this.onFormSubmit}
      {...{...other, buttons, onClose}} 
      >                  
          {/* {id && <div className="id-value">#{id}</div>} */}
          <TextField
            label={_t("Title")}
            name="title"
            value={title || ""}
            onChange={this.setValue}
            fullWidth
            required
          />          
          <TextField
            label={_t("Description (optional)")}
            name="description"
            type="text"
            multiline            
            value={description || ""}
            onChange={this.setValue}
            fullWidth
          />
          <FormControl>
            <FormLabel>{_t("Group status")}</FormLabel>
            <Select 
            name="group_status" 
            value={group_status} 
            
            className="mb-12"
            onChange={this.setValue}
            >
              <MenuItem value="active">{_t("Active")}</MenuItem>
              <MenuItem value="archived">{_t("Archived")}</MenuItem>
            </Select>                  
          </FormControl>
      </ModalWindow>
    );
  }
}

export default WithTranslations(GroupForm);
