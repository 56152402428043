import {ModalWindow} from "@ses-education/courses-components";
import {
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";
import { Button } from "@material-ui/core";
import React from "react";
import LocalizedDataGrid from "../localized-Data-Grid";

const CustomToolbar = ({ children }) => (
  <GridToolbarContainer className="admin-table-toolbar">
    {children}
  </GridToolbarContainer>
);

const columns = [
  {
    field: "id",
    hide: true,
  },
  {
    field: "citizen_id",
    flex: 0,
    headerName: "Citizen ID",
  },
  {
    field: "full_name",
    headerName: "Name",
    flex: 1,
    valueGetter: ({ row }) => `${row.name || ""} ${row.last_name || ""}`,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 2,
  },
  {
    field: "password",
    headerName: "Password",
    flex: 1,
  },
];

class Passwords extends React.Component {
  render() {
    const { passwords, onClose, ...other } = this.props;
    console.debug("PAsswords props", this.props);
    return (
      <ModalWindow
        {...other}
        header="Created passwords"
        formClassName="full-width"
        // disableEscapeKeyDown
        onClose={(ev, reason) => {
          // don't close on escape and backdrop click
          if (["escapeKeyDown", "backdropClick"].includes(reason)) return false;

          onClose();
        }}
      >
        {/* <>{JSON.stringify(passwords)}</> */}
        <LocalizedDataGrid
          className="flex align-self-stretch"
          autoHeight
          rows={passwords || []}
          columns={columns}
          disableSelectionOnClick // don't let user select rows cause it causes export of single line
          pageSize={10}
          components={{
            Toolbar: () => (
              <CustomToolbar>
                <GridToolbarExport
                  title="Download CSV"
                  variant="contained"
                  color="primary"
                  csvOptions={{
                    fileName: "password-list",
                  }}
                />
              </CustomToolbar>
            ),
          }}
        />
        <div className="buttons">
          <Button
            variant="contained"
            color="secondary"
            onClick={this.props.onClose}
          >
            Close
          </Button>
        </div>
      </ModalWindow>
    );
  }
}

export default Passwords;
