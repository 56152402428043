import React from 'react'
import {ReactComponent as CenterLogoSvg } from './images/ses-stlab-logo.svg';
import {Link} from 'react-router-dom';

class CenterLogo extends React.Component{

    render(){
        const { homeLink = "/about" } = this.props
        return( 
            <Link className="center-logo" to={homeLink}>
                <CenterLogoSvg />
            </Link>
        )
    }
}

export default CenterLogo