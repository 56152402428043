export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function extractHtmlBody(html) {
  // Sometimes an HTTP request returns a complete HTML page, let's extract error content from it, if any.
  // Check if there's an html body in html (it's a complete HTML page) and if there is - return its contents
  const bodyMatches = /<body[^>]*>(.*?)<\/body>/is.exec(html);

  // it should produce an array with found group at 0 and group content at 1, let's return [1].
  if (Array.isArray(bodyMatches) && bodyMatches[1]) return bodyMatches[1];

  return html;
}

/**
 * Replace words wrapped with %..% with corresponding items from items{} object
 * E.g.
 * items: { %name%: "Me", %age%: 12 }
 * str: "I am %name" and I'm %age% years old. That's %name%."
 * strFormat(str, items) => "I am Me and I'm 12 years old. that's Me."
 * @param {*} str
 * @param {*} items
 */
export function strFormat(str, items) {
  return str.replace(/%\w+%/g, (key) => {
    return items[key] || key;
  });
}

/**
 * Counting unique elements in an array
 * @param {*} arr
 * @returns {Object}
 */
export function countUnique(arr) {
  const counts = {};
  for (let i = 0; i < arr.length; i++) {
    counts[arr[i]] = 1 + (counts[arr[i]] || 0);
  }
  return counts;
}

/**
 * Simple object comparison through conversion to JSON string
 * @param {*} obj1
 * @param {*} obj2
 * @returns
 */
export function objectsAreEqual(obj1, obj2) {
  try {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  } catch (e) {
    console.error(
      "objectsAreEqual error comparing objects:",
      e.message,
      "\nobjects:",
      obj1,
      obj2
    );
    return false;
  }
}

/**
 * Fills array with separator elements by putting them betweeen array elements
 * @param {*} array
 * @param {*} separator
 * @returns
 */
export function separateArray(array, separator) {
  if (!Array.isArray(array)) return [];

  const [first, ...other] = array;
  let result = [first];

  for (let i = 0; i < other.length; i++) {
    result = [...result, separator, other[i]];
  }

  return result;
}

/**
 * Returns true if arr1 contains at least one of elements of arr2
 * @param {*} arr1
 * @param {*} arr2
 * @returns
 */
export const arraysIntersect = (arr1, arr2) =>
  Array.isArray(arr1) &&
  Array.isArray(arr2) &&
  arr1.some((el) => arr2.includes(el));

/**
 * this function get image url check if its start with the REACT_APP_MEDIA_FIX
 * env variabale and if yes remove that part from the url
 * @param {*} img_url
 * @returns
 */

export function fixUrl(img_url, prependWithActualURL = false) {
  const { REACT_APP_MEDIA_FIX, REACT_APP_MEDIA_URL } = process.env;
  if (
    img_url.substring(0, REACT_APP_MEDIA_FIX.length) === REACT_APP_MEDIA_FIX
  ) {
    // console.log(
    //   "fixUrl",
    //   img_url,
    //   "\n",
    //   img_url.substring(REACT_APP_MEDIA_FIX.length)
    // );
    return prependWithActualURL
      ? // if asked to prepend with the actual URL - do so
        REACT_APP_MEDIA_URL + img_url.substring(REACT_APP_MEDIA_FIX.length)
      : // otherwise just return the fixed local URL
        img_url.substring(REACT_APP_MEDIA_FIX.length);
  }
  // if asked to prepend with the actual URL - do so, otherwise return the url intact
  return prependWithActualURL ? REACT_APP_MEDIA_URL + img_url : img_url;
}
