import Dashboard from '../components/Dashboard';
import Users from '../components/pages/Users';
import Teachers from '../components/pages/Teachers';
import Reports from '../components/pages/Reports';
import Programs from '../components/pages/Programs';
import Page404 from '../components/template/Page404';
import About from '../components/pages/About';
import UserProfile from '../components/pages/Users/UserProfileController';
import UsersReport from '../components/pages/Reports/users.report';
import ProgramsReport from '../components/pages/Reports/programs.report';
import StudentGroups from '../components/pages/StudentGroups';
import ReportsStudents from '../components/pages/Reports/reports-students';
import Tests from '../components/tests';
import ProgramCourse from '../components/pages/Programs/program-course';
const components = {
  // Organizations,
  // Programs,
  // Courses,
  // Course,
  UserProfile,
  About,
  Users,
  Teachers,
  StudentGroups,
  Dashboard,
  Reports,
  UsersReport,
  ProgramsReport,
  Programs,
  Page404,
  ReportsStudents,
  Tests,
  ProgramCourse
};

export const routes = [
  {
    exact: true,
    path: '/',
    component: components['Dashboard'],
  },
  {
    exact: true,
    path: '/students',
    component: components['Users'],
    credentials: ["organization"]
  },
  {
    exact: true,
    path: '/students/:user_id/:course_id?',
    component: components['UserProfile'],
    credentials: ["organization"]
  },
  // {
  //   exact: true,
  //   path: '/student-groups/:group_id?/:user_id?',
  //   component: components['UserGroups'],
  //   // credentials: ["organization", "teacher"]
  // },
  {
    exact: true,
    path: '/teachers/:user_id?/:group_id?/:student_id?/:course_id?',
    component: components['Teachers'],
    credentials: ["organization"]
  },
  {
    exact: true,
    path: '/groups/:group_id?/:user_id?/:course_id?',
    component: StudentGroups,
    // credentials: ["organization"]
  },

  {
    exact: true,
    path: '/reports/:report?/:course_ids?',
    component: components['ReportsStudents'],
  },
  // {
  //   exact: true,
  //   path: '/reports/students',
  //   component: components['UsersReport'],
  // },  
  // {
  //   exact: true,
  //   path: '/reports/programs',
  //   component: components['ProgramsReport'],
  // },
  {
    exact: true,
    path: '/programs/:program_id?',
    component: components['Programs'],
  },
  {
    exact: true,
    path: '/programs/:program_id/:course_id/:experiment_id?/:chapter?',
    component: components['ProgramCourse'],
  },  
  {
    exact: true,
    path: '/about',
    component: components['About'],
  },
  {
    exact: true,
    path: '/profile',
    component: components['UserProfile'],
  },

  // Testing grounds
  { exact: true, path: '/test', component: components['Tests'] },

  { exact: false, path: '*', component: components['Page404'] },
];

export default routes;
