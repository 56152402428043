import datagridLocales from "./datagrid-locales";

/**
 * Use this function to localize DataGrid component
 * by passing it's result to localeText prop of DataGrid,
 * e.g. `<DataGrid localeText={datagridLocales(language_id)} />`
 * or
 * const dgLocale = datagridLocales(language_id);
 * return (
 *  //...
 *  <DataGrid localeText={dgLocale} />
 * )
 * @param {*} language_id
 * @returns
 */
const dataGridLocalize = (language_id) => {
  return language_id in datagridLocales
    ? datagridLocales[language_id]
    : datagridLocales["en"];
};

export default dataGridLocalize;
