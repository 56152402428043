import { Menu, MenuItem } from "@material-ui/core";
import { Language } from "@material-ui/icons";
import React from "react";
import "./language-switch.scss";
import { connect } from "react-redux";
import TranslationService from "../../../services/TranslationService";
import { showMessage } from "@ses-education/courses-components";
import store from "../../../redux/store";

class LanguageSwitch extends React.Component {
  state = {
    languages: null,
    language_id: "en",
    menuTarget: null,
  };

  async componentDidMount() {
    const languages = await TranslationService.getLanguages();
    if (!languages) {
      store.dispatch(showMessage(TranslationService.error, "error"));
      this.setState({ languages: false });
      return;
    }

    this.setState({ languages });
  }

  setLanguage = async ({ target }) => {
    const language_id = target.getAttribute("value");
    if (!(await TranslationService.setLanguage(language_id))) {
      store.dispatch(
        showMessage(
          TranslationService.error || "Unknown TranslationService error",
          "error"
        )
      );
      return;
    }
    this.hide();
  };
  show = (ev) => {
    this.setState({ menuTarget: ev.currentTarget });
  };
  hide = (ev) => {
    this.setState({ menuTarget: null });
  };
  render() {
    const { languages, menuTarget } = this.state;
    const { language_id = "en" } = this.props;
    console.log("LanguageSwitch", languages);
    return (
      <>
        <div className="language-switch" onClick={this.show}>
          <Language />
          {language_id}

          {/* {languages ? 
                <Select value={language_id} onChange={this.setLanguage}>                   
                </Select> : language_id
                } */}
        </div>
        {
          // menu has to be outside of the element that's calling it
          // otherwise it won't close when clicking outside of it
          languages && (
            <Menu
              id="simple-menu"
              anchorEl={menuTarget}
              keepMounted
              open={Boolean(menuTarget)}
              onClose={this.hide}
            >
              {languages.map((l) => (
                <MenuItem
                  onClick={this.setLanguage}
                  key={l.english_short.substring(0, 2)}
                  value={l["iso639-1"]}
                >
                  {l.english_short.substring(0, 2)}
                </MenuItem>
              ))}
            </Menu>
          )
        }
      </>
    );
  }
}

const stateToProps = (state) => ({ language_id: state.auth.language });

export default connect(stateToProps)(LanguageSwitch);
