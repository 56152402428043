import { Container, Typography } from "@material-ui/core";
import { Home } from "@material-ui/icons";
import React from "react";
// import AutoBreadcrumbs from "@bit/ses-education.ses-courses-shared-components.template.auto-breadcrumbs";
import AutoBreadcrumbs from "../../template/CustomBreadcrumbs";

// import UserService from "../../../services/UserService";
import Error from "../../template/Error";
import Spinner from "../../template/Spinner";
import UsersReportItem from "./users.report.item";
import OrganizationService from "../../../services/OrganizationService";

class UsersReport extends React.Component {
  state = {
    students: null,
  };

  componentDidMount() {
    this.fetchStudentsProgress();
  }

  async fetchStudentsProgress() {   

    // let's fetch students' progress
    const students = await OrganizationService.getStudentsProgress();
    if (!students) {
      this.setState({ error: OrganizationService.error });
      return;
    }

    console.debug("Resulting students", students);
    this.setState({ students });
    return true;
  }

  render() {
    const { students, error } = this.state;

    let breadcrumbs = [
      { to: "/", icon: <Home /> },
      { to: "/reports", text: "Reports" },
      { text: "Students report" },
    ];

    const backLink = "/reports";

    if (!students && !error) return <Spinner />;
    if (error) return <Error text={error} />;
    return (
      <Container className="students-report">
        <AutoBreadcrumbs items={breadcrumbs} {...{backLink}} />
        <Typography variant="h1">Students report</Typography>
        <div className="students-list bubble">
          {students.map((student) => (
            <UsersReportItem key={`user-report-${student.user_id}`} {...student} />
          ))}
        </div>
      </Container>
    );
  }
}

export default UsersReport;